import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  getPartsInventoryList,
  deletePartInventory,
} from "../../services/PartsInventory";
import { useNavigate, useLocation } from "react-router-dom";

const PartsInventory = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const navigate = useNavigate();
  const [delModal, setDelModal] = useState(false);
  const [delPartId, setDelPartId] = useState("");
  const [catalog, setCatalog] = useState("");
  const [model, setModel] = useState("");
  const [year, SetYear] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [inventoryStatus, setInventoryStatus] = useState(0);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    vehicle_model: "",
    vehicle_year: "",
    catalog_name: "",
    inventory_status: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [partsInventoryList, setPartsInventoryList] = useState([]);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = "";
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    paramsObj.vehicle_model = model;
    paramsObj.vehicle_year = year;
    paramsObj.catalog_name = catalog;
    paramsObj.inventory_status = parseInt(inventoryStatus);
    setParams(paramsObj);
  };

  const fetchPartInventory = () => {
    let data = { ...params };
    getPartsInventoryList(data)
      .then((response) => {
        setPartsInventoryList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartInventory(data);
    },
    [params]
  );

  const handlePartsInventory = () => {
    navigate("/parts-inventory/AddParts");
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPartId(item.id);
    setDelModal(true);
  };
  const onDeleteModalClose = () => {
    setDelModal(false);
  };
  const onDestroyPartInventory = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delPartId };
    deletePartInventory(data)
      .then((response) => {
        let data = { ...params };
        fetchPartInventory(data);
        toast.success(response.data.message);
        setDelPartId("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      <div>
        {isLoading ? (
          // <div className="text-center pageLoader">
          //   <div className="spinner-border" role="status">
          //     <span className="visually-hidden">Loading...</span>
          //   </div>
          // </div>
          <div className="skeleton-loader p-3 d-none">
            <div className="card">
              <div className="card-header">
                <table className="table rounded-table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ width: 10, borderTop: "0", borderBottom: "0" }}><div className="skeleton" ></div></th>
                      <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                      <th class="d-flex" style={{ borderTop: "0", borderBottom: "0" }}><div class="skeleton me-1"></div>
                        <div class="skeleton"></div>
                      </th>
                      <th style={{ width: 10, borderTop: "0", borderBottom: "0" }}><div className="skeleton"></div></th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="card-body">
                <div className="vehicleThBg table-responsive">
                  <table className="table table-bordered rounded-table table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                        <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                        <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                      </tr>
                    </thead>
                    <tbody>
                      {partsInventoryList?.map((_, index) => (
                        <tr key={index}>
                          <td><div className="skeleton"></div></td>
                          <td><div className="skeleton"></div></td>
                          <td><div className="skeleton"></div></td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <div className="skeleton col-lg-3 float-right"></div>
              </div>
            </div>
          </div>
        ) : (
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1>Parts Inventory</h1> */}
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
        )}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary-Vehicle fuelCard">
                  <div className="card-header">
                    {RolePermission?.findIndex(
                      (e) => e.permission_name === "add_part_inventory"
                    ) > -1 && (
                        <div className="card-title m-0 float-right fuelCT">
                          <button
                            type="button"
                            className="btn-icon-text mr-2 mr-2"
                            onClick={handlePartsInventory}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">Add For Vehicle</span>
                          </button>
                        </div>
                      )}
                    {RolePermission?.findIndex(
                      (e) => e.permission_name === "add_part_inventory"
                    ) > -1 && (
                        <div className="card-title m-0 float-right fuelCT">
                          <button
                            type="button"
                            className="btn-icon-text mr-2"
                            onClick={() => navigate("/parts-inventory-addparts")}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">Add For Parts</span>
                          </button>
                        </div>
                      )}
                  </div>
                  <div className="card-body mt-3">
                    <div className="vehicleThBg table-responsive cstm-height">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th className="Label-my">
                              Catalog{" "}
                              <input
                                className="form-control mt-2 input-search"
                                type="text"
                                value={catalog}
                                onChange={(e) => {
                                  setCatalog(e.target.value);
                                }}
                                maxLength={50}
                                placeholder="Catalog"
                              />
                            </th>

                            <th className="Label-my">
                              Model
                              <input
                                className="form-control mt-2 input-search"
                                type="text"
                                value={model}
                                onChange={(e) => {
                                  setModel(e.target.value);
                                }}
                                maxLength={50}
                                placeholder="Model"
                              />
                            </th>
                            <th className="Label-my">
                              Year
                              <input
                                className="form-control mt-2 input-search"
                                type="number"
                                value={year}
                                onChange={(e) => {
                                  SetYear(e.target.value);
                                }}
                                maxLength={50}
                                placeholder="year"
                              />
                            </th>
                            <th
                              className="Label-my"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Inventory Status
                              <select
                                className="form-select mt-2 input-search"
                                type="select"
                                value={inventoryStatus}
                                onChange={(e) => {
                                  setInventoryStatus(e.target.value);
                                }}
                              >
                                <option value="0">All</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                              </select>
                            </th>
                            <th className="Label-my" style={{ width: "10%" }}>
                              Action
                              <button
                                style={{ marginTop: "12px" }}
                                type="button"
                                className="btn  btn-primary"
                                onClick={(e) => handleParams(e, "search")}
                              >
                                <i className="bi bi-search"></i>
                                <span className="ms-1">Search</span>
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {partsInventoryList?.map((item) => {
                            return (
                              <>
                                <tr key={item.id}>
                                  <td>{item.namePartPT}</td>
                                  <td>{item.vehicle_model}</td>
                                  <td>{item.vehicle_year}</td>
                                  <td>
                                    {item.inventory_status > 0 ? "Yes" : "No"}
                                  </td>

                                  <td className="d-flex">
                                    {RolePermission?.findIndex(
                                      (e) =>
                                        e.permission_name ===
                                        "modify_part_inventory"
                                    ) > -1 && (
                                        <a
                                          className="edit-btn"
                                          href="#"
                                          onClick={() => {
                                            navigate(
                                              `/parts-inventory/AddParts/${item.id}`
                                            );
                                          }}
                                        >
                                          <i className="bi bi-pencil"></i>
                                        </a>
                                      )}
                                    {RolePermission?.findIndex(
                                      (e) =>
                                        e.permission_name ===
                                        "delete_part_inventory"
                                    ) > -1 && (
                                        <div className="trash-btn">
                                          <a
                                            className="delete-btn"
                                            href="#"
                                            onClick={(e) =>
                                              onDeleteModalOpen(e, item)
                                            }
                                          >
                                            <i className="bi bi-trash3"></i>
                                          </a>
                                        </div>
                                      )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {partsInventoryList &&
                          partsInventoryList?.length === 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className={`text-center`}
                              >{`Record Not Found`}</td>
                            </tr>
                          )}
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}

                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="pagination pagination-sm m-0 float-right card-footer-part">
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </section>
      </div>
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Inventory Part`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this inventory part?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartInventory}
            >
              Delete
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PartsInventory;
