import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import heroBg from "../../asset/images/hero-bg.png";
import bannerImg from "../../asset/images/illustration-img.png";

const Banner = ({ settingDetails }) => {
  const banner = [
    {
      heading: "A powerful solution for ",
      subHeading: "stock management",
    },
  ];
  return (
    <div id="Home">
      <div className="hero-bg d-flex align-items-center">
        <img
          src={heroBg}
          alt="Background"
          className="background-image position-absolute w-100 h-100"
          style={{ objectFit: "cover", zIndex: -1 }}
        />
        <Container>
          <Row className="mt-5 mb-5 align-items-center">
            <Col lg="6">
              {banner?.map((item, index) => (
                <div key={index} className="banner-content">
                  <h1>{item.heading}</h1>
                  <span>{item.subHeading}</span>
                </div>
              ))}
            </Col>
            <Col lg="6">
              <div className="banner-img">
                {settingDetails?.header_image ? (
                  <img
                    className="animated"
                    src={settingDetails?.header_image}
                  />
                ) : (
                  <img className="animated" src={bannerImg} />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Banner;
