import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  getPartsInventoryList,
  deletePartInventory,
} from "../../services/PartsInventory";
import { useNavigate, useLocation } from "react-router-dom";
import {
  actionDelDepollutionOpration,
  actionUpdateDepollutionOpration,
  getDepollutionOprationList,
} from "../../services/DepollutionService";

const DepollutionOpration = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const navigate = useNavigate();
  const [delModal, setDelModal] = useState(false);
  const [delPartId, setDelPartId] = useState("");
  const [catalog, setCatalog] = useState("");
  const [model, setModel] = useState("");
  const [year, SetYear] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [inventoryStatus, setInventoryStatus] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [depollutionData, setdepollutionData] = useState({
    uuid: "",
    ler_code_id: "",
    wastege_value: "",
    description: "",
    vehicle_make: "",
    vehicle_model: "",
    vehicle_start_year: "",
    vehicle_year: "",
    vehicle_id: "",
    vehicle_make1: "",
    vehicle_model1: "",
    vehicle_year1: "",
    ler_code: "",
  });
  const [lerCode, setLerCode] = useState("");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [depollutionList, setDepollutionList] = useState([]);

  const handleAddModal = (item) => {
    setModifyModal(true);
    setdepollutionData({
      uuid: item?.uuid,
      ler_code_id: item?.ler_code_id,
      wastege_value: item?.wastege_value,
      description: item?.description,
      vehicle_make: item?.vehicle_make,
      vehicle_model: item?.vehicle_model,
      vehicle_start_year: item?.vehicle_start_year,
      vehicle_year: item?.vehicle_year,
      vehicle_id: item?.vehicle_id,
      vehicle_make1: item?.vehicle_make1,
      vehicle_model1: item?.vehicle_model1,
      vehicle_year1: item?.vehicle_year1,
      ler_code: item?.ler_code
    });
  };

  const handleCloseModal = () => {
    setModifyModal(false);
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }

    setParams(paramsObj);
  };

  const fetchPartInventory = () => {
    let data = { ...params };
    getDepollutionOprationList(data)
      .then((response) => {
        setDepollutionList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartInventory(data);
    },
    [params]
  );

  const handlePartsInventory = () => {
    navigate("/add-vehicle-depollution-operation");
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPartId(item.uuid);
    setDelModal(true);
  };
  const onDeleteModalClose = () => {
    setDelModal(false);
  };
  const onDestroyPartInventory = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delPartId };
    actionDelDepollutionOpration(data)
      .then((response) => {
        let data = { ...params };
        fetchPartInventory(data);
        toast.success(response.data.message);
        setDelPartId("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };
  const handleEditDepollution = (e) => {
    e.preventDefault();

    let data = {
      uuid: depollutionData?.uuid,
      vehicle_id: depollutionData?.vehicle_id,
      ler_code_id: depollutionData?.ler_code_id,
      wastege_value: depollutionData?.wastege_value,
      description: depollutionData?.description,
    };

    actionUpdateDepollutionOpration(data)
      .then((response) => {
        toast.success(response.data.message);
        setModifyModal(false);
        let data = { ...params };
        fetchPartInventory(data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      {isLoading ? (
        // <div className="text-center pageLoader">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
          <div className="card">
            <div className="card-header">
              <table className="table rounded-table table-striped table-hover">
                <thead>
                  <tr>
                    <th style={{ width: 10, borderTop: "0", borderBottom: "0" }}><div className="skeleton" ></div></th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}><div className="skeleton"></div></th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                    <th class="d-flex" style={{ borderTop: "0", borderBottom: "0" }}><div class="skeleton me-1"></div>
                      <div class="skeleton me-1"></div>
                      <div class="skeleton"></div>
                    </th>
                    <th style={{ width: 10, borderTop: "0", borderBottom: "0" }}><div className="skeleton"></div></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-body">
              <div className="vehicleThBg table-responsive">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {depollutionList?.map((_, index) => (
                      <tr key={index}>
                        <td><div className="skeleton"></div></td>
                        <td><div className="skeleton"></div></td>
                        <td><div className="skeleton"></div></td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="skeleton col-lg-3 float-right"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1>Depollution Opration</h1> */}
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary-Vehicle fuelCard">
                    <div className="card-header">
                      <div className="card-title m-0 float-left fuelCT">
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>

                      </div>

                      <div className="card-title m-0 float-right fuelCT">
                        <button
                          type="button"
                          className="btn-icon-text mr-2"
                          onClick={() =>
                            navigate("/add-vehicle-part-depollution-operation")
                          }
                        >
                          <i className="bi bi-plus-lg"></i>
                          <span className="ms-2">
                            Add For Parts inventory Vehicle
                          </span>
                        </button>
                      </div>
                      <div className="card-title m-0 float-right fuelCT">
                        <button
                          type="button"
                          className="btn-icon-text mr-2 mr-2"
                          onClick={handlePartsInventory}
                        >
                          <i className="bi bi-plus-lg"></i>
                          <span className="ms-2">Add For Vehicle</span>
                        </button>

                      </div>
                      <div className="card-title m-0 float-right fuelCT">
                        <button
                          type="button"
                          className="btn-icon-text mr-2"
                          onClick={() => navigate("/other-depollution-operation")}
                        >
                          <i className="bi bi-plus-lg"></i>
                          <span className="ms-2">Other Vehicle</span>
                        </button>
                      </div>
                    </div>
                    <div className="card-body mt-3">
                      <div className="vehicleThBg table-responsive cstm-height">
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th className="Label-my">LER Code </th>
                              <th className="Label-my">Wastege Value</th>

                              <th className="Label-my" style={{ width: "10%" }}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {depollutionList?.map((item) => {
                              return (
                                <>
                                  <tr key={item.id}>
                                    <td>{item.ler_code}</td>
                                    <td>{item.wastege_value}</td>

                                    <td className="d-flex">
                                      <a
                                        className="edit-btn"
                                        href="#"
                                        onClick={() => {
                                          handleAddModal(item);
                                        }}
                                      >
                                        <i className="bi bi-pencil"></i>
                                      </a>

                                      <div className="trash-btn">
                                        <a
                                          className="delete-btn"
                                          href="#"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          <i className="bi bi-trash3"></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          {depollutionList && depollutionList?.length === 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className={`text-center`}
                              >{`Record Not Found`}</td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
            <div className="fuelCF">
              <div className="pagination pagination-sm m-0 float-right card-footer-part">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </section>
        </div>
      )}
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Inventory Part`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this inventory part?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartInventory}
            >
              Delete
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Depollution Opration</ModalHeader>
        <Form method={`post`} onSubmit={handleEditDepollution}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                {depollutionData?.vehicle_id === 0 ? (
                  <>
                    <ul style={{ listStyleType: "square" }}>
                      <li>
                        Vehicle Make : {depollutionData?.vehicle_make}
                      </li>
                      <li>
                        Vehicle Model : {depollutionData?.vehicle_model}
                      </li>
                      <li>
                        Vehicle Year : {depollutionData?.vehicle_year}
                      </li>
                      <li>
                        LER Code : {depollutionData?.ler_code}
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <ul style={{ listStyleType: "square" }}>
                      <li>
                        Vehicle Start Year : {depollutionData?.vehicle_start_year}
                      </li>
                      <li>
                        Vehicle Make : {depollutionData?.vehicle_make1}
                      </li>
                      <li>
                        Vehicle Model : {depollutionData?.vehicle_model1}
                      </li>
                      <li>
                        LER Code : {depollutionData?.ler_code}
                      </li>
                    </ul>
                  </>
                )}
              </div>
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Wastege Value"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search form-control"
                        type="number"
                        value={depollutionData.wastege_value}
                        onChange={(e) => {
                          setdepollutionData({
                            ...depollutionData,
                            wastege_value: e.target.value,
                          });
                        }}
                        name={`wastege_value`}
                        maxLength={50}
                        placeholder="Wastege Value"
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Description"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <textarea
                        className="form-control input-search form-control"
                        type="text"
                        value={depollutionData.description}
                        onChange={(e) => {
                          setdepollutionData({
                            ...depollutionData,
                            description: e.target.value,
                          });
                        }}
                        name={`description`}
                        maxLength={50}
                        placeholder="Description"
                        rows={3}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            <button
              type="submit"
              class="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
            >
              Submit <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DepollutionOpration;
