import axiosInstance from "./AxiosInstance";

export function createPartsInventory(data) {
  return axiosInstance.post(`/vehicle-inventory-store`, data);
}
export function updatePartsInventory(data) {
  return axiosInstance.post(`/vehicle-inventory-update`, data);
}

export function getVehicleList(data) {
  return axiosInstance.post(`/vehicle-search-list`, data);
}

export function getCatalogList(data) {
  return axiosInstance.post(`/catalog-search-list`, data);
}

export function getPartsInventoryList(data) {
  return axiosInstance.post(`/part-inventory-list`, data);
}

export function getPartInventoryDetails(data) {
  return axiosInstance.post(`/part-inventory-details`, data);
}

export function actionPartInventoryTempStore(data) {
  return axiosInstance.post(`/vehicle-inventory-temp-store`, data);
}

export function getPartInventoryTempList(data) {
  return axiosInstance.post(`/vehicle-inventory-temp-list`, data);
}

export function DestoryPartInventoryTemp(data) {
  return axiosInstance.post(`/vehicle-inventory-temp-delete`, data);
}

export function deletePartInventory(data) {
  return axiosInstance.post(`/vehicle-inventory-delete`, data);
}

export function getPartInventoryMakeList(data) {
  return axiosInstance.post(`/make-search-list`, data);
}

export function getPartInventoryYearList(data) {
  return axiosInstance.post(`/year-search-list`, data);
}

export function getPartInventoryModelList(data) {
  return axiosInstance.post(`/model-search-list`, data);
}

export function actionPartInventoryImage(data) {
  return axiosInstance.post(`/part-images`, data);
}

export function destoryPartInventoryImage(data) {
  return axiosInstance.post(`/part-images-delete`, data);
}

export function PartInventoryImageDetails(data) {
  return axiosInstance.post(`/part-images-details`, data);
}


