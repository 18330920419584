import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  createLerCatalog,
  deleteLerCatalog,
  getLerCatalogList,
  importLerCatalog,
  updateLerCatalog,
  getExportLerCatalogList,
} from "../../services/LerCatalogService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import * as XLSX from "xlsx";
import { useLocation } from "react-router-dom";

const LerCatalog = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [lerList, setlerList] = useState([]);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [partLocationData, setPartLocationData] = useState({
    uuid: "",
    ler_code: "",
    item_weight: "",
    item_weight_unit: "",
    is_body_ler: 0,
    ler_description: "",
  });

  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [file, setFile] = useState(null);

  let errorsObj = {
    ler_code: "",
    item_weight: "",
    item_weight_unit: "",
    is_body_ler: "",
    ler_description: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  let errorsObjImport = {
    file: "",
  };
  const [errorsImport, setErrorsImport] = useState(errorsObjImport);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (e) => {
    setPartLocationData({
      ...partLocationData,
      is_body_ler: e.target.checked ? 1 : 0,
    });
  };

  const handleImportModal = () => {
    setModifyImportModal(true);
  };

  const handleImportCloseModal = () => {
    setModifyImportModal(false);
  };

  const handleAddModal = () => {
    setPartLocationData("");
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setPartLocationData("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchLerCatLog = () => {
    let data = { ...params };
    setIsLoading(true);

    getLerCatalogList(data)
      .then((response) => {
        setlerList(response.data.data);
        setTotalRecords(response.data.total);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsLoading(false);
      });
  };

  const fetchExportLerCatLog = () => {
    let data = { ...params };
    getExportLerCatalogList(data)
      .then((response) => {
        const worksheet = XLSX.utils.json_to_sheet(response.data.data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "lerCatalog.csv");
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchLerCatLog(data);
    },
    [params]
  );

  const onEditLerCatalog = (e, item) => {
    setModifyModal(true);
    setPartLocationData({
      uuid: item?.uuid,
      ler_code: item?.ler_code,
      item_weight: item?.item_weight,
      item_weight_unit: item?.item_weight_unit,
      is_body_ler: item?.is_body_ler,
      ler_description: item?.ler_description,
    });
  };

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.uuid);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delpart };
    deleteLerCatalog(data)
      .then((response) => {
        fetchLerCatLog();
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handlePartLocationForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!partLocationData.ler_code) {
      errorObj.ler_code = `LER Code required.`;
      error = true;
    }

    if (!partLocationData.item_weight) {
      errorObj.item_weight = `Unit Type required.`;
      error = true;
    }

    if (!partLocationData.item_weight_unit) {
      errorObj.item_weight_unit = `Unit value required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      ler_code: partLocationData.ler_code,
      item_weight: partLocationData.item_weight,
      item_weight_unit: partLocationData.item_weight_unit,
      is_body_ler: partLocationData?.is_body_ler,
      ler_description: partLocationData?.ler_description,
    };

    let Updatedata = {
      uuid: partLocationData?.uuid,
      ler_code: partLocationData.ler_code,
      item_weight: partLocationData.item_weight,
      item_weight_unit: partLocationData.item_weight_unit,
      is_body_ler: partLocationData?.is_body_ler,
      ler_description: partLocationData?.ler_description,
    };

    if (partLocationData?.uuid) {
      updateLerCatalog(Updatedata)
        .then((response) => {
          toast.success(response.data.message);
          let datas = { ...params };

          fetchLerCatLog(datas);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createLerCatalog(data)
        .then((response) => {
          toast.success(response.data.message);
          let datas = { ...params };

          fetchLerCatLog(datas);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  const handleImportLocation = (e) => {
    e.preventDefault();
    let error = false;
    let errorObjImport = { ...errorsObjImport };
    if (!file) {
      errorObjImport.file = `File is required.`;
      error = true;
    }

    setErrorsImport(errorObjImport);
    if (error) return;
    setIsModifyButtonLoad(true);
    const formData = new FormData();
    formData.append("files", file);

    importLerCatalog(formData)
      .then((response) => {
        toast.success(response.data.message);
        let data = { ...params };
        fetchLerCatLog(data);
        setModifyImportModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const datas = [
    { name: "John Doe", age: 30, email: "john@example.com" },
    { name: "Jane Smith", age: 25, email: "jane@example.com" },
  ];

  const handleExport = () => {
    let data = { ...params };
    fetchExportLerCatLog(data);
  };
  const renderBodyLer = (value) => {
    return value === 1 ? "Yes" : "No";
  };

  return (
    <div>
      <>
        {isLoading ? (
          // <div className="text-center pageLoader">
          //   <div className="spinner-border" role="status">
          //     <span className="visually-hidden">Loading...</span>
          //   </div>
          // </div>
          <div className="skeleton-loader p-3 d-none">
            <div className="card">
              <div className="card-header">
                <table className="table rounded-table table-striped table-hover">
                  <thead>
                    <tr>
                      <th
                        style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                      >
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ borderTop: "0", borderBottom: "0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                      <th
                        class="d-flex"
                        style={{ borderTop: "0", borderBottom: "0" }}
                      >
                        <div class="skeleton me-1"></div>
                        <div class="skeleton me-1"></div>
                        <div class="skeleton"></div>
                      </th>
                      <th
                        style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                      >
                        <div className="skeleton"></div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="card-body">
                <div className="vehicleThBg table-responsive">
                  <table className="table table-bordered rounded-table table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{ backgroundColor: "#c0c0c0" }}>
                          <div className="skeleton"></div>
                        </th>
                        <th style={{ backgroundColor: "#c0c0c0" }}>
                          <div className="skeleton"></div>
                        </th>
                        <th style={{ backgroundColor: "#c0c0c0" }}>
                          <div className="skeleton"></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lerList?.map((_, index) => (
                        <tr key={index}>
                          <td>
                            <div className="skeleton"></div>
                          </td>
                          <td>
                            <div className="skeleton"></div>
                          </td>
                          <td>
                            <div className="skeleton"></div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <div className="skeleton col-lg-3 float-right"></div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">{/* <h1>LER Catalog</h1> */}</div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      {/* <li className="breadcrumb-item">
                    <a href="/dashboard">Home</a>
                  </li> */}
                      {/* <li className="breadcrumb-item active">Let Catalog</li> */}
                    </ol>
                  </div>
                </div>
              </div>
              {/* /.container-fluid */}
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card fuelCard">
                      <div className="card-header">
                        <div className="card-title m-0 float-left">
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          ></input>
                        </div>

                        <div className="card-title m-0 float-right d-flex">
                          {RolePermission?.findIndex(
                            (e) => e.permission_name === "add_ler_catalog"
                          ) > -1 && (
                              <button
                                type="button"
                                className="btn  btn-icon-text mr-2"
                                onClick={handleImportModal}
                              >
                                <i className="bi bi-file-earmark-excel-fill"></i>
                                <span className="ms-2">Import</span>
                              </button>
                            )}
                          {RolePermission?.findIndex(
                            (e) => e.permission_name === "export_ler_catalog"
                          ) > -1 && (
                              <button
                                type="button"
                                className="btn  btn-icon-text mr-2"
                                onClick={handleExport}
                              >
                                <i className="bi bi-file-earmark-excel-fill"></i>
                                <span className="ms-2">Export</span>
                              </button>
                            )}

                          {RolePermission?.findIndex(
                            (e) => e.permission_name === "import_ler_catalog"
                          ) > -1 && (
                              <button
                                type="button"
                                className="btn  btn-icon-text mr-2"
                                onClick={handleAddModal}
                              >
                                <i className="bi bi-plus-lg"></i>
                                <span className="ms-2">Add</span>
                              </button>
                            )}

                          {/* )} */}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="vehicleThBg cstm-height table-responsive" style={{height: "calc(76vh - 50px)"}}>
                          <table className="table table-bordered rounded-table table table-striped table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: 10 }}>#</th>
                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  Ler Code
                                </th>
                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  Unit Type
                                </th>
                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  Unit Value
                                </th>

                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  Is Body LER{" "}
                                </th>
                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: 10 }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {lerList?.map((item, index) => {
                                return (
                                  <>
                                    <tr key={item.id}>
                                      <td>{++index}</td>
                                      <td>{item.ler_code}</td>
                                      <td>{item.item_weight}</td>
                                      <td>{item.item_weight_unit}</td>
                                      <td>{renderBodyLer(item.is_body_ler)}</td>

                                      <td>
                                        <td className="d-flex">
                                          {(item.company_id > 0 ||
                                            companyId.role_id == 1) &&
                                            RolePermission?.findIndex(
                                              (e) =>
                                                e.permission_name ===
                                                "modify_ler_catalog"
                                            ) > -1 && (
                                              <a
                                                className="edit-btn"
                                                href="#"
                                                onClick={(e) =>
                                                  onEditLerCatalog(e, item)
                                                }
                                              >
                                                <i className="bi bi-pencil"></i>
                                              </a>
                                            )}

                                          {(item.company_id > 0 ||
                                            companyId.role_id == 1) &&
                                            RolePermission?.findIndex(
                                              (e) =>
                                                e.permission_name ===
                                                "delete_ler_catalog"
                                            ) > -1 && (
                                              <div className="trash-btn">
                                                <a
                                                  className="delete-btn"
                                                  href="#"
                                                  onClick={(e) =>
                                                    onDeleteModalOpen(e, item)
                                                  }
                                                >
                                                  <i className="bi bi-trash3"></i>
                                                </a>
                                              </div>
                                            )}
                                        </td>
                                      </td>
                                      {/* <a
                                    className="btn btn-app"
                                    href="#"
                                    onClick={(e) => onEditPartType(e, item)}
                                  >
                                    <i class="bi bi-pen"></i>
                                  </a> */}
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                            {lerList && lerList?.length === 0 && (
                              <tr>
                                <td
                                  colSpan={7}
                                  className={`text-center`}
                                >{`Record Not Found`}</td>
                              </tr>
                            )}
                          </table>
                        </div>
                      </div>
                      {/* /.card-body */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer clearfix card-footer-part fuelCF">
                <div className="pagination pagination-sm m-0 float-right">
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </section>
          </div>
        )}

        <Modal isOpen={delModal}>
          <ModalHeader>{`Delete LER Catalog`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this LER Catalog?`}</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={onDeleteModalClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>

            {!isDelButtonLoad && (
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartType}
              >
                Delete
              </button>
            )}
            {isDelButtonLoad && (
              <button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </button>
            )}
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyModal}>
          <ModalHeader>Ler Catalog</ModalHeader>
          <Form method={`post`} onSubmit={handlePartLocationForm}>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"LER Code"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control input-search form-control"
                          type="text"
                          value={partLocationData.ler_code}
                          onChange={(e) => {
                            setPartLocationData({
                              ...partLocationData,
                              ler_code: e.target.value,
                            });
                          }}
                          name={`ler_code`}
                          maxLength={50}
                          placeholder="LER Code"
                        />
                      </div>
                      {errors.ler_code && (
                        <span className="input-error">{errors.ler_code}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Unit Type"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control input-search form-control"
                          type="text"
                          value={partLocationData.item_weight}
                          onChange={(e) => {
                            setPartLocationData({
                              ...partLocationData,
                              item_weight: e.target.value,
                            });
                          }}
                          name={`item_weight`}
                          maxLength={50}
                          placeholder="Unit Type"
                        />
                      </div>
                      {errors.item_weight && (
                        <span className="input-error">
                          {errors.item_weight}
                        </span>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Unit Value"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control input-search form-control"
                          type="text"
                          value={partLocationData.item_weight_unit}
                          onChange={(e) => {
                            setPartLocationData({
                              ...partLocationData,
                              item_weight_unit: e.target.value,
                            });
                          }}
                          name={`item_weight_unit`}
                          maxLength={50}
                          placeholder="Unit Value"
                        />
                      </div>
                      {errors.item_weight_unit && (
                        <span className="input-error">
                          {errors.item_weight_unit}
                        </span>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Description"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <textarea
                          className="form-control input-search form-control"
                          type="text"
                          value={partLocationData.ler_description}
                          onChange={(e) => {
                            setPartLocationData({
                              ...partLocationData,
                              ler_description: e.target.value,
                            });
                          }}
                          name={`ler_description`}
                          maxLength={50}
                          placeholder="Description"
                          rows={3}
                        />
                      </div>
                      {errors.ler_description && (
                        <span className="input-error">
                          {errors.ler_description}
                        </span>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Is Body Ler"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div mt-12">
                        <div className="form-check">
                          <input
                            className="my-checkbox-1 form-check-input"
                            type="checkbox"
                            checked={partLocationData.is_body_ler}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label pl-6">
                            Is Body Ler
                          </label>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                class="btn btn-block btn-secondary"
                disabled={isModifyButtonLoad}
                onClick={handleCloseModal}
                style={{ width: "100px", marginTop: "10px" }}
              >
                Close <i class="bi bi-x-lg"></i>
              </button>
              {!isModifyButtonLoad && (
                <button
                  type="submit"
                  class="btn btn-block btn-primary nxt-btn"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  Submit <i class="bi bi-check2"></i>
                </button>
              )}
              {isModifyButtonLoad && (
                <Button
                  color="success"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modifyImportModal}>
          <Form method={`post`} onSubmit={handleImportLocation}>
            <ModalHeader>{`Import LER Catalog`}</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">Select File</Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="file"
                          onChange={(e) => setFile(e.target.files[0])}
                          name="file"
                          accept=".csv,.xlsx" // adjust file types as needed
                        />
                      </div>
                      {errorsImport.file && (
                        <span className="input-error">{errorsImport.file}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                class="btn btn-block btn-secondary"
                disabled={isModifyButtonLoad}
                onClick={handleImportCloseModal}
                style={{ width: "100px", marginTop: "10px" }}
              >
                Close
              </button>
              {!isModifyButtonLoad && (
                <button
                  type="submit"
                  class="btn btn-block btn-primary"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  Import
                </button>
              )}
              {isModifyButtonLoad && (
                <Button
                  color="success"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </>
    </div>
  );
};

export default LerCatalog;
