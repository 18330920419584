

import axiosInstance from "./AxiosInstance";

export function actionSettingAdd(data) {
    return axiosInstance.post(`/company/setting-update`, data);
}

export function getSettingsDetails(data) {
    return axiosInstance.post(`/company/setting-details`, data);
}

export function actionAddContactUs(data) {
    return axiosInstance.post(`/company/contact`, data);
}

export function actionAddSubscribe(data) {
    return axiosInstance.post(`/company-subscribe`, data);
}