import React, { useEffect, useState } from "react";
import ConfigDB from "../../config";
import { Form, FormGroup, Label } from "reactstrap";

import {
  profileDetail,
  profileDetailForWeb,
} from "../../services/AuthServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import moment from "moment";

const Profile = () => {
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
  });

  const [profileDatas, setProfileDatas] = useState(null);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  let errorsObj = {
    password: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [image, setImage] = useState(null);

  let errorsPicObj = {
    image: "",
  };
  const [errorsPic, setErrorsPic] = useState(errorsPicObj);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      setImage(selectedFiles);
      const imageUrl = URL.createObjectURL(selectedFiles);
      setPreviewUrl(imageUrl);
    }
  };

  useEffect(() => {
    profileDetailForWeb()
      .then((response) => {
        setProfileDatas(response.data.data);
        setProfileData({
          name: response.data.data.name || "",
          email: response.data.data.email || "",
        });
        setPreviewUrl(response.data.data.profile);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateImage = (image) => {
    if (!image) {
      return false;
    }
    const allowedTypes = ["image/jpeg", "image/png"];
    const maxSize = 2 * 1024 * 1024; // 2MB
    return allowedTypes.includes(image.type) && image.size <= maxSize;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };

    let errorObjPic = { ...errorsPicObj };
    let errorPic = false;

    if (password && password.length < 8) {
      errorObj.password = "Password must be at least 8 characters long.";
      error = true;
    }

    if (confirmPassword && confirmPassword.length < 8) {
      errorObj.confirmPassword = "Password must be at least 8 characters long.";
      error = true;
    } else if (password && password !== confirmPassword) {
      errorObj.confirmPassword = "Passwords do not match!";
      error = true;
    }

    if (!validateImage(image)) {
      errorObjPic.image =
        "Invalid file. Please upload a valid image. Size must be < 2mb";
      errorPic = true;
    }

    setErrors(errorObj);
    if (error) return;

    const formData = new FormData();
    formData.append("files", image);
    formData.append("password", password);
    formData.append("name", profileData.name);
    formData.append("email", profileData.email);

    profileDetail(formData)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Profile</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Update Profile</h3>
                  </div>

                  <Form method={`post`} onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label ml-2 Label-my form-label form-label">
                              {"Name"}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  placeholder="Enter name"
                                  value={profileData?.name}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>

                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label ml-2 Label-my form-label form-label">
                              {"Email"}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Enter email"
                                  value={profileData?.email}
                                  disabled
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label ml-2 Label-my form-label form-label">
                              {"Password"}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="password"
                                  placeholder="Enter new password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </div>
                              {errors.password && (
                                <span className="input-error">
                                  {errors.password}
                                </span>
                              )}
                            </div>
                          </FormGroup>
                        </div>

                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label ml-2 Label-my form-label form-label">
                              {"Confirm Password"}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="confirmPassword"
                                  placeholder="Confirm new password"
                                  value={confirmPassword}
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                />
                              </div>
                              {errors.confirmPassword && (
                                <span className="input-error">
                                  {errors.confirmPassword}
                                </span>
                              )}
                            </div>
                          </FormGroup>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Profile"}
                          </Label>
                          <div className="avatar-upload">
                            <div className="avatar-edit">
                              <div className="input-div">
                                <input
                                  className="form-control"
                                  type="file"
                                  id="imageUpload"
                                  onChange={handleFileChange}
                                  name="file"
                                  accept=".jpg,.jpeg,.png"
                                />
                              </div>
                              <label htmlFor="imageUpload"></label>
                            </div>
                            <div className="avatar-preview profile-user">
                              <img src={previewUrl} className="preview-image" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6"></div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-7"
                        >
                          <h4
                            className="col-form-label ml-2 form-label form-label"
                            style={{
                              fontSize: "20px !important",
                              color: "#344767",
                            }}
                          >
                            SubScription Details
                          </h4>
                          <h4
                            style={{
                              fontSize: "15px",
                              marginTop: "-13px",
                              color: "#344767",
                            }}
                            className="col-form-label ml-2 form-label form-label"
                          >
                            <span style={{ fontWeight: "600px" }}>
                              Subscribed On :{" "}
                              <span style={{ fontWeight: "400px" }}>
                                {moment(
                                  profileDatas?.subscription_data?.subscribed_on
                                ).format("DD/MM/YYYY")}
                              </span>
                            </span>
                            <span
                              style={{
                                marginLeft: "16px",
                                fontWeight: "600px",
                              }}
                            >
                              Subscribed End :{" "}
                              <span style={{ fontWeight: "400px" }}>
                                {moment(
                                  profileDatas?.subscription_data
                                    ?.subscribed_ends
                                ).format("DD/MM/YYYY")}
                              </span>
                            </span>
                          </h4>
                        </div>

                        <div className="col-md-5">
                          <h4
                            className="col-form-label ml-2  form-label form-label"
                            style={{
                              fontSize: "20px !important",
                              color: "#344767",
                            }}
                          >
                            Last Login Details
                          </h4>
                          <h4
                            style={{
                              fontSize: "15px",
                              marginTop: "-13px",
                              color: "#344767",
                            }}
                            className="col-form-label ml-2 form-label form-label"
                          >
                            <span style={{ fontWeight: "600px" }}>
                              Last Login Date:{" "}
                              <span style={{ fontWeight: "400px" }}>
                                {moment(profileDatas?.last_login_date).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </span>
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="card-footer d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Profile;
