import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import {
  createVehicleDocument,
  deleteVehicleImage,
} from "../../services/VehicleService";

const VehicleDocument = ({
  setActiveTab,
  vehicleProcessList = [],
  activeTab,
}) => {
  const [citizenCard, setCitizenCard] = useState(null);

  const [certificate, setCertificate] = useState(null);
  const [singleCar, setSingleCar] = useState(null);
  const [modelImtt, setModelImtt] = useState(null);
  const [chassisNo, setChassisNo] = useState(null);
  const [previewUrls, setPreviewUrls] = useState({
    citizen_card_file: null,
    certificate_file: null,
    single_car_document_file: null,
    model_imtt_file: null,
    chassis_no_file: null,
  });
  const [showPreviews, setShowPreviews] = useState({
    citizen_card_file: false,
    certificate_file: false,
    single_car_document_file: false,
    model_imtt_file: false,
    chassis_no_file: false,
  });
  const [removedImages, setRemovedImages] = useState({});

  let vehicleId = localStorage.getItem("vehicleId");

  const navigate = useNavigate();

  const isImageFile = (file) => file && file.type.startsWith("image/");

  const validateFiles = () => {
    const files = [citizenCard, certificate, singleCar, modelImtt, chassisNo];
    const imageFiles = files.filter(isImageFile);
    return imageFiles.length >= 2;
  };

  useEffect(() => {
    if (
      Array.isArray(vehicleProcessList?.document_file) &&
      activeTab === "vehicle_documents"
    ) {
      const initializeData = () => {
        const documentTypes = [
          "citizen_card_file",
          "certificate_file",
          "single_car_document_file",
          "model_imtt_file",
          "chassis_no_file",
        ];

        const newPreviewUrls = {};
        const newShowPreviews = {};

        documentTypes.forEach((type) => {
          const docData = vehicleProcessList?.document_file?.find(
            (item) => item.document_type === type
          );
          newPreviewUrls[type] = docData ? docData.document_file : null;
          newShowPreviews[type] = !!docData;
        });

        setPreviewUrls(newPreviewUrls);
        setShowPreviews(newShowPreviews);
        setCitizenCard(newPreviewUrls?.citizen_card_file);
        setCertificate(newPreviewUrls?.certificate_file);
        setSingleCar(newPreviewUrls?.single_car_document_file);
        setModelImtt(newPreviewUrls?.model_imtt_file);
        setChassisNo(newPreviewUrls?.chassis_no_file);
      };

      initializeData();
    }
  }, [vehicleProcessList, activeTab]);

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrls((prev) => ({ ...prev, [`${fileType}`]: url }));
      switch (fileType) {
        case "citizen_card_file":
          setCitizenCard(file);
          break;
        case "certificate_file":
          setCertificate(file);
          break;
        case "single_car_document_file":
          setSingleCar(file);
          break;
        case "model_imtt_file":
          setModelImtt(file);
          break;
        case "chassis_no_file":
          setChassisNo(file);
          break;
        default:
          break;
      }
    } else {
      setPreviewUrls((prev) => ({ ...prev, [`${fileType}`]: null }));
    }
  };

  // const handleRemoveImage = (name, id) => {

  //   setPreviewUrls((prevState) => ({
  //     ...prevState,
  //     [`${name}`]: null,
  //   }));

  //   setShowPreviews((prevState) => ({
  //     ...prevState,
  //     [`${name}`]: false,
  //   }));

  //   setRemovedImages((prevState) => ({
  //     ...prevState,
  //     [name]: id,
  //   }));

  //   if (id) {
  //     const data = { id };

  //     deleteVehicleImage(data)
  //       .then((response) => {
  //         toast.success(response.data.message);
  //         // Optionally handle successful removal
  //         // setActiveTab("vehicle_extra_info");
  //       })
  //       .catch((err) => {
  //         toast.error(Utils.getErrorMessage(err));
  //       });
  //   } else {
  //     console.error("ID is undefined. Cannot remove image.");
  //   }
  // };

  const togglePreview = (fileType) => {
    setShowPreviews((prev) => ({
      ...prev,
      [`${fileType}`]: !prev[`${fileType}`],
    }));
  };

  const handleVehicleDocumentForm = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("citizen_card_file", citizenCard);

    formData.append("certificate_file", certificate);

    formData.append("single_car_document_file", singleCar);

    formData.append("model_imtt_file", modelImtt);

    formData.append("chassis_no_file", chassisNo);
    formData.append("vehicle_id", vehicleId);

    const hasFiles = citizenCard || certificate || singleCar || modelImtt || chassisNo;


    if (vehicleProcessList?.id) {
      const formData = new FormData();

      formData.append("citizen_card_file", citizenCard);

      formData.append("certificate_file", certificate);

      formData.append("single_car_document_file", singleCar);

      formData.append("model_imtt_file", modelImtt);

      formData.append("chassis_no_file", chassisNo);
      formData.append("vehicle_id", vehicleProcessList.id);
      formData.append("uuid", vehicleProcessList.uuid);
    }

if (hasFiles) {
    createVehicleDocument(formData)
      .then((response) => {
        toast.success(response.data.message);
        setActiveTab("vehicle_extra_info");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  } else {
    setActiveTab("vehicle_extra_info");
  }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-primary">
          <Form method="post" onSubmit={handleVehicleDocumentForm}>
            <div className="card-body">
              <div className="row justify-content-start mb-5 ms-1 me-1">
                {[
                  { name: "citizen_card_file", label: "Citizen Card" },
                  { name: "certificate_file", label: "Certificate" },
                  { name: "single_car_document_file", label: "Single Car" },
                  { name: "model_imtt_file", label: "Model IMTT" },
                  { name: "chassis_no_file", label: "Chassis Number" },
                ].map(({ name, label }) => (
                  <React.Fragment key={name}>
                    <div className="col-md-4">
                      <div className="fileupload-content">
                        <div className="fileupload-content-2">
                          <FormGroup>
                            <Label className="col-form-label ml-2">
                              {label}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  className="inputFile"
                                  type="file"
                                  onChange={(e) => handleFileChange(e, name)}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                />
                              </div>
                            </div>
                          </FormGroup>

                          <div
                            className="Ss"
                            style={{ marginTop: "35px", marginLeft: "5px" }}
                          >
                            <div style={{cursor: "pointer"}}
                              className="icon-circle"
                              onClick={() => togglePreview(name)}
                            >
                              <i className="bi bi-eye"></i>
                            </div>
                          </div>
                        </div>
                        {showPreviews[name] && previewUrls[name] && (
                          <div className="col-md-12 mt-3">
                            <img
                              className="Preview-img"
                              src={previewUrls[name]}
                              alt="Preview"
                              style={{ width: "500px", height: "214px" }}
                            />
                            {/* <i
                        className="bi bi-x-circle"
                        style={{
                          fontSize: "25px",
                          cursor: "pointer",
                          color: "red",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                    

                          const foundItem = vehicleProcessList.find(
                            (item) => item.document_type === name
                          );
                          const imageId = foundItem?.id;
                          

                          handleRemoveImage(name.replace("_file", ""), imageId);
                        }}
                      ></i> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>

              <div className="row">
                <div className="col">
                  <button
                    type="button"
                    className="back-btn"
                    onClick={() => setActiveTab("vehicle_images")}
                  >
                    <i class="bi bi-arrow-left"></i> Back
                  </button>
                </div>
                <div className="col">
                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary nxt-btn">
                      Next <i class="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default VehicleDocument;
