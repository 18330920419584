import React, { useEffect, useRef, useState } from "react";
import {
  getCatalogList,
  actionPartInventoryTempStore,
  getVehicleList,
  getPartInventoryTempList,
  deletePartInventory,
  DestoryPartInventoryTemp,
  createPartsInventory,
  getPartInventoryDetails,
  updatePartsInventory,
  getPartInventoryMakeList,
  getPartInventoryModelList,
  getPartInventoryYearList,
  actionPartInventoryImage,
  destoryPartInventoryImage,
  PartInventoryImageDetails,
} from "../../services/PartsInventory";
import { Button, FormGroup, Label, Form } from "reactstrap";
import Select from "react-select";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import VehiclePartsList from "./VehiclePartsList";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getVehicleModelAllList } from "../../services/VehicleService";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";

const AddForParts = ({ vehicleAddDetails }) => {
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTemp, setSearchQueryTemp] = useState("");
  const [VehicleModelList, setVehicleModelList] = useState([]);
  const [VehicleModelAllList, setVehicleModelAllList] = useState([]);
  const [CatalogList, setCatalogList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tempData, setTempData] = useState({});

  const [partsData, setPartsData] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [inputValueMake, setInputValueMake] = useState("");
  const [inputValueYear, setInputValueYear] = useState("");
  const [inputValueModal, setInputValueModal] = useState("");

  const [inputSelctedValue, setInputSelctedValue] = useState("");
  const [categoryPartList, setCategoryPartList] = useState([]);
  const [categoryPartsData, setCategoryPartsData] = useState({
    catalog_id: "",
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
    part_name: "",
  });
  const [selectedVehicleModel, setSelectedVehicleModel] = useState({});
  const [vehiclePartsData, setVehiclePartsData] = useState({
    vehicle_model_id: "",
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
  });
  const [isEnlarged, setIsEnlarged] = useState(false);

  const { uuid } = useParams();

  let errorsObj = {
    part_code: "",
    part_name: "",
    quantity: "",
    part_price: "",
    part_original_price: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  let errorsObjs = {
    catalog_id: "",
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
    part_name: "",
  };
  const [errorss, setErrorss] = useState(errorsObjs);
  const [images, setImage] = useState([]);
  const [previewUrl, SetPreviewUrl] = useState([]);
  const [showModalAbout, setShowModalAbout] = useState(false);
  const [makeList, SetMakeList] = useState([]);
  const [yearList, SetYearList] = useState([]);
  const [modelList, SetModelList] = useState([]);
  const [detailsForImages, SetDetailsForImages] = useState([]);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [showSkip, setShowSkip] = useState(false);
  const [enlargedIndexes, setEnlargedIndexes] = useState([]);
  const [inputValues, setInputValues] = useState([]); // Initialize as an empty array

  const handleSkip = () => {
    setCurrentPartIndex((prevIndex) => prevIndex + 1);
    setShowSkip(false);
  };

  const handleOpenSkip = () => {
    setShowSkip(true);
  };

  const handleCloseSkip = () => {
    setShowSkip(false);
  };

  const handleFinish = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("part_id", currentPart?.id);
    images.forEach((file) => {
      formData.append("files", file);
    });

    actionPartInventoryImage(formData)
      .then((response) => {
        toast.success(response.data.message);
        setImage([]);
        SetPreviewUrl([]);
        navigate("/parts-inventory");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const currentPart = detailsForImages[currentPartIndex];
  const fileInputRef = useRef(null);


  const hasNextPart = currentPartIndex < detailsForImages.length;

  const handleSubmits = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("part_id", currentPart?.id);
    images.forEach((file) => {
      formData.append("files", file);
    });

    actionPartInventoryImage(formData)
      .then((response) => {
        toast.success(response.data.message);
        setImage([]);
        SetPreviewUrl([]);
        setCurrentPartIndex((prevIndex) => prevIndex + 1);
        if (images.length === 1) {
          fileInputRef.current.value = "";
        }

      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleAboutFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 0) {
      setImage((prevImages) => [...prevImages, ...selectedFiles]);

      const newPreviewUrls = selectedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      SetPreviewUrl((prevUrls) => [...prevUrls, ...newPreviewUrls]);
    }
  };
  const isLastPart = currentPartIndex === detailsForImages?.length - 1;

  const handleRemoveFile = (indexToRemove) => {
    setImage((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    SetPreviewUrl((prevUrls) =>
      prevUrls.filter((_, index) => index !== indexToRemove)
    );

    if (images.length === 1) {
      fileInputRef.current.value = "";
    }

    // if (idToRemove?.id) {
    //   setImage((prevFiles) =>
    //     prevFiles.filter((file) => file.id !== idToRemove.id)
    //   );

    //   const data = { id: idToRemove };
    //   destoryPartInventoryImage(data)
    //     .then((response) => {
    //       toast.success(response.data.message);
    //     })
    //     .catch((err) => {
    //       toast.error(Utils.getErrorMessage(err));
    //     });
    // }
  };

  // const handleRemoveFile = (indexToRemove) => {
  //   setImage((prevFiles) =>
  //     prevFiles.filter((_, index) => index !== indexToRemove)
  //   );

  //   SetPreviewUrl((prevUrls) =>
  //     prevUrls.filter((_, index) => index !== indexToRemove)
  //   );

  //   setInputValues((prevValues) =>
  //     prevValues.filter((_, index) => index !== indexToRemove)
  //   );
  // };

  const handleShowAbout = () => setShowModalAbout(true);
  const handleCloseAbout = () => setShowModalAbout(false);

  useEffect(() => {
    const isDuplicatePart = (part, newPart) => {
      return (
        part.part_code === newPart.part_code &&
        part.vehicle_make === newPart.vehicle_make &&
        part.vehicle_model === newPart.vehicle_model &&
        part.vehicle_year === newPart.vehicle_year
      );
    };

    if (vehicleAddDetails?.id) {
      const newPart = {
        catalog_id: vehicleAddDetails.catalog_id,
        namePartPT: vehicleAddDetails.namePartPT,
        part_code: vehicleAddDetails?.part_code,
        part_name: vehicleAddDetails?.part_name,
        quantity: vehicleAddDetails?.quantity,
        part_price: vehicleAddDetails?.part_price,
        part_original_price: vehicleAddDetails?.part_original_price,
        inventory_status: vehicleAddDetails?.inventory_status,
        parts_associate: vehicleAddDetails?.parts_associate,
        sponsor_price: vehicleAddDetails?.sponsor_price,
        part_description: vehicleAddDetails?.part_description,
        vehicle_make: vehicleAddDetails.vehicle_make,
        vehicle_model: vehicleAddDetails.vehicle_model,
        vehicle_model_id: vehicleAddDetails.vehicle_parts_id,
        vehicle_year: vehicleAddDetails.vehicle_year,
      };

      setPartsData((prevData) => {
        if (!prevData.some((part) => isDuplicatePart(part, newPart))) {
          return [...prevData, newPart];
        }
        return prevData;
      });
    } else if (selectedItems && selectedItems.length > 0) {
      const selectedParts = selectedItems.map((item) => ({
        tempId: item.id,
        temp_id: item.temp_id,
        id: item.catalog_id,
        namePartPT: item.namePartPT,
        part_code: "",
        part_name: "",
        quantity: "",
        part_price: "",
        part_original_price: "",
        inventory_status: 0,
        sponsor_price: "",
        part_description: "",
        parts_associate: [],
        images: [],
        vehicle_make: item.vehicle_make,
        vehicle_model: item.vehicle_model,
        vehicle_model_id: item.vehicle_model_id,
        vehicle_year: item.vehicle_year,
      }));

      setPartsData(selectedParts);
    }
  }, [vehicleAddDetails, selectedItems]);

  const handleInputChangePartList = (e, id) => {
    const { name, value, type, checked } = e.target;
    setPartsData((prevData) =>
      prevData.map((part) =>
        part.tempId === id
          ? { ...part, [name]: type === "checkbox" ? (checked ? 1 : 0) : value }
          : part
      )
    );
  };

  const handleAddClick = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObjs };

    if (!categoryPartsData.catalog_id) {
      errorObj.catalog_id = `Part is required.`;
      error = true;
    }

    if (!categoryPartsData.vehicle_make) {
      errorObj.vehicle_make = `Vehicle Make is required.`;
      error = true;
    }

    if (!categoryPartsData.vehicle_year) {
      errorObj.vehicle_year = `Vehicle Year is required.`;
      error = true;
    }

    if (!categoryPartsData.vehicle_model) {
      errorObj.vehicle_model = `Vehicle Model is required.`;
      error = true;
    }

    if (!categoryPartsData.part_name) {
      errorObj.part_name = `Part Name is required.`;
      error = true;
    }

    setErrorss(errorObj);
    if (error) return;

    let data = {
      catalog_id: categoryPartsData?.catalog_id,
      temp_id: tempData?.temp_id ? tempData?.temp_id : "",
      vehicle_make: categoryPartsData?.vehicle_make,
      vehicle_year: categoryPartsData?.vehicle_year,
      vehicle_model: categoryPartsData?.vehicle_model,
      part_name: categoryPartsData?.part_name,
    };

    actionPartInventoryTempStore(data)
      .then((response) => {
        // toast.success(response.data.message);
        setTempData(response.data.data);

        setCategoryPartsData({
          catalog_id: "",
          vehicle_make: "",
          vehicle_year: "",
          vehicle_model: "",
          part_name: "",
        });
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleDeleteClick = (item) => {
    let data = {
      id: item.tempId ? item.tempId : item?.id,
    };

    setTempData(item);

    DestoryPartInventoryTemp(data)
      .then((response) => {
        toast.success(response.data.message);

        const fetchData = async () => {
          try {
            const obj = {
              search: searchQueryTemp,
              limit: tableLengthList[0],
              page: 1,
              temp_id: item?.temp_id,
            };
            const response = await getPartInventoryTempList(obj);
            setSelectedItems(response.data.data);
          } catch (err) {
            console.error("Error fetching part inventory:", err);
          }
        };

        fetchData();
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(() => {
    if (selectedItems?.length === 0) {
      setCurrentStep(1);
    }
  }, [selectedItems]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = "";
    } else if (type === "pagination") {
      paramsObj.page = e;
    }

    setParams(paramsObj);
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getVehicleList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: `${response.data[i].vehicle_make}/ ${response.data[i].vehicle_model} /${response.data[i].vehicle_year}`,
            vehicle_year: response.data[i].vehicle_year,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    var obj = {
      text: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getVehicleModelAllList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].vehicle_model,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_year: response.data[i].vehicle_end_year,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelAllList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const obj = {
      text: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getCatalogList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        const LangData = localStorage.getItem("lng") || "en";

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label:
              LangData === "pt"
                ? response.data[i].namePartPT
                : LangData === "fr"
                ? response.data[i].namePartFR
                : LangData === "es"
                ? response.data[i].namePartES
                : LangData === "it"
                ? response.data[i].namePartIT
                : LangData === "de"
                ? response.data[i].namePartDE
                : response.data[i].namePartEN,
          });
        }
        setCategoryPartList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getPartInventoryMakeList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].vehicle_make,
            label: response.data[i].vehicle_make,
          });
        }
        SetMakeList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (categoryPartsData?.vehicle_make) {
      const obj = {
        search: "",
        vehicle_make: categoryPartsData?.vehicle_make,
        limit: 5,
        page: 1,
      };

      getPartInventoryYearList(obj)
        .then((response) => {
          response = response.data;
          let list = [];

          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].vehicle_start_year,
              label: response.data[i].vehicle_start_year,
            });
          }
          SetYearList(list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [categoryPartsData?.vehicle_make]);

  useEffect(() => {
    if (categoryPartsData?.vehicle_make && categoryPartsData?.vehicle_year) {
      const obj = {
        search: "",
        vehicle_make: categoryPartsData?.vehicle_make,
        vehicle_start_year: categoryPartsData?.vehicle_year,
        limit: 10,
        page: 1,
      };

      getPartInventoryModelList(obj)
        .then((response) => {
          response = response.data;
          let list = [];

          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].vehicle_model,
              label: response.data[i].vehicle_model,
            });
          }
          SetModelList(list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [categoryPartsData?.vehicle_make, categoryPartsData?.vehicle_year]);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);

    if (inputValue.length > 2) {
      const obj = {
        search: inputValue,
        limit: tableLengthList[0],
        page: 1,
      };

      const LangData = localStorage.getItem("lng") || "en";

      getCatalogList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          const LangData = localStorage.getItem("lng") || "en";

          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label:
                LangData === "pt"
                  ? response.data[i].namePartPT
                  : LangData === "fr"
                  ? response.data[i].namePartFR
                  : LangData === "es"
                  ? response.data[i].namePartES
                  : LangData === "it"
                  ? response.data[i].namePartIT
                  : LangData === "de"
                  ? response.data[i].namePartDE
                  : response.data[i].namePartEN,
            });
          }
          setCategoryPartList(list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleInputChangeMake = (inputValue) => {
    setInputValueMake(inputValue);

    if (inputValueMake.length > 2) {
      const obj = {
        search: "",
        limit: tableLengthList[0],
        page: 1,
      };

      getPartInventoryMakeList(obj)
        .then((response) => {
          response = response.data;
          let list = [];

          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].vehicle_make,
              label: response.data[i].vehicle_make,
            });
          }
          SetMakeList(list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleInputChangeYear = (inputValue) => {
    setInputValueYear(inputValue);

    if (inputValueYear.length > 2) {
      const obj = {
        search: "",
        vehicle_make: categoryPartsData?.vehicle_make,
        limit: 5,
        page: 1,
      };

      getPartInventoryYearList(obj)
        .then((response) => {
          response = response.data;
          let list = [];

          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].vehicle_start_year,
              label: response.data[i].vehicle_start_year,
            });
          }
          SetYearList(list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleInputChangeModal = (inputValue) => {
    setInputValueModal(inputValue);

    if (inputValueModal.length > 2) {
      const obj = {
        search: "",
        vehicle_make: categoryPartsData?.vehicle_make,
        vehicle_start_year: categoryPartsData?.vehicle_year,
        limit: 10,
        page: 1,
      };

      getPartInventoryModelList(obj)
        .then((response) => {
          response = response.data;
          let list = [];

          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].vehicle_model,
              label: response.data[i].vehicle_model,
            });
          }
          SetModelList(list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleInputSelectedChange = (inputSelctedValue) => {
    setInputSelctedValue(inputSelctedValue);

    if (inputSelctedValue.length > 2) {
      const obj = {
        text: inputSelctedValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleModelAllList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_model,
            });
          }
          setVehicleModelAllList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChanges = (selectedOptions, id) => {
    const optionsArray = Array.isArray(selectedOptions) ? selectedOptions : [];

    setPartsData((prevData) =>
      prevData.map((part) =>
        part.id === id
          ? {
              ...part,
              parts_associate: optionsArray.map((option) => ({
                label: option.vehicle_model,
                vehicle_make: option.vehicle_make,
                vehicle_year: option.vehicle_year,
                vehicle_model: option.vehicle_model,
              })),
            }
          : part
      )
    );
  };

  useEffect(
    function () {
      var obj = {
        search: searchQuery,
        limit: tableLengthList[0],
        page: 1,
      };
      getCatalogList(obj)
        .then((response) => {
          response = response.data;
          setCatalogList(response);
        })
        .catch((err) => {});
    },
    [searchQuery]
  );

  useEffect(() => {
    if (tempData?.temp_id) {
      const fetchData = async () => {
        try {
          const obj = {
            search: searchQueryTemp,
            limit: tableLengthList[0],
            page: 1,
            temp_id: tempData?.temp_id,
          };
          const response = await getPartInventoryTempList(obj);
          setSelectedItems(response.data.data);
          setTotalRecords(response.data.total);
        } catch (err) {
          console.error("Error fetching part inventory:", err);
        }
      };

      fetchData();
    }
  }, [searchQueryTemp, tempData]);

  const createPayload = () => {
    return partsData.map((part) => ({
      vehicle_id: vehiclePartsData?.vehicle_model_id || 0,
      part_catelog: part.id || "",
      part_code: part?.part_code,
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      part_original_price: part?.part_original_price,
      inventory_status: part.inventory_status,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_year,
      vehicle_model: part?.vehicle_model,
      sponsor_price: part?.sponsor_price,
      part_description: part?.part_description,
      parts_associate: part?.parts_associate?.map((model) => ({
        vehicle_make: model.vehicle_make,
        vehicle_year: model.vehicle_year,
        vehicle_model: model.vehicle_model,
      })),
    }));
  };

  const createPayloads = () => {
    const part = partsData[0];

    return {
      uuid: vehicleAddDetails?.uuid,
      id: vehicleAddDetails?.id,
      vehicle_id: part?.vehicle_model_id ? part?.vehicle_model_id : 0,
      part_catelog: part.id || vehicleAddDetails?.part_catelog,
      part_code: part?.part_code,
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      part_original_price: part?.part_original_price,
      inventory_status: part.inventory_status,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_year,
      vehicle_model: part?.vehicle_model,
      part_description: part?.part_description,
      sponsor_price: part?.sponsor_price,
      parts_associate:
        part?.parts_associate?.map((model) => ({
          uuid: model?.uuid,
          vehicle_make: model.vehicle_make,
          vehicle_year: model.vehicle_year,
          vehicle_model: model.vehicle_model,
        })) || [],
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    partsData.forEach((part) => {
      if (!part.part_code) {
        errorObj.part_code = `required.`;
        error = true;
      }

      if (!part.part_name) {
        errorObj.part_name = `required.`;
        error = true;
      }

      if (!part.quantity) {
        errorObj.quantity = `required.`;
        error = true;
      }

      if (!part.part_price) {
        errorObj.part_price = `required.`;
        error = true;
      }

      if (!part.part_original_price) {
        errorObj.part_original_price = `required.`;
        error = true;
      }
    });

    setErrors(errorObj);
    if (error) return;

    const payloads = createPayload();
    const payloadsUpdated = createPayloads();

    if (vehicleAddDetails?.id) {
      updatePartsInventory(payloadsUpdated)
        .then((response) => {
          toast.success(response.data.message);
          // navigate("/parts-inventory");
          setCurrentStep(2);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      let data = {
        temp_id: tempData?.temp_id,
        part_list: payloads,
      };
      createPartsInventory(data)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          // navigate("/parts-inventory");

          setCurrentStep(3);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleNextClick = () => {
    if (!tempData?.temp_id || selectedItems.length === 0) {
      toast.error("Please select a vehicle part.");
    } else {
      setCurrentStep(2);
    }
  };

  const handleSelectChangeCategoryPartList = (selectedOption) => {
    setCategoryPartsData({
      ...categoryPartsData,
      catalog_id: selectedOption ? selectedOption.value : "",
    });
  };

  const handleSelectChangeMakeList = (selectedOption) => {
    setCategoryPartsData({
      ...categoryPartsData,
      vehicle_make: selectedOption ? selectedOption.label : "",
    });
  };

  const handleSelectChangeYearList = (selectedOption) => {
    setCategoryPartsData({
      ...categoryPartsData,
      vehicle_year: selectedOption ? selectedOption.label : "",
    });
  };

  const handleSelectChangeModalList = (selectedOption) => {
    setCategoryPartsData({
      ...categoryPartsData,
      vehicle_model: selectedOption ? selectedOption.label : "",
    });
  };

  // const handleAddPart = (e, index) => {
  //   e.preventDefault();
  //   if (selectedVehicleModel) {
  //     let list = [...partsData];
  //     let partList = list[index]["parts_associate"];

  //     let idx = partList.findIndex(
  //       (x) => x.value == selectedVehicleModel.value
  //     );
  //     if (idx === -1) {
  //       partList.push(selectedVehicleModel);
  //       list[index]["parts_associate"] = partList;
  //       setPartsData(list);
  //     } else {
  //       toast.error(selectedVehicleModel.label + ` is already added.`);
  //     }
  //   } else {
  //     console.warn("No vehicle model selected.");
  //   }
  // };

  // const handleRemovePart = (e, index, partIndex) => {
  //   e.preventDefault();

  //   let list = [...partsData];
  //   let partList = list[index]["parts_associate"];

  //   if (partIndex >= 0 && partIndex < partList.length) {
  //     partList.splice(partIndex, 1);
  //     list[index]["parts_associate"] = partList;
  //     setPartsData(list);
  //   } else {
  //     toast.error("Invalid part index.");
  //   }
  // };

  const handleRemovePart = (e, index, partIndex) => {
    e.preventDefault();
    let list = [...partsData];
    let partList = list[index]["parts_associate"];

    partList = partList.filter((_, idx) => idx !== partIndex);

    list[index]["parts_associate"] = partList;
    setPartsData(list);
    setSelectedVehicleModel((prev) => ({
      ...prev,
      [index]: null,
    }));
  };

  const handleSelectWithoutChange = (option) => {
    setSelectedVehicleModel(option);
  };

  const handleBackButtonClick = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    } else {
      navigate("/parts-inventory");
    }
  };

  const toggleEnlarge = (index) => {
    if (enlargedIndexes.includes(index)) {
      setEnlargedIndexes(enlargedIndexes.filter((i) => i !== index));
    } else {
      setEnlargedIndexes([...enlargedIndexes, index]);
    }
  };

  const handleSelectChange = (index, selectedOption) => {
    setSelectedVehicleModel((prev) => ({
      ...prev,
      [index]: selectedOption,
    }));
  };

  const handleAddPart = (e, index) => {
    e.preventDefault();
    const selectedVehicleModels = selectedVehicleModel[index];

    if (selectedVehicleModels) {
      let list = [...partsData];
      let partList = list[index]["parts_associate"];

      let idx = partList.findIndex(
        (x) => x.value == selectedVehicleModels.value
      );
      if (idx === -1) {
        partList.push(selectedVehicleModels);
        list[index]["parts_associate"] = partList;
        setPartsData(list);
      } else {
        toast.error(selectedVehicleModels.label + ` is already added.`);
      }
    } else {
      console.warn("No vehicle model selected for index " + index);
    }
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <button
                  type="button"
                  className="back-btn"
                  onClick={() => navigate("/parts-inventory")}
                  className="beck-btn"
                  onClick={handleBackButtonClick}
                >
                  <i className="bi bi-chevron-left"></i>
                </button> */}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    {/* <a href="#">Home</a> */}
                  </li>
                  {/* <li className="breadcrumb-item active">Fuel</li> */}
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary card-primary-Vehicle">
                {vehicleAddDetails?.id ? (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <Form method={`post`} onSubmit={handleSubmit}>
                          <div className="row">
                            {partsData?.map((part, pIndex) => {
                              return (
                                <React.Fragment key={pIndex}>
                                  <div className="col-lg-6" key={part.id}>
                                    <Card className="text-center">
                                      <Card.Header className="cardHeader">
                                        <div className="d-flex">
                                          <div className="">
                                            <input
                                              className="my-checkbox cstmCheckBox mt-4"
                                              type="checkbox"
                                              name="inventory_status"
                                              checked={
                                                part.inventory_status === 1
                                              }
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="AddPh6">
                                            <h6>
                                              {part.vehicle_make}/
                                              {part.vehicle_model}/
                                              {part.vehicle_year}
                                            </h6>
                                          </div>
                                          {/* <div className="">
                                            <button
                                              type=""
                                              className="btnClose"
                                              onClick={() =>
                                                handleDeleteClick(part)
                                              }
                                            >
                                              <i className="bi bi-x-lg"></i>
                                            </button>
                                          </div> */}
                                        </div>
                                      </Card.Header>
                                      <Card.Body className="cardBody">
                                        <div className="row">
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Code
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              name="part_code"
                                              value={part.part_code}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Code"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Name
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              name="part_name"
                                              value={part.part_name}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Name"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Quantity
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="quantity"
                                              value={part.quantity || "0"}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Quantity"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Price
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="part_price"
                                              value={part.part_price || "0.0"}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Price"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Original Price
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="part_original_price"
                                              value={part.part_original_price}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Sponsor Price
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="sponsor_price"
                                              value={part.sponsor_price}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                          </div>

                                          <div className="col-lg-12 addPartField">
                                            <label className="partsLabel">
                                              Part Description
                                            </label>
                                            <textarea
                                              className="form-control mt-2"
                                              type="text"
                                              name="part_description"
                                              value={part.part_description}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Description"
                                              rows={3}
                                            />
                                          </div>

                                          <div className="col-lg-12 d-flex">
                                            <div className="input-div SelectV selectA">
                                              <Select
                                                className="my-Select"
                                                options={VehicleModelAllList}
                                                value={VehicleModelAllList.find(
                                                  (item) =>
                                                    item.value ==
                                                    selectedVehicleModel
                                                )}
                                                onChange={
                                                  handleSelectWithoutChange
                                                }
                                                placeholder={
                                                  "Select Vehicle Model"
                                                }
                                                isSearchable={true}
                                                // onInputChange={handleInputSelectedChange}
                                                inputSelctedValue={
                                                  inputSelctedValue
                                                }
                                              />
                                            </div>

                                            <button
                                              style={{ marginTop: "8px" }}
                                              type="button"
                                              className="addBtn ms-2"
                                              onClick={(e) =>
                                                handleAddPart(e, pIndex)
                                              }
                                            >
                                              <i className="bi bi-plus-lg"></i>
                                            </button>
                                          </div>
                                          <div className="col-lg-6">
                                            <Table
                                              className="rounded-table striped bordered hover mt-2 table mb-2"
                                              striped
                                              bordered
                                              hover
                                            >
                                              <tbody>
                                                {vehicleAddDetails
                                                  ?.parts_associate.length >
                                                  0 &&
                                                  vehicleAddDetails?.parts_associate?.map(
                                                    (row, index) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <tr>
                                                          <td className="lisatingTable mb-1">
                                                            {row.vehicle_model}
                                                          </td>
                                                        </tr>
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </tbody>
                                            </Table>
                                          </div>
                                          <div className="col-lg-6"></div>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="row">
                            <div className="col-md-12 text-end">
                              <button type="submit" className="btn btn-primary">
                                Save
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>

                    {currentStep === 2 && (
                      <>
                        <div className="card-body vehicleparts-table mt-3">
                          <div className="vehicleparts-table-inner">
                            <div className="row">
                              <>
                                <Form onSubmit={handleSubmits}>
                                  <React.Fragment>
                                    <div className="col-lg-6">
                                      <Card className="text-center">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Part Information</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="cardBody">
                                          <div class="container text-center">
                                            <div class="row">
                                              <div class="col">Part Name</div>
                                              <div class="col">
                                                {currentPart?.part_name}
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col">
                                                Part Description
                                              </div>
                                              <div class="col">
                                                {currentPart?.part_description}
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col">
                                                Part Quantity
                                              </div>
                                              <div class="col">
                                                {currentPart?.quantity}
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col">Part Price</div>
                                              <div class="col">
                                                {currentPart?.part_price}
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col">
                                                Part Original Price
                                              </div>
                                              <div class="col">
                                                {
                                                  currentPart?.part_original_price
                                                }
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col">
                                                Sponsor Price{" "}
                                              </div>
                                              <div class="col">
                                                {currentPart?.sponsor_price}
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>

                                    <div className="col-lg-6">
                                      <Card className="text-center">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Upload Part Image</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="cardBody">
                                          <div className="row">
                                            <div className="col-lg-12 addPartField">
                                              <div className="col-lg-12 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Upload Images
                                                  {/* {currentPart.part_name} */}
                                                </label>
                                                <div className="input-div">
                                                  <div className="avatar-upload">
                                                    <div className="avatar-edit">
                                                      <div className="input-div">
                                                        <input
                                                          className="form-control"
                                                          type="file"
                                                          id="imageuploadAbout"
                                                          onChange={
                                                            handleAboutFileChange
                                                          }
                                                          name="file"
                                                          accept=".jpg,.jpeg,.png"
                                                          multiple
                                                        />
                                                        {previewUrl && (
                                                          <i
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              top: "46px",
                                                              right: "12px",
                                                              fontSize: "20px",
                                                              color: "#81bb42",
                                                            }}
                                                            className="bi bi-eye"
                                                            onClick={
                                                              handleShowAbout
                                                            }
                                                          ></i>
                                                        )}
                                                      </div>
                                                      <label htmlFor="imageuploadAbout"></label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </React.Fragment>
                                  <div className="d-flex flex-row mt-3 justify-content-end">
                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 btn-primary btn"
                                        onClick={handleOpenSkip}
                                      >
                                        Skip & Next
                                      </Button>
                                    )}

                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 btn-primary"
                                        type="submit"
                                      >
                                        Save & Next
                                      </Button>
                                    )}

                                    {isLastPart && (
                                      <Button
                                        className="me-2 mb-2"
                                        onClick={handleFinish}
                                      >
                                        Finish
                                      </Button>
                                    )}
                                  </div>
                                </Form>
                              </>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {currentStep === 1 && (
                      <>
                        <div className="row">
                          <div className="col-lg-11">
                            <div className="vehicleThBg table-responsive">
                              <Table
                                className="rounded-table"
                                striped
                                bordered
                                hover
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <div style={{ width: "245px" }}>
                                        <div className="input-div SelectV">
                                          <Select
                                            menuPortalTarget={document.body}
                                            className="my-Select"
                                            options={categoryPartList}
                                            value={
                                              categoryPartList.find(
                                                (item) =>
                                                  item.value ===
                                                  categoryPartsData.catalog_id
                                              ) || null
                                            }
                                            onChange={
                                              handleSelectChangeCategoryPartList
                                            }
                                            placeholder={"Select Parts"}
                                            isSearchable={true}
                                            onInputChange={handleInputChange}
                                            inputValue={inputValue}
                                          />
                                        </div>
                                        {errorss.catalog_id && (
                                          <span className="input-error">
                                            {errorss.catalog_id}
                                          </span>
                                        )}
                                      </div>
                                    </th>
                                    <th>
                                      <input
                                        className="form-control mt-2 input-search"
                                        type="text"
                                        maxLength="50"
                                        placeholder="Parts"
                                        value={categoryPartsData.part_name}
                                        onChange={(e) => {
                                          setCategoryPartsData({
                                            ...categoryPartsData,
                                            part_name: e.target.value,
                                          });
                                        }}
                                        name={`part_name`}
                                      />
                                      {errorss.part_name && (
                                        <span className="input-error">
                                          {errorss.part_name}
                                        </span>
                                      )}
                                    </th>
                                    <th>
                                      {/* <input
                                        className="form-control mt-2 input-search"
                                        type="text"
                                        maxLength="50"
                                        placeholder="Vehicle Make"
                                        value={categoryPartsData.vehicle_make}
                                        onChange={(e) => {
                                          setCategoryPartsData({
                                            ...categoryPartsData,
                                            vehicle_make: e.target.value,
                                          });
                                        }}
                                        name={`vehicle_make`}
                                      /> */}

                                      <div className="input-div SelectV">
                                        <Select
                                          menuPortalTarget={document.body}
                                          className="my-Select"
                                          options={makeList}
                                          value={
                                            makeList.find(
                                              (item) =>
                                                item.label ==
                                                categoryPartsData.vehicle_make
                                            ) || null
                                          }
                                          onChange={handleSelectChangeMakeList}
                                          placeholder={"Select Make"}
                                          isSearchable={true}
                                          onInputChange={handleInputChangeMake}
                                          inputValue={inputValueMake}
                                        />
                                      </div>
                                      {errorss.vehicle_make && (
                                        <span className="input-error">
                                          {errorss.vehicle_make}
                                        </span>
                                      )}
                                    </th>
                                    <th>
                                      {/* <input
                                        className="form-control mt-2 mb-1 input-search"
                                        type="number"
                                        maxLength={5}
                                        placeholder="Vehicle Year"
                                        value={categoryPartsData.vehicle_year}
                                        onChange={(e) => {
                                          setCategoryPartsData({
                                            ...categoryPartsData,
                                            vehicle_year: e.target.value,
                                          });
                                        }}
                                        name={`vehicle_year`}
                                      /> */}

                                      <div className="input-div SelectV">
                                        <Select
                                          menuPortalTarget={document.body}
                                          className="my-Select"
                                          options={yearList}
                                          value={
                                            yearList.find(
                                              (item) =>
                                                item.label ==
                                                categoryPartsData.vehicle_year
                                            ) || null
                                          }
                                          onChange={handleSelectChangeYearList}
                                          placeholder={"Select Year"}
                                          isSearchable={true}
                                          onInputChange={handleInputChangeYear}
                                          inputValue={inputValueYear}
                                        />
                                      </div>
                                      {errorss.vehicle_year && (
                                        <span className="input-error">
                                          {errorss.vehicle_year}
                                        </span>
                                      )}
                                    </th>
                                    <th>
                                      <div>
                                        {/* <input
                                          className="form-control mt-2 mb-1 input-search"
                                          type="text"
                                          maxLength="50"
                                          placeholder="Vehicle Modal"
                                          value={
                                            categoryPartsData.vehicle_model
                                          }
                                          onChange={(e) => {
                                            setCategoryPartsData({
                                              ...categoryPartsData,
                                              vehicle_model: e.target.value,
                                            });
                                          }}
                                          name={`vehicle_model`}
                                        /> */}

                                        <div className="input-div SelectV">
                                          <Select
                                            menuPortalTarget={document.body}
                                            className="my-Select"
                                            options={modelList}
                                            value={
                                              modelList.find(
                                                (item) =>
                                                  item.label ==
                                                  categoryPartsData.vehicle_model
                                              ) || null
                                            }
                                            onChange={
                                              handleSelectChangeModalList
                                            }
                                            placeholder={"Select modal"}
                                            isSearchable={true}
                                            onInputChange={
                                              handleInputChangeModal
                                            }
                                            inputValue={inputValueModal}
                                          />
                                        </div>
                                        {errorss.vehicle_model && (
                                          <span className="input-error">
                                            {errorss.vehicle_model}
                                          </span>
                                        )}
                                      </div>
                                      {/* <div className="input-div SelectV">
                                          <Select
                                            menuPortalTarget={document.body}
                                            className="my-Select"
                                            styles={{}}
                                            options={categoryPartList}
                                            value={categoryPartList.find(
                                              (item) =>
                                                item.value ==
                                                categoryPartsData.catalog_id
                                            )}
                                            onChange={
                                              handleSelectChangeCategoryPartList
                                            }
                                            placeholder={"Select Vehicle Model"}
                                            isSearchable={true}
                                            onInputChange={handleInputChange}
                                            inputValue={inputValue}
                                          />
                                        </div> */}
                                    </th>
                                    <th>
                                      <div
                                        className="addBtn"
                                        style={{ cursor: "pointer" }}
                                        onClick={handleAddClick}
                                      >
                                        <i className="bi bi-plus-lg"></i>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedItems?.length > 0 ? (
                                    selectedItems?.map((row, index) => (
                                      <tr key={index}>
                                        <td>{row.namePartPT}</td>
                                        <td>{row.part_name}</td>
                                        <td>{row.vehicle_make}</td>
                                        <td>{row.vehicle_year}</td>
                                        <td>{row.vehicle_model}</td>
                                        <td>
                                          <Button
                                            className="btn-danger-delete"
                                            onClick={() =>
                                              handleDeleteClick(row)
                                            }
                                          >
                                            <i class="bi bi-trash3"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="100%"
                                        className="text-center"
                                      >
                                        No results found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                                {/* <div className="card-footer clearfix card-footer-part">
                                  <div className="pagination pagination-sm m-0 float-right">
                                    <Pagination
                                      activePage={params.page}
                                      itemsCountPerPage={parseInt(params.limit)}
                                      totalItemsCount={parseInt(totalRecords)}
                                      pageRangeDisplayed={5}
                                      itemClass={`page-item`}
                                      linkClass={`page-link`}
                                      onChange={(e) =>
                                        handleParams(e, "pagination")
                                      }
                                    />
                                  </div>
                                </div> */}
                              </Table>
                            </div>
                          </div>
                        </div>
                        <div className="back-next">
                          <div className="row">
                            <div className="col">
                              <button
                                type="button"
                                className="back-btn ms-0"
                                onClick={handleBackButtonClick}
                              >
                                <i class="bi bi-arrow-left"></i> Back
                              </button>
                            </div>
                            <div className="col nxt-btn mr-0">
                              <Button
                                className="next-button"
                                type="button"
                                onClick={handleNextClick}
                                // disabled={
                                //   !vehiclePartsData?.vehicle_make &&
                                //   !tempData?.temp_id
                                // }
                              >
                                Next <i class="bi bi-arrow-right"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="fuelCF">
                          <div className="card-footer clearfix card-footer-part ">
                            <div className="pagination pagination-sm m-0 float-right">
                              <Pagination
                                activePage={params.page}
                                itemsCountPerPage={parseInt(params.limit)}
                                totalItemsCount={parseInt(totalRecords)}
                                pageRangeDisplayed={5}
                                itemClass={`page-item`}
                                linkClass={`page-link`}
                                onChange={(e) => handleParams(e, "pagination")}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {currentStep === 2 && (
                      <>
                        <div className="card-body vehicleparts-table mt-3">
                          <div className="vehicleparts-table-inner">
                            <Form method={`post`} onSubmit={handleSubmit}>
                              <div
                                className="row"
                                style={{ justifyContent: "flex-start" }}
                              >
                                {partsData?.map((part, pIndex) => {
                                  return (
                                    <React.Fragment key={pIndex}>
                                      <div className="col-lg-6" key={pIndex}>
                                        <Card className="text-center">
                                          <Card.Header className="cardHeader">
                                            <div className="d-flex">
                                              <div className="">
                                                <input
                                                  className="my-checkbox cstmCheckBox mt-4"
                                                  type="checkbox"
                                                  name="inventory_status"
                                                  checked={
                                                    part.inventory_status === 1
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.tempId
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="AddPh6">
                                                <h6>
                                                  {part.vehicle_make}/
                                                  {part.vehicle_model}/
                                                  {part.vehicle_year}
                                                </h6>
                                              </div>
                                              <div className="">
                                                <button
                                                  type=""
                                                  className="btnClose"
                                                  onClick={() =>
                                                    handleDeleteClick(part)
                                                  }
                                                >
                                                  <i className="bi bi-x-lg"></i>
                                                </button>
                                              </div>
                                            </div>
                                          </Card.Header>
                                          <Card.Body className="cardBody">
                                            <div className="row">
                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Part Code
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="text"
                                                  name="part_code"
                                                  value={part.part_code}
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.tempId
                                                    )
                                                  }
                                                  placeholder="Part Code"
                                                />
                                                {errors.part_code && (
                                                  <span className="input-error">
                                                    {errors.part_code}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Part Name
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="text"
                                                  name="part_name"
                                                  value={part.part_name}
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.tempId
                                                    )
                                                  }
                                                  placeholder="Part Name"
                                                />
                                                {errors.part_name && (
                                                  <span className="input-error">
                                                    {errors.part_name}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Quantity
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="quantity"
                                                  value={part.quantity}
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.tempId
                                                    )
                                                  }
                                                  placeholder="0"
                                                />

                                                {errors.quantity && (
                                                  <span className="input-error">
                                                    {errors.quantity}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Part Price
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="part_price"
                                                  value={part.part_price}
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.tempId
                                                    )
                                                  }
                                                  placeholder="0"
                                                />

                                                {errors.part_price && (
                                                  <span className="input-error">
                                                    {errors.part_price}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Part Original Price
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="part_original_price"
                                                  value={
                                                    part.part_original_price
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.tempId
                                                    )
                                                  }
                                                  placeholder="0"
                                                />
                                                {errors.part_original_price && (
                                                  <span className="input-error">
                                                    {errors.part_original_price}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Sponsor Price
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="sponsor_price"
                                                  value={part.sponsor_price}
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.tempId
                                                    )
                                                  }
                                                  placeholder="0"
                                                />
                                              </div>

                                              <div className="col-lg-12 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Part Description
                                                </label>
                                                <textarea
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="part_description"
                                                  value={part.part_description}
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.tempId
                                                    )
                                                  }
                                                  placeholder="Part Description"
                                                  rows={3}
                                                />
                                              </div>

                                              {/* <div className="col-lg-12 addPartField">
                                                <div className="col-lg-12 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Upload Images
                                                  </label>
                                                  <div className="input-div">
                                                    <div className="avatar-upload">
                                                      <div className="avatar-edit">
                                                        <div className="input-div">
                                                          <input
                                                            className="form-control"
                                                            type="file"
                                                            id="imageuploadAbout"
                                                            onChange={
                                                              handleAboutFileChange
                                                            }
                                                            name="file"
                                                            accept=".jpg,.jpeg,.png"
                                                            multiple // Allow multiple files
                                                          />
                                                          {previewUrl?.length >
                                                            0 && (
                                                            <i
                                                              style={{
                                                                position:
                                                                  "absolute",
                                                                top: "46px",
                                                                right: "12px",
                                                                fontSize:
                                                                  "20px",
                                                                color:
                                                                  "#81bb42",
                                                              }}
                                                              className="bi bi-eye"
                                                              onClick={
                                                                handleShowAbout
                                                              }
                                                            ></i>
                                                          )}
                                                        </div>

                                                        <label htmlFor="imageuploadAbout"></label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div> */}
                                              <div className="col-lg-12 d-flex">
                                                <div className="input-div SelectV selectA">
                                                  {/* <Select
                                                    className="my-Select selectWidth"
                                                    options={
                                                      VehicleModelAllList
                                                    }
                                                    value={VehicleModelAllList.find(
                                                      (item) =>
                                                        item.value ==
                                                        selectedVehicleModel
                                                    )}
                                                    onChange={
                                                      handleSelectWithoutChange
                                                    }
                                                    placeholder={
                                                      "Select Vehicle Model"
                                                    }
                                                    isSearchable={true}
                                                    onInputChange={
                                                      handleInputSelectedChange
                                                    }
                                                    inputSelctedValue={
                                                      inputSelctedValue
                                                    }
                                                  /> */}
                                                  <Select
                                                    className="my-Select selectWidth"
                                                    options={
                                                      VehicleModelAllList
                                                    }
                                                    value={
                                                      selectedVehicleModel[
                                                        pIndex
                                                      ] || null
                                                    }
                                                    onChange={(
                                                      selectedOption
                                                    ) =>
                                                      handleSelectChange(
                                                        pIndex,
                                                        selectedOption
                                                      )
                                                    }
                                                    placeholder={
                                                      "Select Vehicle Model"
                                                    }
                                                    isSearchable={true}
                                                    onInputChange={
                                                      handleInputSelectedChange
                                                    }
                                                    inputSelctedValue={
                                                      inputSelctedValue
                                                    }
                                                  />
                                                </div>

                                                <button
                                                  style={{
                                                    float: "right",
                                                    marginTop: "10px",
                                                    marginRight: "23px",
                                                  }}
                                                  type="button"
                                                  className="addBtn ms-2"
                                                  onClick={(e) =>
                                                    handleAddPart(e, pIndex)
                                                  }
                                                >
                                                  <i className="bi bi-plus-lg"></i>
                                                </button>
                                              </div>

                                              <div className="col-lg-6">
                                                <Table
                                                  style={{
                                                    borderCollapse: "separate",
                                                    borderSpacing: "0 15px",
                                                  }}
                                                  className="striped
                                                  bordered
                                                  hover mt-3"
                                                >
                                                  <tbody>
                                                    {part?.parts_associate
                                                      .length > 0 &&
                                                      part.parts_associate.map(
                                                        (row, partIndex) => (
                                                          <React.Fragment
                                                            key={partIndex}
                                                          >
                                                            <tr>
                                                              <td
                                                                className="lisatingTable mb-1"
                                                                style={{
                                                                  width: "50%",
                                                                  border:
                                                                    "1px solid #e7e4e4",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {row.label}
                                                              </td>
                                                              <Button
                                                                style={{
                                                                  borderRadius:
                                                                    "0px",
                                                                  margin: "7px",
                                                                  height:
                                                                    "30px",
                                                                  width: "30px",
                                                                  color:
                                                                    "#dd4b4b",
                                                                  backgroundColor:
                                                                    "#ffe9e9",
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                  border:
                                                                    "transparent",
                                                                }}
                                                                className=""
                                                                onClick={(e) =>
                                                                  handleRemovePart(
                                                                    e,
                                                                    pIndex,
                                                                    partIndex
                                                                  )
                                                                }
                                                              >
                                                                <i class="bi bi-trash3"></i>
                                                              </Button>
                                                            </tr>
                                                          </React.Fragment>
                                                        )
                                                      )}
                                                  </tbody>
                                                  <div></div>
                                                </Table>
                                              </div>
                                              <div className="col-lg-6"></div>
                                            </div>
                                          </Card.Body>
                                        </Card>
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                              <div class="row">
                                <div class="col">
                                  <button
                                    type="button"
                                    class="next-button btn btn-secondary"
                                    onClick={handleBackButtonClick}
                                  >
                                    <i class="bi bi-arrow-left"></i> Back
                                  </button>
                                </div>
                                <div class="col text-end">
                                  <button type="submit" class="btn btn-primary">
                                    Save <i class="bi bi-save"></i>
                                  </button>
                                </div>
                              </div>
                            </Form>
                            <div className="card-footer clearfix card-footer-part">
                              <div className="pagination pagination-sm m-0 float-right">
                                <Pagination
                                  activePage={params.page}
                                  itemsCountPerPage={parseInt(params.limit)}
                                  totalItemsCount={parseInt(totalRecords)}
                                  pageRangeDisplayed={5}
                                  itemClass={`page-item`}
                                  linkClass={`page-link`}
                                  onChange={(e) =>
                                    handleParams(e, "pagination")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {currentStep === 3 && (
                      <>
                        <div className="card-body vehicleparts-table mt-3">
                          <div className="vehicleparts-table-inner">
                            <div className="row">
                              <>
                                <Form className="row" onSubmit={handleSubmits}>
                                  <React.Fragment>
                                    <div className="col-lg-6">
                                      <Card className="text-justify">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Part Information</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body
                                          className="cardBody"
                                          style={{ padding: "0" }}
                                        >
                                          <div class="container text-justify">
                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col">Part Name</div>
                                              <div class="col">
                                                {currentPart?.part_name}
                                              </div>
                                            </div>
                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col">
                                                Part Description
                                              </div>
                                              <div class="col">
                                                {currentPart?.part_description}
                                              </div>
                                            </div>

                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col">
                                                Part Quantity
                                              </div>
                                              <div class="col">
                                                {currentPart?.quantity}
                                              </div>
                                            </div>
                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col">Part Price</div>
                                              <div class="col">
                                                {currentPart?.part_price}
                                              </div>
                                            </div>

                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col">
                                                Part Original Price
                                              </div>
                                              <div class="col">
                                                {
                                                  currentPart?.part_original_price
                                                }
                                              </div>
                                            </div>

                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col">
                                                Sponsor Price{" "}
                                              </div>
                                              <div class="col">
                                                {currentPart?.sponsor_price}
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>

                                    <div className="col-lg-6">
                                      <Card className="text-center">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Upload Part Image</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="cardBody">
                                          <div className="row">
                                            <div className="col-lg-12 addPartField">
                                              <div className="col-lg-12 addPartField" style={{height: "221px",overflowY: "auto", overflowX:"hidden"}}>
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Upload Images
                                                  {/* {currentPart.part_name} */}
                                                </label>
                                                <div className="input-div">
                                                  <div className="avatar-upload">
                                                    <div className="avatar-edit">
                                                      <div className="input-div">
                                                        <input
                                                        ref={fileInputRef}
                                                          className="inputFile"
                                                          type="file"
                                                          id="imageuploadAbout"
                                                          onChange={
                                                            handleAboutFileChange
                                                          }
                                                          name="file"
                                                          accept=".jpg,.jpeg,.png"
                                                          multiple
                                                        />
                                                        <div className="partImages mt-2">
                                                          {images?.map(
                                                            (
                                                              { file, id },
                                                              index
                                                            ) => (
                                                              <div
                                                                key={id}
                                                                className="preview-item"
                                                              >
                                                                <div
                                                                  className="btn-Remove"
                                                                  style={{
                                                                    position:
                                                                      "absolute",
                                                                    width:
                                                                      "20px",
                                                                    height:
                                                                      "20px",
                                                                  }}
                                                                >
                                                                  <i
                                                                    className="bi bi-x"
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleRemoveFile(
                                                                        index
                                                                      )
                                                                    }
                                                                  ></i>
                                                                </div>
                                                                <img
                                                                  onClick={() =>
                                                                    toggleEnlarge(
                                                                      index
                                                                    )
                                                                  }
                                                                  className={`image ${
                                                                    enlargedIndexes.includes(
                                                                      index
                                                                    )
                                                                      ? "enlarged"
                                                                      : ""
                                                                  }`}
                                                                  src={
                                                                    previewUrl[
                                                                      index
                                                                    ]
                                                                  }
                                                                  alt={`Preview ${index}`}
                                                                  style={{
                                                                    width:
                                                                      "100px",
                                                                    margin:
                                                                      "5px",
                                                                    height:
                                                                      "100px",
                                                                    border:
                                                                      "1px solid",
                                                                    borderRadius:
                                                                      "5px",
                                                                    maxWidth:
                                                                      "100px",
                                                                    maxHeight:
                                                                      "100px",
                                                                  }}
                                                                />
                                                              </div>
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                      <label htmlFor="imageuploadAbout"></label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </React.Fragment>
                                  <div className="d-flex flex-row mt-3 justify-content-end">
                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 next-button"
                                        onClick={handleOpenSkip}
                                      >
                                        Skip & Next
                                      </Button>
                                    )}

                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 btn-primary"
                                        type="submit"
                                      >
                                        Save & Next
                                      </Button>
                                    )}

                                    {isLastPart && (
                                      <>
                                        <Button
                                          className="mb-2 next-button"
                                          onClick={handleFinish}
                                          style={{ marginRight: "10px" }}
                                        >
                                          Save
                                        </Button>

                                        <Button
                                          className="mb-2 next-button"
                                          onClick={() =>
                                            navigate("/parts-inventory")
                                          }
                                        >
                                          Finish
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </Form>
                              </>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        <Modal show={showModalAbout} onHide={handleCloseAbout}>
          <Modal.Header closeButton>
            <Modal.Title>Parts Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="preview-container">
              {images.map(({ file, id }, index) => (
                <div key={id} className="preview-item">
                  <img
                    src={previewUrl[index]}
                    alt={`Preview ${index}`}
                    style={{ width: "100px", margin: "5px" }}
                  />
                  <div className="btn-Remove">
                    <i
                      className="bi bi-x"
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleRemoveFile(index)}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAbout}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showSkip} onHide={handleCloseSkip}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            Are you sure you want to skip this part? You won't be able to upload
            images for this part.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseSkip}>
              Close
            </Button>
            <Button variant="secondary" onClick={handleSkip}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AddForParts;
