import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Plan from "./PlanSection";
import Faq from "./FaqSection";
import Subscribe from "./Subscribe";
import ContactForm from "./ContactUs";
import { getSettingsDetails } from "../../services/settingsServices";

const Home = () => {
  const [settingDetails, setSettingDetails] = useState("");
  useEffect(() => {
    getSettingsDetails()
      .then((response) => {
        setSettingDetails(response.data.data);
      })
      .catch((err) => {
        // toast.error(Utils.getErrorMessage(err));
      });
  }, []);
  return (
    <div>
      <Banner settingDetails={settingDetails} />
      <AboutUs settingDetails={settingDetails} />
      <Services settingDetails={settingDetails} />
      <Plan />
      <Faq />
      <ContactForm settingDetails={settingDetails} />
      <Subscribe settingDetails={settingDetails} />
    </div>
  );
};

export default Home;
