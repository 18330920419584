import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import {
  createVehicleModel,
  deleteVehicleModel,
  getVehicleModelList,
  updateVehicleModel,
} from "../../services/VehicleModelService";
import Select from "react-select";
import { getVehicleTypeAllList } from "../../services/VehicleType";

const VehicleModel = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);

  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [vehicleModelList, setVehicleModelList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delVehicleModel, setDelVehicleModel] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [vehicleModelData, setVehicleModelData] = useState({
    uuid: "",
    vehicle_type: "",
    vehicle_start_year: "",
    vehicle_end_year: "",
    vehicle_make: "",
    vehicle_model: "",
  });

  let errorsObj = {
    vehicle_type: "",
    vehicle_start_year: "",
    vehicle_end_year: "",
    vehicle_make: "",
    vehicle_model: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [vehicleTypeList, setVehicleTypeList] = useState([]);

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setVehicleModelData("");
    setErrors("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  useEffect(function () {
    let data = { ...params };
    getVehicleTypeAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].type_name,
          });
        }
        setVehicleTypeList(list);
      })
      .catch((err) => {});
  }, []);

  const fetchVehicleModel = () => {
    let data = { ...params };
    getVehicleModelList(data)
      .then((response) => {
        setVehicleModelList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchVehicleModel(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditVehicleModel = (e, item) => {
    setModifyModal(true);
    setVehicleModelData({
      uuid: item?.uuid,
      vehicle_type: item?.vehicle_type,
      vehicle_start_year: item?.vehicle_start_year,
      vehicle_end_year: item?.vehicle_end_year,
      vehicle_make: item?.vehicle_make,
      vehicle_model: item?.vehicle_model,
    });
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelVehicleModel(item.uuid);
    setDelModal(true);
  };

  const onDestroyVehicleForm = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delVehicleModel };
    deleteVehicleModel(data)
      .then((response) => {
        let data = { ...params };

        fetchVehicleModel(data);
        toast.success(response.data.message);
        setDelVehicleModel("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleVehicleModelForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!vehicleModelData.vehicle_type) {
      errorObj.vehicle_type = `Vehicle type is required.`;
      error = true;
    }

    if (!vehicleModelData.vehicle_make) {
      errorObj.vehicle_make = `Vehicle make is required.`;
      error = true;
    }
    if (!vehicleModelData.vehicle_model) {
      errorObj.vehicle_model = `Vehicle model is required.`;
      error = true;
    }

    if (
      !vehicleModelData.vehicle_start_year ||
      isNaN(vehicleModelData.vehicle_start_year)
    ) {
      errorObj.vehicle_start_year =
        "Vehicle start year is required and must be a number.";
      error = true;
    }

    if (
      !vehicleModelData.vehicle_end_year ||
      isNaN(vehicleModelData.vehicle_end_year)
    ) {
      errorObj.vehicle_end_year =
        "Vehicle end year is required and must be a number.";
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);

    let data = {
      vehicle_type: vehicleModelData.vehicle_type,
      vehicle_start_year: vehicleModelData.vehicle_start_year,
      vehicle_end_year: vehicleModelData.vehicle_end_year,
      vehicle_make: vehicleModelData.vehicle_make,
      vehicle_model: vehicleModelData.vehicle_model,
    };

    let Updatedata = {
      uuid: vehicleModelData?.uuid,
      vehicle_type: vehicleModelData.vehicle_type,
      vehicle_start_year: vehicleModelData.vehicle_start_year,
      vehicle_end_year: vehicleModelData.vehicle_end_year,
      vehicle_make: vehicleModelData.vehicle_make,
      vehicle_model: vehicleModelData.vehicle_model,
    };

    if (vehicleModelData?.uuid) {
      updateVehicleModel(Updatedata)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchVehicleModel(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createVehicleModel(data)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchVehicleModel(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      {isLoading ? (
        // <div className="text-center pageLoader">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
          <div className="card">
            <div className="card-header">
              <table className="table rounded-table table-striped table-hover">
                <thead>
                  <tr>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}>
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                    <th
                      class="d-flex"
                      style={{ borderTop: "0", borderBottom: "0" }}
                    >
                      <div class="skeleton me-1"></div>
                      <div class="skeleton me-1"></div>
                      <div class="skeleton"></div>
                    </th>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-body">
              <div className="vehicleThBg table-responsive">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleModelList?.map((_, index) => (
                      <tr key={index}>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="skeleton col-lg-3 float-right"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="page-title">Vehicle Model</h1> */}
                </div>

                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary-Vehicle fuelCard">
                    <div className="card-header">
                      <div className="card-title m-0 float-left">
                        <input
                          type="text"
                          className="form-control input-search"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>
                      </div>
                      <div className="card-title m-0 float-right">
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_vehicle_model"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn btn-icon-text mr-2"
                            onClick={handleAddModal}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">Add</span>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="card-body part-card mt-3">
                      <div className="vehicleThBg table-responsive cstm-height">
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: 10 }}
                              >
                                #
                              </th>
                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                Vehicle Type
                              </th>
                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                Vehicle Start Year
                              </th>
                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                Vehicle End Year
                              </th>
                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                Vehicle Make
                              </th>
                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                Vehicle Model
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: 10 }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {vehicleModelList?.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{item.type_name}</td>
                                    <td>{item.vehicle_start_year}</td>
                                    <td>{item.vehicle_end_year}</td>
                                    <td>{item.vehicle_make}</td>
                                    <td>{item.vehicle_model}</td>

                                    <td className="d-flex">
                                      {(item.company_id > 0 ||
                                        companyId.role_id == 1) &&
                                        RolePermission?.findIndex(
                                          (e) =>
                                            e.permission_name ===
                                            "modify_vehicle_model"
                                        ) > -1 && (
                                          <a
                                            className="edit-btn"
                                            href="#"
                                            onClick={(e) =>
                                              onEditVehicleModel(e, item)
                                            }
                                          >
                                            <i className="bi bi-pencil"></i>
                                          </a>
                                        )}

                                      {(item.company_id > 0 ||
                                        companyId.role_id == 1) &&
                                        RolePermission?.findIndex(
                                          (e) =>
                                            e.permission_name ===
                                            "delete_vehicle_model"
                                        ) > -1 && (
                                          <div className="trash-btn">
                                            <a
                                              className="delete-btn"
                                              href="#"
                                              onClick={(e) =>
                                                onDeleteModalOpen(e, item)
                                              }
                                            >
                                              <i className="bi bi-trash3"></i>
                                            </a>
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          {vehicleModelList &&
                            vehicleModelList?.length === 0 && (
                              <tr>
                                <td
                                  colSpan={7}
                                  className={`text-center`}
                                >{`Record Not Found`}</td>
                              </tr>
                            )}
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="fuelCF">
                      <div className="card-footer card-footer-part clearfix">
                        <div className="pagination pagination-sm m-0 float-right">
                          <Pagination
                            activePage={params.page}
                            itemsCountPerPage={parseInt(params.limit)}
                            totalItemsCount={parseInt(totalRecords)}
                            pageRangeDisplayed={5}
                            itemClass={`page-item`}
                            linkClass={`page-link`}
                            onChange={(e) => handleParams(e, "pagination")}
                          />
                        </div>
                      </div>
                    </div>
          </section>
        </div>
      )}
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle Model`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle model?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyVehicleForm}
            >
              Delete
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Vehicle Model</ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleModelForm}>
          <ModalBody>
            <div className="row justify-content-start align-items-center">
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle Type"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <Select
                        className="my-select-v"
                        options={vehicleTypeList}
                        value={vehicleTypeList.find(
                          (option) =>
                            option.value ===
                            Number(vehicleModelData.vehicle_type)
                        )}
                        onChange={(selectedOption) =>
                          setVehicleModelData({
                            ...vehicleModelData,
                            vehicle_type: selectedOption.value,
                          })
                        }
                        placeholder={"Select Vehicle Type"}
                      />
                    </div>
                    {errors.vehicle_type && (
                      <span className="input-error">{errors.vehicle_type}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle Start Year"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehicleModelData.vehicle_start_year}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            vehicle_start_year: e.target.value,
                          });
                        }}
                        name={`vehicle_start_year`}
                        maxLength={4}
                        placeholder="Vehicle Start Year"
                      />
                    </div>
                    {errors.vehicle_start_year && (
                      <span className="input-error">
                        {errors.vehicle_start_year}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle End Year"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehicleModelData.vehicle_end_year}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            vehicle_end_year: e.target.value,
                          });
                        }}
                        name={`vehicle_end_year`}
                        maxLength={4}
                        placeholder="Vehicle End Year"
                      />
                    </div>
                    {errors.vehicle_end_year && (
                      <span className="input-error">
                        {errors.vehicle_end_year}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle Make"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleModelData.vehicle_make}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            vehicle_make: e.target.value,
                          });
                        }}
                        name={`vehicle_make`}
                        maxLength={30}
                        placeholder="Vehicle Make"
                      />
                    </div>
                    {errors.vehicle_make && (
                      <span className="input-error">{errors.vehicle_make}</span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle Model"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleModelData.vehicle_model}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            vehicle_model: e.target.value,
                          });
                        }}
                        name={`vehicle_model`}
                        maxLength={30}
                        placeholder="Vehicle Model"
                      />
                    </div>
                    {errors.vehicle_model && (
                      <span className="input-error">
                        {errors.vehicle_model}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default VehicleModel;
