import axiosInstance from "./AxiosInstance";

export function createVehicleOwner(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-owner`, data);
}
export function createVehicle(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle`, data);
}

export function createVehicleImage(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-images`, data);
}

export function createVehicleDocument(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-documents`, data);
}

export function createVehicleExtraInfo(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-extra-info`, data);
}

export function createVehicleAmortization(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-amortization`, data);
}

export function deleteVehicleImage(data) {
  return axiosInstance.post(`vehicle-process/delete-vehicle-images`, data);
}

export function getVehicleCategoryAllList(data) {
  return axiosInstance.get(`/get-vehicle-category`, data);
}
export function getVehicleModelAllList(data) {
  return axiosInstance.post(`/get-vehicle-model`, data);
}
export function getVehicleFuelAllList(data) {
  return axiosInstance.get(`/get-vehicle-fuel-type`, data);
}

export function getVehicleAmortizationList(data) {
  return axiosInstance.post(
    `/vehicle-process/get-vehicle-amortization-list`,
    data
  );
}

export function getVehicleProcessDetails(data) {
  return axiosInstance.post(`/vehicle-process/get-vehicle-details`, data);
}

export function getVehicleAllProcessList(data) {
  return axiosInstance.post(`/vehicle-process/get-vehicle-list`, data);
}

export function getCountryAllist(data) {
  return axiosInstance.post(`/country/list`, data);
}

export function getVehicleVinAllist(data) {
  return axiosInstance.post(`/get-vehicle/vin-number`, data);
}

export function getVehiclePlateAllist(data) {
  return axiosInstance.post(`/get-vehicle/plate-number`, data);
}
