import axios from 'axios';
import configDb from "../config";
import { store } from "../store";

const API_URL = configDb.data.api_url;

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        accept: '*/*',
    },
});

axiosInstance.interceptors.request.use((config) => {
    let auth = store.getState().auth.value;
    let lngData = localStorage.getItem("lng")
    if (typeof auth !== "object") {
        auth = JSON.parse(auth);
    }

    if (auth?.access_token) {
        config.headers.auth = auth.access_token;
        config.headers.lg= lngData
        // if (auth?.main_branch && auth?.main_branch > 0) {
        //     config.headers.branch = auth.main_branch;
        // }
    }


    if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
    } 

    return config;
});


export default axiosInstance;
