import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";

import {
  getFuelList,
  actionAddFuel,
  actionUpdateFuel,
  actionDelFuel,
} from "../../services/FuelServices";
import { toast } from "react-toastify";
import Utils from "../../utils";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const FuelType = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);

  // const groupedByMenu =
  //   RolePermission?.findIndex((e) => e.display_name === "View") > -1;

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fuelTypeList, setFuelTypeList] = useState([]);
  const [fuelType, setFuelType] = useState("");
  const [uuid, setFuelId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = { fuletype: "" };
  const [errors, setErrors] = useState(errorsObj);

  const handleAddModal = () => {
    setModifyModal(true);
    ClereForm();
  };

  const handleDeleteModal = (e, uuid) => {
    setFuelId(uuid.uuid);

    setDeleteModal(true);
  };
  const handleCloseModal = () => {
    setModifyModal(false);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const onEditFuelType = (e, item) => {
    setModifyModal(true);
    setFuelType(item.fuel_type);
    setFuelId(item?.uuid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsObj };
    let error = false;
    if (!fuelType) {
      errorObj.fuletype = "Fule Type is required";
      error = true;
    }
    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    let obj = {
      name: fuelType,
    };

    let Updateobj = {
      name: fuelType,
      uuid: uuid,
    };
    setIsLoading(true);

    if (uuid) {
      actionUpdateFuel(Updateobj)
        .then((response) => {
          toast.success(response.data.message);
          ClereForm();
          setModifyModal(false);
          let data = { ...params };
          LoadList(data);
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      actionAddFuel(obj)
        .then((response) => {
          toast.success(response.data.message);
          ClereForm();
          setModifyModal(false);
          let data = { ...params };
          LoadList(data);
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleDeleteSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    let obj = {
      uuid: uuid,
    };
    actionDelFuel(obj)
      .then((response) => {
        toast.success(response.data.message);
        setDeleteModal(false);
        let data = { ...params };

        LoadList(data);
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  useEffect(
    function () {
      let data = { ...params };

      LoadList(data);
    },
    [params]
  );
  const LoadList = (data) => {
    // setIsLoading(true); 
    getFuelList(data)
      .then((response) => {
        setFuelTypeList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      })
    // .finally(() => {
    //   setIsLoading(false); 
    // });
  };
  function ClereForm() {
    setFuelType("");
    setFuelId("");
  }

  return (
    <div>
      {isLoading ? (
        <div className="text-center pageLoader">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        //   <div className="skeleton-loader p-3">
        //   <div className="card">
        //     <div className="card-header">
        //         <table className="table rounded-table table-striped table-hover">
        //           <thead>
        //             <tr>
        //               <th style={{ width: 10, borderTop: "0", borderBottom:"0" }}><div className="skeleton" ></div></th>
        //               <th style={{borderTop: "0", borderBottom:"0"}}><div className="skeleton"></div></th>
        //               <th style={{borderTop: "0", borderBottom:"0"}}></th>
        //               <th class="d-flex" style={{borderTop: "0", borderBottom:"0"}}><div class="skeleton me-1"></div>
        //                 <div class="skeleton me-1"></div>
        //                 <div class="skeleton"></div>
        //               </th>
        //               <th style={{ width: 10,borderTop: "0", borderBottom:"0" }}><div className="skeleton"></div></th>
        //             </tr>
        //           </thead>
        //         </table>
        //       </div>
        //     <div className="card-body">
        //       <div className="vehicleThBg table-responsive">
        //       <table className="table table-bordered rounded-table table table-striped table-hover">
        //         <thead>
        //           <tr>
        //             <th style={{backgroundColor: "#c0c0c0"}}><div className="skeleton"></div></th>
        //             <th style={{backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
        //             <th style={{backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
        //           </tr>
        //         </thead>
        //         <tbody>
        //           {fuelTypeList?.map((_, index) => (
        //             <tr key={index}>
        //               <td><div className="skeleton"></div></td>
        //               <td><div className="skeleton"></div></td>
        //               <td><div className="skeleton"></div></td>

        //             </tr>
        //           ))}
        //         </tbody>
        //       </table>
        //       </div>
        //     </div>
        //     <div className="card-footer">
        //       <div className="skeleton col-lg-3 float-right"></div>
        //     </div>
        //   </div>
        // </div>

        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">{/* <h1>Fuel</h1> */}</div>

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"></li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="card-header">
                      <div className="card-title m-0 float-left">
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>
                      </div>
                      {/* {RolePermission[0]?.add === 1 && ( */}

                      {RolePermission?.findIndex(
                        (e) => e.permission_name === "add_fuel"
                      ) > -1 && (
                          <div className="card-title m-0 float-right ">
                            <button
                              type="button"
                              className="btn btn-block btn-icon-text"
                              onClick={handleAddModal}
                            >
                              <i className="bi bi-plus-lg"></i>
                              <span className="ms-2">Add</span>
                            </button>
                          </div>
                        )}
                      {/* )} */}
                    </div>
                    <div className="card-body fuelCardB">
                      {isLoading ? (
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="vehicleThBg cstm-height">
                          <table className="table table-bordered rounded-table table table-striped table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: 10 }}>#</th>
                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  Fule Type
                                </th>
                                <th style={{ width: 10 }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fuelTypeList?.map((item, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{++index}</td>
                                      <td>{item.fuel_type}</td>

                                      <td className="d-flex">
                                        {(item.company_id > 0 ||
                                          companyId.role_id == 1) &&
                                          RolePermission?.findIndex(
                                            (e) =>
                                              e.permission_name ===
                                              "modify_fuel"
                                          ) > -1 && (
                                            <a
                                              className="edit-btn"
                                              href="#"
                                              onClick={(e) =>
                                                onEditFuelType(e, item)
                                              }
                                            >
                                              <i className="bi bi-pencil"></i>
                                            </a>
                                          )}

                                        {(item.company_id > 0 ||
                                          companyId.role_id == 1) &&
                                          RolePermission?.findIndex(
                                            (e) =>
                                              e.permission_name ===
                                              "delete_fuel"
                                          ) > -1 && (
                                            <div className="trash-btn">
                                              <a
                                                className="delete-btn"
                                                href="#"
                                                onClick={(e) =>
                                                  handleDeleteModal(e, item)
                                                }
                                              >
                                                <i className="bi bi-trash3"></i>
                                              </a>
                                            </div>
                                          )}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                            {fuelTypeList?.length === 0 && (
                              <tr>
                                <td
                                  colSpan={7}
                                  className={`text-center`}
                                >{`Record Not Found`}</td>
                              </tr>
                            )}
                          </table>
                        </div>
                      )}
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>

            </div>
            <div className="card-footer card-footer-part clearfix fuelCF">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
            <Modal
              show={modifyModal}
              onHide={handleCloseModal}
              backdrop="static"
              keyboard={false}
            >
              <Form onSubmit={handleSubmit}>
                <ModalHeader closeButton>
                  <Modal.Title>Create Fuel</Modal.Title>
                </ModalHeader>
                <ModalBody>
                  <Form.Group controlId="fuelType">
                    <Form.Label className="col-form-label ml-2 Label-my form-label">
                      Fuel Type
                    </Form.Label>
                    <Form.Control
                      className="form-control input-search"
                      type="text"
                      placeholder="Enter fuel type"
                      value={fuelType}
                      onChange={(e) => setFuelType(e.target.value)}
                    />
                    {errors.fuletype && (
                      <span className="input-error">{errors.fuletype}</span>
                    )}
                  </Form.Group>
                </ModalBody>
                <ModalFooter>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Close <i class="bi bi-x-lg"></i>
                  </Button>
                  <Button variant="primary" type="submit">
                    Save Changes <i class="bi bi-check2-square"></i>
                  </Button>
                </ModalFooter>
              </Form>
            </Modal>

            <Modal
              show={deleteModal}
              onHide={handleCloseDeleteModal}
              backdrop="static"
              keyboard={false}
            >
              <Form onSubmit={handleDeleteSubmit}>
                <ModalHeader closeButton>
                  <Modal.Title>Delete Fuel</Modal.Title>
                </ModalHeader>
                <ModalBody>
                  <Form.Group controlId="id">
                    <Form.Label>
                      Are you sure you want to delete this fuel?
                    </Form.Label>
                  </Form.Group>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={handleCloseDeleteModal}
                    style={{ width: "100px", marginTop: "10px" }}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-block bg-red"
                    style={{ width: "100px" }}
                  >
                    Delete
                  </button>
                </ModalFooter>
              </Form>
            </Modal>
          </section>
        </div>
      )}
    </div>
  );
};

export default FuelType;
