import axiosInstance from "./AxiosInstance";

export function actionFaqAddUpdate(data) {
  return axiosInstance.post(`/faq/add-update`, data);
}

export function getFaqDetails(data) {
  return axiosInstance.post(`faq/details`, data);
}

export function destroyfaqList(data) {
  return axiosInstance.post(`/faq/delete`, data);
}

export function getfaqList(data) {
  return axiosInstance.post(`/faq/list`, data);
}

export function getViewfaqList(data) {
  return axiosInstance.post(`/faq/view-list`, data);
}
