import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  createVehicle,
  getCountryAllist,
  getVehicleCategoryAllList,
  getVehicleFuelAllList,
  getVehicleModelAllList,
  getVehiclePlateAllist,
  getVehicleVinAllist,
} from "../../services/VehicleService";
import { getVehicleTypeAllList } from "../../services/VehicleType";
import moment from "moment";
import ConfigDB from "../../config";
const ProcessType = [
  { value: 1, label: "Depollution" },
  { value: 2, label: "Dismentle" },
  { value: 3, label: "Both" },
];

const VehicleSerachType = [
  { value: 1, label: "VIN Number" },
  { value: 2, label: "Plate Number" },
];

const Vehicles = ({
  setActiveTab,
  vehicleProcessList,
  setVehicleVinData,
  vehicleVinData,
  vehicleVinTecData,
  vehiclePlateData,
  countryAllData,
  AllvehicleSerachData,
  setAllVehicleSerachData,
  setVehiclePlateData,
  setVehicleVinTecData,
}) => {
  console.log("vehicleProcessList",vehicleProcessList)
  const [vehicleData, setVehicleData] = useState({
    uuid: "",
    vehicle_owner_id: "",
    vehicle_process_type: "",
    vehicle_process_start_date: "",
    vehicle_process_end_date: "",
    registration: "",
    registration_plate: "",
    vehicle_frame: "",
    vehicle_model_id: "",
    vehicle_brand: "",
    vehicle_model: "",
    vehicle_booklet_model: "",
    vehicle_motorization: "",
    vehicle_category: "",
    vehicle_year: "",
    vehicle_kms: "",
    vehicle_engine: "",
    vehicle_approval: "",
    vehicle_total_gross_weight: "",
    vehicle_fuel: "",
    vehicle_parts: "",
    vehicle_color: "",
    vehicle_cyllinder: "",
    vehicle_type: "",
    vehicle_tare: "",
    vehicle_eei: "",
    vehicle_internl_ref: "",
    vehicle_external_ref: "",
    vehicle_exp_value: "",
    vehicle_provenance: "",
    vehicle_park: "",
    body_sent_to_waste: "0",
    status: "",
  });
  console.log("vehicleData",vehicleData)

  const tableLengthList = ConfigDB.data.dataTableLength;

  const [selectedOption, setSelectedOption] = useState("");
  const [SelectedVehicleData, setSelectedVehicleData] = useState("");

  const [serachModal, setSerachModal] = useState(false);

  const handleOptionChange = (event, data) => {
    setSelectedOption(data?.vehicle_type_description);
    setSelectedVehicleData(data);
    setSerachModal(false);
  };

  let errorsObj = {
    vehicle_process_type: "",
    vehicle_process_start_date: "",
    vehicle_process_end_date: "",
    registration: "",
    registration_plate: "",
    vehicle_frame: "",
    vehicle_model_id: "",
    vehicle_brand: "",
    vehicle_booklet_model: "",
    vehicle_motorization: "",
    vehicle_category: "",
    vehicle_year: "",
    vehicle_kms: "",
    vehicle_engine: "",
    vehicle_approval: "",
    vehicle_total_gross_weight: "",
    vehicle_fuel: "",
    vehicle_parts: "",
    vehicle_color: "",
    vehicle_cyllinder: "",
    vehicle_type: "",
    vehicle_tare: "",
    vehicle_eei: "",
    vehicle_internl_ref: "",
    vehicle_external_ref: "",
    vehicle_exp_value: "",
    vehicle_provenance: "",
    vehicle_park: "",
    body_sent_to_waste: "",
    status: "",
    vehicle_model: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const navigate = useNavigate();
  const [VehicleModelList, setVehicleModelList] = useState([]);
  const [VehicleCategoryList, setVehicleCategoryList] = useState([]);
  const [VehicleFuelList, setVehicleFuelList] = useState([]);
  const [VehicleTypeList, setVehicleTypeList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  let ownerId = localStorage.getItem("ownerList");

  const handleCheckboxChange = (e) => {
    setVehicleData({
      ...vehicleData,
      body_sent_to_waste: e.target.checked ? 1 : 0,
    });
  };

  useEffect(function () {
    var obj = {
      text: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getVehicleModelAllList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].vehicle_model,
          });
        }
        setVehicleModelList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getVehicleCategoryAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].category,
          });
        }
        setVehicleCategoryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getVehicleFuelAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].fuel_type,
          });
        }
        setVehicleFuelList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getVehicleTypeAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].type_name,
          });
        }
        setVehicleTypeList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);

    if (inputValue.length > 2) {
      const obj = {
        text: inputValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleModelAllList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_model,
            });
          }
          setVehicleModelList(list);
        })
        .catch((err) => {
          console.error("Error fetching vehicle model list", err);
        });
    } else {
      setVehicleModelList([]);
      const obj = {
        text: inputValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleModelAllList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_model,
            });
          }
          setVehicleModelList(list);
        })
        .catch((err) => {
          console.error("Error fetching vehicle model list", err);
        });
    }
  };

  useEffect(() => {
    if (vehicleProcessList) {
      setVehicleData((prevData) => ({
        ...prevData,
        vehicle_owner_id: vehicleProcessList.vehicle_owner_id,
        vehicle_process_type: vehicleProcessList.vehicle_process_type,
        vehicle_process_start_date: moment(
          vehicleProcessList.vehicle_process_start_date
        ).format("YYYY-MM-DD"),
        vehicle_process_end_date: moment(
          vehicleProcessList.vehicle_process_end_date
        ).format("YYYY-MM-DD"),
        registration: vehicleProcessList.registration,
        registration_plate: vehicleProcessList.registration_plate,
        vehicle_frame: vehicleProcessList.vehicle_frame,
        vehicle_model_id: vehicleProcessList.vehicle_model_id,
        vehicle_brand: vehicleProcessList.vehicle_brand,
        vehicle_model: vehicleProcessList.vehicle_model,
        vehicle_booklet_model: vehicleProcessList.vehicle_booklet_model,
        vehicle_motorization: vehicleProcessList.vehicle_motorization,
        vehicle_category: vehicleProcessList.vehicle_category,
        vehicle_year: vehicleProcessList.vehicle_year,
        vehicle_kms: vehicleProcessList.vehicle_kms,
        vehicle_engine: vehicleProcessList.vehicle_engine,
        vehicle_approval: vehicleProcessList.vehicle_approval,
        vehicle_total_gross_weight:
          vehicleProcessList.vehicle_total_gross_weight,
        vehicle_fuel: vehicleProcessList.vehicle_fuel,
        vehicle_parts: vehicleProcessList.vehicle_parts,
        vehicle_color: vehicleProcessList.vehicle_color,
        vehicle_cyllinder: vehicleProcessList.vehicle_cyllinder,
        vehicle_type: vehicleProcessList.vehicle_type,
        vehicle_tare: vehicleProcessList.vehicle_tare,
        vehicle_eei: vehicleProcessList.vehicle_eei,
        vehicle_internl_ref: vehicleProcessList.vehicle_internl_ref,
        vehicle_external_ref: vehicleProcessList.vehicle_external_ref,
        vehicle_exp_value: vehicleProcessList.vehicle_exp_value,
        vehicle_provenance: vehicleProcessList.vehicle_provenance,
        vehicle_park: vehicleProcessList.vehicle_park,
        body_sent_to_waste: vehicleProcessList.body_sent_to_waste,
      }));
    }
  }, [vehicleProcessList]);

  useEffect(() => {
    if (SelectedVehicleData) {
      setVehicleData((prevData) => ({
        ...prevData,
        vehicle_year: SelectedVehicleData.vehicle_model_fromYear,
        vehicle_model: SelectedVehicleData.vehicle_type_model,
        vehicle_cyllinder: SelectedVehicleData.vehicle_type_cyl,
      }));
    }
  }, [SelectedVehicleData]);

  const handleVehicleForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };

    if (!vehicleData.vehicle_process_type) {
      errorObj.vehicle_process_type = `Vehicle process type is required.`;
      error = true;
    }

    // const today = new Date().toISOString().split("T")[0];

    // if (!vehicleData.vehicle_process_end_date) {
    //   vehicleData.vehicle_process_end_date = today;
    // }

    // if (!vehicleData.vehicle_process_start_date) {
    //   vehicleData.vehicle_process_start_date = today;
    // }

    // if (!vehicleData.vehicle_process_start_date) {
    //   errorObj.vehicle_process_start_date = `Start date is required.`;
    //   error = true;
    // } else {
    //   const startDate = new Date(vehicleData.vehicle_process_start_date);
    //   const endDate = new Date(vehicleData.vehicle_process_end_date);
    //   const currentDate = new Date(today);

    //   if (startDate < currentDate) {
    //     errorObj.vehicle_process_start_date = `Start date cannot be before today.`;
    //     error = true;
    //   }

    //   if (startDate > endDate) {
    //     errorObj.vehicle_process_end_date = `End date cannot be before start date.`;
    //     error = true;
    //   }
    // }

    if (!vehicleData.vehicle_process_start_date) {
      errorObj.vehicle_process_start_date = `Start Date is required.`;
      error = true;
    }

    if (!vehicleData.vehicle_process_end_date) {
      errorObj.vehicle_process_end_date = `End Date is required.`;
      error = true;
    }

    if (!vehicleData.registration) {
      errorObj.registration = `Registration is required.`;
      error = true;
    }
    if (!vehicleData.registration_plate) {
      errorObj.registration_plate = `Registration plate is required.`;
      error = true;
    }

    if (!vehicleData.vehicle_frame) {
      errorObj.vehicle_frame = `Frame is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_model) {
      errorObj.vehicle_model = `Model is required.`;
      error = true;
    }

    if (!vehicleData.vehicle_model_id) {
      errorObj.vehicle_model_id = `Vehicle model is required.`;
      error = true;
    }

    if (!vehicleData.vehicle_brand) {
      errorObj.vehicle_brand = `Vehicle brand is required.`;
      error = true;
    }

    if (!vehicleData.vehicle_booklet_model) {
      errorObj.vehicle_booklet_model = `Booklet model is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_motorization) {
      errorObj.vehicle_motorization = `Motorization is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_category) {
      errorObj.vehicle_category = `Category is required.`;
      error = true;
    }

    if (!vehicleData.vehicle_year) {
      errorObj.vehicle_year = `Year is required.`;
      error = true;
    } else if (
      isNaN(vehicleData.vehicle_year) ||
      vehicleData.vehicle_year <= 0
    ) {
      errorObj.vehicle_year = `Year must be a valid number`;
      error = true;
    }

    if (!vehicleData.vehicle_kms) {
      errorObj.vehicle_kms = `KMS is required.`;
      error = true;
    }

    if (!vehicleData.vehicle_engine) {
      errorObj.vehicle_engine = `Engine is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_approval) {
      errorObj.vehicle_approval = `Approval is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_total_gross_weight) {
      errorObj.vehicle_total_gross_weight = `Total gross weight is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_fuel) {
      errorObj.vehicle_fuel = `Fuel is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_parts) {
      errorObj.vehicle_parts = `Parts is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_color) {
      errorObj.vehicle_color = `Color is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_cyllinder) {
      errorObj.vehicle_cyllinder = `Cyllinder is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_type) {
      errorObj.vehicle_type = `Type is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_tare) {
      errorObj.vehicle_tare = `Tare is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_eei) {
      errorObj.vehicle_eei = `EEI is required.`;
      error = true;
    } else if (
      isNaN(vehicleData.vehicle_eei) ||
      parseFloat(vehicleData.vehicle_eei) <= 0
    ) {
      errorObj.vehicle_eei = `EEI must be a valid positive number.`;
      error = true;
    }

    if (!vehicleData.vehicle_internl_ref) {
      errorObj.vehicle_internl_ref = `Internal Ref is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_external_ref) {
      errorObj.vehicle_external_ref = `external Ref is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_exp_value) {
      errorObj.vehicle_exp_value = `Exp value is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_provenance) {
      errorObj.vehicle_provenance = `Provenance is required.`;
      error = true;
    }
    if (!vehicleData.vehicle_park) {
      errorObj.vehicle_park = `Park is required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    let data = {
      vehicle_owner_id: ownerId,
      vehicle_process_type: vehicleData.vehicle_process_type,
      vehicle_process_start_date: vehicleData.vehicle_process_start_date,
      vehicle_process_end_date: vehicleData.vehicle_process_end_date,
      registration: vehicleData.registration,
      registration_plate: vehicleData.registration_plate,
      vehicle_frame: vehicleData.vehicle_frame,
      vehicle_model_id: vehicleData.vehicle_model_id,
      vehicle_brand: vehicleData.vehicle_brand,
      vehicle_model: vehicleData.vehicle_model,
      vehicle_booklet_model: vehicleData.vehicle_booklet_model,
      vehicle_motorization: vehicleData.vehicle_motorization,
      vehicle_category: vehicleData.vehicle_category,
      vehicle_year: vehicleData.vehicle_year,
      vehicle_kms: vehicleData.vehicle_kms,
      vehicle_engine: vehicleData.vehicle_engine,
      vehicle_approval: vehicleData.vehicle_approval,
      vehicle_total_gross_weight: vehicleData.vehicle_total_gross_weight,
      vehicle_fuel: vehicleData.vehicle_fuel,
      vehicle_parts: vehicleData.vehicle_parts,
      vehicle_color: vehicleData.vehicle_color,
      vehicle_cyllinder: vehicleData.vehicle_cyllinder,
      vehicle_type: vehicleData.vehicle_type,
      vehicle_tare: vehicleData.vehicle_tare,
      vehicle_eei: vehicleData.vehicle_eei,
      vehicle_internl_ref: vehicleData.vehicle_internl_ref,
      vehicle_external_ref: vehicleData.vehicle_external_ref,
      vehicle_exp_value: vehicleData.vehicle_exp_value,
      vehicle_provenance: vehicleData.vehicle_provenance,
      vehicle_park: vehicleData.vehicle_park,
      body_sent_to_waste: vehicleData.body_sent_to_waste,
      // ...(vehicleProcessList ? { uuid: vehicleProcessList.uuid } : {}),
    };

    let updateData = {
      vehicle_owner_id: vehicleProcessList?.vehicle_owner_id,
      uuid: vehicleProcessList?.uuid,
      vehicle_process_type: vehicleData.vehicle_process_type,
      vehicle_process_start_date: vehicleData.vehicle_process_start_date,
      vehicle_process_end_date: vehicleData.vehicle_process_end_date,
      registration: vehicleData.registration,
      registration_plate: vehicleData.registration_plate,
      vehicle_frame: vehicleData.vehicle_frame,
      vehicle_model_id: vehicleData.vehicle_model_id,
      vehicle_brand: vehicleData.vehicle_brand,
      vehicle_model: vehicleData.vehicle_model,
      vehicle_booklet_model: vehicleData.vehicle_booklet_model,
      vehicle_motorization: vehicleData.vehicle_motorization,
      vehicle_category: vehicleData.vehicle_category,
      vehicle_year: vehicleData.vehicle_year,
      vehicle_kms: vehicleData.vehicle_kms,
      vehicle_engine: vehicleData.vehicle_engine,
      vehicle_approval: vehicleData.vehicle_approval,
      vehicle_total_gross_weight: vehicleData.vehicle_total_gross_weight,
      vehicle_fuel: vehicleData.vehicle_fuel,
      vehicle_parts: vehicleData.vehicle_parts,
      vehicle_color: vehicleData.vehicle_color,
      vehicle_cyllinder: vehicleData.vehicle_cyllinder,
      vehicle_type: vehicleData.vehicle_type,
      vehicle_tare: vehicleData.vehicle_tare,
      vehicle_eei: vehicleData.vehicle_eei,
      vehicle_internl_ref: vehicleData.vehicle_internl_ref,
      vehicle_external_ref: vehicleData.vehicle_external_ref,
      vehicle_exp_value: vehicleData.vehicle_exp_value,
      vehicle_provenance: vehicleData.vehicle_provenance,
      vehicle_park: vehicleData.vehicle_park,
      body_sent_to_waste: vehicleData.body_sent_to_waste,
    };
    console.log("updateData",updateData)

    if (vehicleProcessList?.uuid) {
    
      console.log("vehicleProcessList?.uuid",vehicleData.body_sent_to_waste)
      createVehicle(updateData)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_images");
          localStorage.setItem("vehicleId", response.data.data.id);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      createVehicle(data)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_images");
          localStorage.setItem("vehicleId", response.data.data.id);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleSelectChange = (selectedOption) => {
    setVehicleData({
      ...vehicleData,
      vehicle_model_id: selectedOption ? selectedOption.value : "",
    });
  };

  const data = Number(vehicleData.vehicle_model_id);

  const selectedModel = VehicleModelList.find((item) => item.value == data);

  const selectedCategory = VehicleCategoryList.find(
    (item) => item.value == vehicleData.vehicle_category
  );

  const selectedFuel = VehicleFuelList.find(
    (item) => item.value == vehicleData.vehicle_fuel
  );

  const selectedType = VehicleTypeList.find(
    (item) => item.value == vehicleData.vehicle_type
  );

  const handleVehicleVinForm = (e) => {
    e.preventDefault();
    setLoading(true);

    if (AllvehicleSerachData?.serach_vehicle_id === 2) {
      let data = {
        country: AllvehicleSerachData.country,
        plate: AllvehicleSerachData.plate,
        language: "EN",
      };
      getVehiclePlateAllist(data)
        .then((response) => {
          setVehiclePlateData(response.data.data.vehicles_decoded);
          setSerachModal(true);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let data = {
        vin: AllvehicleSerachData.vin,
        language: "EN",
      };
      getVehicleVinAllist(data)
        .then((response) => {
          setVehicleVinData(response.data.data.vehicles_decoded);

          setSerachModal(true);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            {!vehicleProcessList?.uuid && (
              <div className="row">
                <Form method={`post`} onSubmit={handleVehicleVinForm}>
                  <div className="vehicle-owner-form">
                    <div className="row mt-3 justify-content-start align-items-center">
                      <div className="col-md-3">
                        <FormGroup>
                          <Label className="col-form-label">
                            {"Serach Vehicle By"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                className="my-select-v"
                                options={VehicleSerachType}
                                value={VehicleSerachType.find(
                                  (option) =>
                                    option.value ===
                                    AllvehicleSerachData.serach_vehicle_id
                                )}
                                onChange={(selectedOption) =>
                                  setAllVehicleSerachData({
                                    ...AllvehicleSerachData,
                                    serach_vehicle_id: selectedOption.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>

                      {AllvehicleSerachData.serach_vehicle_id === 2 ? (
                        <>
                          <div className="col-md-3">
                            <FormGroup>
                              <Label className="col-form-label">
                                {AllvehicleSerachData.serach_vehicle_id === 2
                                  ? "Plate Number"
                                  : "VIN Number"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={AllvehicleSerachData.plate}
                                    onChange={(e) => {
                                      setAllVehicleSerachData({
                                        ...AllvehicleSerachData,
                                        plate: e.target.value,
                                      });
                                    }}
                                    name={`plate`}
                                    maxLength={100}
                                    placeholder="Enter Number"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </>
                      ) : (
                        <div className="col-md-3">
                          <FormGroup>
                            <Label className="col-form-label">
                              {AllvehicleSerachData.serach_vehicle_id === 2
                                ? "Plate Number"
                                : "VIN Number"}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  className="form-control"
                                  type="text"
                                  value={AllvehicleSerachData.vin}
                                  onChange={(e) => {
                                    setAllVehicleSerachData({
                                      ...AllvehicleSerachData,
                                      vin: e.target.value,
                                    });
                                  }}
                                  name={`vin`}
                                  maxLength={100}
                                  placeholder="Enter Number"
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      )}

                      {AllvehicleSerachData.serach_vehicle_id === 2 && (
                        <div className="col-md-3">
                          <FormGroup>
                            <Label className="col-form-label">
                              {"Country"}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <Select
                                  className="my-select-v"
                                  options={countryAllData}
                                  value={countryAllData.find(
                                    (option) =>
                                      option.value ===
                                      AllvehicleSerachData.country
                                  )}
                                  onChange={(selectedOption) =>
                                    setAllVehicleSerachData({
                                      ...AllvehicleSerachData,
                                      country: selectedOption.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      )}

                      <div className="col-md-3" style={{ marginTop: "26px" }}>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={loading}
                        >
                          {loading ? "Searching..." : "Search"}
                        </button>
                      </div>
                    </div>
                  </div>
                  {loading && (
                    <div
                      className="d-flex justify-content-center align-items-center position-fixed top-0 bottom-0 start-0 end-0 z-3"
                      style={{ zIndex: "99", backgroundColor: "#ffffffc2" }}
                    >
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}
            <Form method={`post`} onSubmit={handleVehicleForm}>
              <div className="vehicle-owner-form">
                <div className="card-body">
                  <div className="row justify-content-start">
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Process Type"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={ProcessType}
                              value={ProcessType.find(
                                (option) =>
                                  option.value ===
                                  vehicleData.vehicle_process_type
                              )}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_process_type: selectedOption.value,
                                })
                              }
                              placeholder={"Select Process Type"}
                            />
                          </div>
                          {errors.vehicle_process_type && (
                            <span className="input-error">
                              {errors.vehicle_process_type}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Model"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={VehicleModelList}
                              value={selectedModel}
                              onChange={handleSelectChange}
                              placeholder={"Select Vehicle Model"}
                              isSearchable={true}
                              onInputChange={handleInputChange}
                              inputValue={inputValue}
                              isClearable={true}
                            />
                          </div>
                          {errors.vehicle_model_id && (
                            <span className="input-error">
                              {errors.vehicle_model_id}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Registration Plate"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.registration_plate}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  registration_plate: e.target.value,
                                });
                              }}
                              name={`registration_plate`}
                              maxLength={100}
                              placeholder="Registration Plate"
                            />
                          </div>
                          {errors.registration_plate && (
                            <span className="input-error">
                              {errors.registration_plate}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Registration"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.registration}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  registration: e.target.value,
                                });
                              }}
                              name={`registration`}
                              maxLength={50}
                              placeholder="Registration"
                            />
                          </div>
                          {errors.registration && (
                            <span className="input-error">
                              {errors.registration}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Process Start Date"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              type="date"
                              id="vehicle_process_start_date"
                              className="form-control"
                              value={vehicleData.vehicle_process_start_date}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_process_start_date: e.target.value,
                                });
                              }}
                              disabled={!!vehicleProcessList?.uuid}
                              placeholder="Process Start Date"
                            />
                          </div>
                          {errors.vehicle_process_start_date && (
                            <span className="input-error">
                              {errors.vehicle_process_start_date}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Process End Date"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              type="date"
                              id="vehicle_process_end_date"
                              className="form-control"
                              value={vehicleData.vehicle_process_end_date}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_process_end_date: e.target.value,
                                });
                              }}
                              disabled={!!vehicleProcessList?.uuid}
                              placeholder="Process End Date"
                            />
                          </div>
                          {errors.vehicle_process_end_date && (
                            <span className="input-error">
                              {errors.vehicle_process_end_date}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Frame"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_frame}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_frame: e.target.value,
                                });
                              }}
                              name={`vehicle_frame`}
                              maxLength={100}
                              placeholder="Vehicle Frame"
                            />
                          </div>
                          {errors.vehicle_frame && (
                            <span className="input-error">
                              {errors.vehicle_frame}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Park"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_park}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_park: e.target.value,
                                });
                              }}
                              name={`vehicle_park`}
                              maxLength={100}
                              placeholder="Vehicle Park"
                            />
                          </div>
                          {errors.vehicle_park && (
                            <span className="input-error">
                              {errors.vehicle_park}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Brand"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_brand}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_brand: e.target.value,
                                });
                              }}
                              name={`vehicle_brand`}
                              maxLength={30}
                              placeholder="Brand"
                            />
                          </div>
                          {errors.vehicle_brand && (
                            <span className="input-error">
                              {errors.vehicle_brand}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Booklet Model"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_booklet_model}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_booklet_model: e.target.value,
                                });
                              }}
                              name={`vehicle_booklet_model`}
                              maxLength={100}
                              placeholder="Booklet Model"
                            />
                          </div>
                          {errors.vehicle_booklet_model && (
                            <span className="input-error">
                              {errors.vehicle_booklet_model}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Model"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_model}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_model: e.target.value,
                                });
                              }}
                              name={`vehicle_model`}
                              maxLength={30}
                              placeholder="Vehicle Model"
                            />
                          </div>
                          {errors.vehicle_model && (
                            <span className="input-error">
                              {errors.vehicle_model}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Tare"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_tare}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_tare: e.target.value,
                                });
                              }}
                              name={`vehicle_tare`}
                              maxLength={100}
                              placeholder="Vehicle Tare"
                            />
                          </div>
                          {errors.vehicle_tare && (
                            <span className="input-error">
                              {errors.vehicle_tare}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Motorization"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_motorization}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_motorization: e.target.value,
                                });
                              }}
                              name={`vehicle_motorization`}
                              maxLength={100}
                              placeholder="Motorization"
                            />
                          </div>
                          {errors.vehicle_motorization && (
                            <span className="input-error">
                              {errors.vehicle_motorization}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Category"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={VehicleCategoryList}
                              value={selectedCategory}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_category: selectedOption
                                    ? selectedOption.value
                                    : "",
                                })
                              }
                              placeholder={"Select Vehicle Category"}
                            />
                          </div>
                          {errors.vehicle_category && (
                            <span className="input-error">
                              {errors.vehicle_category}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Year"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_year}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_year: e.target.value,
                                });
                              }}
                              name={`vehicle_year`}
                              maxLength={100}
                              placeholder="Vehicle Year"
                            />
                          </div>
                          {errors.vehicle_year && (
                            <span className="input-error">
                              {errors.vehicle_year}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle KMS"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_kms}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_kms: e.target.value,
                                });
                              }}
                              name={`vehicle_kms`}
                              maxLength={100}
                              placeholder="Vehicle KMS"
                            />
                          </div>
                          {errors.vehicle_kms && (
                            <span className="input-error">
                              {errors.vehicle_kms}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Engine"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_engine}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_engine: e.target.value,
                                });
                              }}
                              name={`vehicle_engine`}
                              maxLength={100}
                              placeholder="Vehicle Engine"
                            />
                          </div>
                          {errors.vehicle_engine && (
                            <span className="input-error">
                              {errors.vehicle_engine}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Approval"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_approval}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_approval: e.target.value,
                                });
                              }}
                              name={`vehicle_approval`}
                              maxLength={100}
                              placeholder="Vehicle Approval"
                            />
                          </div>
                          {errors.vehicle_approval && (
                            <span className="input-error">
                              {errors.vehicle_approval}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Total Gross Weight"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_total_gross_weight}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_total_gross_weight: e.target.value,
                                });
                              }}
                              name={`vehicle_total_gross_weight`}
                              maxLength={100}
                              placeholder="Vehicle Total Gross Weight"
                            />
                          </div>
                          {errors.vehicle_total_gross_weight && (
                            <span className="input-error">
                              {errors.vehicle_total_gross_weight}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Fuel"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={VehicleFuelList}
                              value={selectedFuel}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_fuel: selectedOption
                                    ? selectedOption.value
                                    : "",
                                })
                              }
                              placeholder={"Select Vehicle Fuel"}
                            />
                          </div>
                          {errors.vehicle_fuel && (
                            <span className="input-error">
                              {errors.vehicle_fuel}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Parts"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_parts}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_parts: e.target.value,
                                });
                              }}
                              name={`vehicle_parts`}
                              maxLength={100}
                              placeholder="Vehicle Parts"
                            />
                          </div>
                          {errors.vehicle_parts && (
                            <span className="input-error">
                              {errors.vehicle_parts}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Color"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_color}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_color: e.target.value,
                                });
                              }}
                              name={`vehicle_color`}
                              maxLength={100}
                              placeholder="Vehicle Color"
                            />
                          </div>
                          {errors.vehicle_color && (
                            <span className="input-error">
                              {errors.vehicle_color}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Cyllinder"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_cyllinder}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_cyllinder: e.target.value,
                                });
                              }}
                              name={`vehicle_cyllinder`}
                              maxLength={100}
                              placeholder="Vehicle Cyllinder"
                            />
                          </div>
                          {errors.vehicle_cyllinder && (
                            <span className="input-error">
                              {errors.vehicle_cyllinder}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Type"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={VehicleTypeList}
                              value={selectedType}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_type: selectedOption
                                    ? selectedOption.value
                                    : "",
                                })
                              }
                              placeholder={"Select Vehicle Type"}
                            />
                          </div>
                          {errors.vehicle_type && (
                            <span className="input-error">
                              {errors.vehicle_type}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle EEI"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_eei}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_eei: e.target.value,
                                });
                              }}
                              name={`vehicle_eei`}
                              maxLength={100}
                              placeholder="Vehicle EEI"
                            />
                          </div>
                          {errors.vehicle_eei && (
                            <span className="input-error">
                              {errors.vehicle_eei}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Internal Ref"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_internl_ref}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_internl_ref: e.target.value,
                                });
                              }}
                              name={`vehicle_internl_ref`}
                              maxLength={100}
                              placeholder="Vehicle Internal Ref"
                            />
                          </div>
                          {errors.vehicle_internl_ref && (
                            <span className="input-error">
                              {errors.vehicle_internl_ref}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle External ref"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_external_ref}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_external_ref: e.target.value,
                                });
                              }}
                              name={`vehicle_external_ref`}
                              maxLength={100}
                              placeholder="Vehicle External ref"
                            />
                          </div>
                          {errors.vehicle_external_ref && (
                            <span className="input-error">
                              {errors.vehicle_external_ref}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle exp value"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_exp_value}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_exp_value: e.target.value,
                                });
                              }}
                              name={`vehicle_exp_value`}
                              maxLength={100}
                              placeholder="Vehicle exp value"
                            />
                          </div>
                          {errors.vehicle_exp_value && (
                            <span className="input-error">
                              {errors.vehicle_exp_value}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Provenance"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_provenance}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_provenance: e.target.value,
                                });
                              }}
                              name={`vehicle_provenance`}
                              maxLength={100}
                              placeholder="Vehicle Provenance"
                            />
                          </div>
                          {errors.vehicle_provenance && (
                            <span className="input-error">
                              {errors.vehicle_provenance}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Body Sent To Waste"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div mt-12">
                            <div className="form-check">
                              <input
                                className="my-checkbox-1 form-check-input"
                                type="checkbox"
                                checked={vehicleData.body_sent_to_waste}
                                onChange={handleCheckboxChange}
                              />
                              <label className="form-check-label pl-6">
                                Body Sent To Waste
                              </label>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <button
                        type="button"
                        className="back-btn"
                        onClick={() => setActiveTab("vehicle_owner")}
                      >
                        <i class="bi bi-arrow-left"></i> Back
                      </button>
                    </div>
                    <div className="col">
                      <div className="d-flex justify-content-end nxt-btn">
                        <button type="submit" className="btn btn-primary">
                          Next <i class="bi bi-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer"></div>
            </Form>
          </div>
        </div>
      </div>

      <Modal className="modal-xl" isOpen={serachModal}>
        <ModalHeader>
          {"Please select the Vehicle Information"}
          <button
            type="button"
            className="btn-closes-1"
            onClick={() => setSerachModal(false)}
          >
            <i className="bi bi-x"></i>
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="row justify-content-start">
            <div className="col-md-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by description..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="card-body vehicleparts-table">
              <h4>Cars ETAI Details</h4>
              <div className="vehicleparts-table-inner vehicle-serach">
                <div className="vehicleparts-table-modal">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Description</th>
                        <th>Image</th>
                        <th>Year</th>
                        <th>Model</th>
                        <th>Range</th>
                        <th>Body Type</th>
                        <th>CCM</th>
                        <th>CYL</th>
                        <th>Doors</th>
                        <th>Box Type</th>
                        <th>Manufacturer</th>
                        <th>Vehicle Type</th>
                        <th>Oil Capacity</th>
                      </tr>
                    </thead>

                    {AllvehicleSerachData.serach_vehicle_id === 1 ? (
                      <tbody>
                        {vehicleVinData?.cars_etai
                          ?.filter((data) =>
                            data?.vehicle_type_description
                              ?.toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map((data, index) => {
                            const optionValue =
                              data?.vehicle_type_description ||
                              `option-${index}`;

                            return (
                              <tr key={index}>
                                <td>
                                  <button
                                    type="button"
                                    className={`btn ${
                                      selectedOption === optionValue
                                        ? "btn-success"
                                        : "btn-primary"
                                    }`}
                                    onClick={(e) => handleOptionChange(e, data)}
                                  >
                                    Select
                                  </button>
                                </td>
                                <td>{data?.vehicle_type_description}</td>
                                <td>
                                  <td>
                                    <a
                                      className="eye-box1"
                                      href={data?.vehicle_model_image}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      aria-label="View vehicle model image"
                                    >
                                      <i className="bi bi-eye"></i>
                                    </a>
                                  </td>
                                </td>
                                <td>{data?.vehicle_model_fromYear}</td>
                                <td>{data?.vehicle_type_model}</td>
                                <td>{data?.vehicle_model_range}</td>
                                <td>{data?.vehicle_type_bodyType}</td>
                                <td>{data?.vehicle_type_ccm}</td>
                                <td>{data?.vehicle_type_cyl}</td>
                                <td>{data?.vehicle_type_doors}</td>
                                <td>{data?.vehicle_type_gearboxType}</td>
                                <td>{data?.vehicle_type_manufacter}</td>
                                <td>{data?.vehicle_type_vehicleType}</td>
                                <td>{data?.vehicle_type_oilCapacity}</td>
                              </tr>
                            );
                          })}
                        {vehicleVinData?.cars_etai?.filter((data) =>
                          data?.vehicle_type_description
                            ?.toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).length === 0 && (
                          <tr>
                            <td colSpan={14} className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    ) : (
                      <tbody>
                        {vehiclePlateData?.cars_etai
                          ?.filter((data) =>
                            data?.vehicle_type_description
                              ?.toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map((data, index) => {
                            const optionValue =
                              data?.vehicle_type_description ||
                              `option-${index}`;

                            return (
                              <tr key={index}>
                                {/* <td>
                                  <input
                                    type="radio"
                                    value={optionValue}
                                    checked={selectedOption === optionValue}
                                    onChange={(e) =>
                                      handleOptionChange(e, data)
                                    }
                                  />
                                </td> */}
                                <td>
                                  <button
                                    type="button"
                                    className={`btn ${
                                      selectedOption === optionValue
                                        ? "btn-success"
                                        : "btn-primary"
                                    }`}
                                    onClick={(e) => handleOptionChange(e, data)}
                                  >
                                    Select
                                  </button>
                                </td>
                                <td>{data?.vehicle_type_description}</td>
                                <td>
                                  <td>
                                    <a
                                      className="eye-box1"
                                      href={data?.vehicle_make_image}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      aria-label="View vehicle model image"
                                    >
                                      <i className="bi bi-eye"></i>
                                    </a>
                                  </td>
                                </td>
                                <td>{data?.vehicle_model_fromYear}</td>
                                <td>{data?.vehicle_type_model}</td>
                                <td>{data?.vehicle_model_range}</td>
                                <td>{data?.vehicle_type_bodyType}</td>
                                <td>{data?.vehicle_type_ccm}</td>
                                <td>{data?.vehicle_type_cyl}</td>
                                <td>{data?.vehicle_type_doors}</td>
                                <td>{data?.vehicle_type_gearboxType}</td>
                                <td>{data?.vehicle_type_manufacter}</td>
                                <td>{data?.vehicle_type_vehicleType}</td>
                                <td>{data?.vehicle_type_oilCapacity}</td>
                              </tr>
                            );
                          })}
                        {vehiclePlateData?.cars_etai?.filter((data) =>
                          data?.vehicle_type_description
                            ?.toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).length === 0 && (
                          <tr>
                            <td colSpan={14} className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <h4 style={{ marginTop: "20px" }}>Cars TecDoc Details</h4>

              <div
                className="vehicleparts-table-inner vehicle-serach"
                style={{ marginTop: "20px" }}
              >
                <div className="vehicleparts-table-modal">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Description</th>
                        <th>Model</th>
                        <th>Body Type</th>
                        <th>CCM</th>
                        <th>CYL</th>
                        <th>Doors</th>
                        <th>Manufacturer</th>
                      </tr>
                    </thead>

                    {AllvehicleSerachData.serach_vehicle_id === 1 ? (
                      <tbody>
                        {vehicleVinData?.cars_tecdoc
                          ?.filter((data) =>
                            data?.vehicle_type_description
                              ?.toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map((data, index) => {
                            const optionValue =
                              data?.vehicle_type_description ||
                              `option-${index}`;

                            return (
                              <tr key={index}>
                                <td>
                                  <button
                                    type="button"
                                    className={`btn ${
                                      selectedOption === optionValue
                                        ? "btn-success"
                                        : "btn-primary"
                                    }`}
                                    onClick={(e) => handleOptionChange(e, data)}
                                  >
                                    Select
                                  </button>
                                </td>
                                <td>{data?.vehicle_type_description}</td>

                                <td>{data?.vehicle_type_model}</td>
                                <td>{data?.vehicle_type_bodyType}</td>
                                <td>{data?.vehicle_type_ccm}</td>
                                <td>{data?.vehicle_type_cyl}</td>
                                <td>{data?.vehicle_type_doors}</td>
                                <td>{data?.vehicle_type_manufacter}</td>
                              </tr>
                            );
                          })}
                        {vehicleVinData?.cars_etai?.filter((data) =>
                          data?.vehicle_type_description
                            ?.toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).length === 0 && (
                          <tr>
                            <td colSpan={14} className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    ) : (
                      <tbody>
                        {vehiclePlateData?.cars_etai
                          ?.filter((data) =>
                            data?.vehicle_type_description
                              ?.toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map((data, index) => {
                            const optionValue =
                              data?.vehicle_type_description ||
                              `option-${index}`;

                            return (
                              <tr key={index}>
                                <td>
                                  <button
                                    type="button"
                                    className={`btn ${
                                      selectedOption === optionValue
                                        ? "btn-success"
                                        : "btn-primary"
                                    }`}
                                    onClick={(e) => handleOptionChange(e, data)}
                                  >
                                    Select
                                  </button>
                                </td>
                                <td>{data?.vehicle_type_description}</td>
                                <td>{data?.vehicle_model_fromYear}</td>
                                <td>{data?.vehicle_type_model}</td>
                                <td>{data?.vehicle_model_range}</td>
                                <td>{data?.vehicle_type_bodyType}</td>
                                <td>{data?.vehicle_type_ccm}</td>
                                <td>{data?.vehicle_type_cyl}</td>
                              </tr>
                            );
                          })}
                        {vehiclePlateData?.cars_etai?.filter((data) =>
                          data?.vehicle_type_description
                            ?.toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).length === 0 && (
                          <tr>
                            <td colSpan={14} className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-end"
              style={{ padding: "10px" }}
            ></div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Vehicles;
