import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import {
  GetPartCatalogDetails,
  getPartGroupAllList,
  getPartLocationAllList,
  getPartSideAllList,
  getPartTypeAllList,
} from "../../services/PartTypeService";
import Utils from "../../utils";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createPartCatalog,
  updatePartCatalog,
} from "../../services/LerCatalogService";

const AddCatalog = () => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [partCatalogData, setPartCatalogData] = useState({
    id: "",
    namePartPT: "",
    namePartES: "",
    namePartEN: "",
    namePartFR: "",
    namePartIT: "",
    namePartDE: "",
    idTypePart: "",
    idLocation: "",
    idGroup: "",
    idSide: "",
    dependsBodyCar: "",
    numCarDoors: "",
    individual: "0",
    weight: "",
    length: "",
    width: "",
    height: "",
  });
  let errorsObj = {
    namePartPT: "",
    namePartES: "",
    namePartEN: "",
    namePartFR: "",
    namePartIT: "",
    namePartDE: "",
    idTypePart: "",
    idLocation: "",
    idGroup: "",
    idSide: "",
    dependsBodyCar: "",
    numCarDoors: "",
    individual: "",
    weight: "",
    length: "",
    width: "",
    height: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [partTypeList, setPartTypeList] = useState([]);
  const [partLocationList, setPartLocationList] = useState([]);
  const [partGroupList, setPartGroupList] = useState([]);
  const [partSideList, setPartSideList] = useState([]);
  const [partCatalogDetails, setCatalogDetails] = useState({});

  const { uuid } = useParams();

  useEffect(() => {
    let data = {
      id: uuid,
    };
    GetPartCatalogDetails(data)
      .then((response) => {
        setCatalogDetails(response.data.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        // setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (partCatalogDetails) {
      console.log("partCatalogDetails", partCatalogDetails);
      setPartCatalogData({
        id: partCatalogDetails?.id,
        namePartPT: partCatalogDetails?.namePartPT,
        namePartES: partCatalogDetails?.namePartES,
        namePartEN: partCatalogDetails?.namePartEN,
        namePartFR: partCatalogDetails?.namePartFR,
        namePartIT: partCatalogDetails?.namePartIT,
        namePartDE: partCatalogDetails?.namePartDE,
        idTypePart: partCatalogDetails?.idTypePart,
        idLocation: partCatalogDetails?.idLocation,
        idGroup: partCatalogDetails?.idGroup,
        idSide: partCatalogDetails?.idSide,
        dependsBodyCar: partCatalogDetails?.dependsBodyCar,
        numCarDoors: partCatalogDetails?.numCarDoors,
        individual: partCatalogDetails?.individual,
        weight: partCatalogDetails?.weight,
        length: partCatalogDetails?.length,
        width: partCatalogDetails?.width,
        height: partCatalogDetails?.height,
      });
    }
  }, [partCatalogDetails]);

  useEffect(function () {
    getPartTypeAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionUK,
          });
        }

        setPartTypeList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getPartLocationAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionUK,
          });
        }
        setPartLocationList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getPartGroupAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionUK,
          });
        }
        setPartGroupList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getPartSideAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionUK,
          });
        }
        setPartSideList(list);
      })
      .catch((err) => {});
  }, []);

  const handleCheckboxChange = (e) => {
    setPartCatalogData({
      ...partCatalogData,
      individual: e.target.checked ? "1" : "0",
    });
  };

  const handlePartCatalogForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!partCatalogData.namePartPT) {
      errorObj.namePartPT = `Part PT required.`;
      error = true;
    }

    if (!partCatalogData.namePartES) {
      errorObj.namePartES = `Part ES required.`;
      error = true;
    }
    if (!partCatalogData.namePartEN) {
      errorObj.namePartEN = `Part EN required.`;
      error = true;
    }
    if (!partCatalogData.namePartFR) {
      errorObj.namePartFR = `Part FR required.`;
      error = true;
    }
    if (!partCatalogData.namePartIT) {
      errorObj.namePartIT = `Part IT required.`;
      error = true;
    }
    if (!partCatalogData.namePartDE) {
      errorObj.namePartDE = `Part DE required.`;
      error = true;
    }

    if (!partCatalogData.idTypePart) {
      errorObj.idTypePart = `Part Type required.`;
      error = true;
    }
    if (!partCatalogData.idLocation) {
      errorObj.idLocation = `Part Location required.`;
      error = true;
    }
    if (!partCatalogData.idGroup) {
      errorObj.idGroup = `Part Group required.`;
      error = true;
    }
    if (!partCatalogData.idSide) {
      errorObj.idSide = `Part Slide required.`;
      error = true;
    }
    if (!partCatalogData.dependsBodyCar) {
      errorObj.dependsBodyCar = "Body Car required.";
      error = true;
    }
    if (!partCatalogData.numCarDoors || isNaN(partCatalogData.numCarDoors)) {
      errorObj.numCarDoors =
        "Number of car doors is required and must be a number.";
      error = true;
    }
    if (!partCatalogData.individual) {
      errorObj.individual = "Individual required.";
      error = true;
    }
    if (!partCatalogData.weight || isNaN(partCatalogData.weight)) {
      errorObj.weight = "Weight is required and must be a number.";
      error = true;
    }
    if (!partCatalogData.length || isNaN(partCatalogData.length)) {
      errorObj.length = "Length is required and must be a number.";
      error = true;
    }
    if (!partCatalogData.width || isNaN(partCatalogData.width)) {
      errorObj.width = "Width is required and must be a number.";
      error = true;
    }
    if (!partCatalogData.height || isNaN(partCatalogData.height)) {
      errorObj.height = "Height is required and must be a number.";
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();
    if (error) return;

    let data = {
      namePartPT: partCatalogData?.namePartPT,
      namePartES: partCatalogData?.namePartES,
      namePartEN: partCatalogData?.namePartEN,
      namePartFR: partCatalogData?.namePartFR,
      namePartIT: partCatalogData?.namePartIT,
      namePartDE: partCatalogData?.namePartDE,
      idTypePart: partCatalogData?.idTypePart,
      idLocation: partCatalogData?.idLocation,
      idGroup: partCatalogData?.idGroup,
      idSide: partCatalogData?.idSide,
      dependsBodyCar: partCatalogData?.dependsBodyCar,
      numCarDoors: partCatalogData?.numCarDoors,
      individual: partCatalogData?.individual,
      weight: partCatalogData?.weight,
      length: partCatalogData?.length,
      width: partCatalogData?.width,
      height: partCatalogData?.height,
    };

    let updateData = {
      id: partCatalogData?.id,
      namePartPT: partCatalogData?.namePartPT,
      namePartES: partCatalogData?.namePartES,
      namePartEN: partCatalogData?.namePartEN,
      namePartFR: partCatalogData?.namePartFR,
      namePartIT: partCatalogData?.namePartIT,
      namePartDE: partCatalogData?.namePartDE,
      idTypePart: partCatalogData?.idTypePart,
      idLocation: partCatalogData?.idLocation,
      idGroup: partCatalogData?.idGroup,
      idSide: partCatalogData?.idSide,
      dependsBodyCar: partCatalogData?.dependsBodyCar,
      numCarDoors: partCatalogData?.numCarDoors,
      individual: partCatalogData?.individual,
      weight: partCatalogData?.weight,
      length: partCatalogData?.length,
      width: partCatalogData?.width,
      height: partCatalogData?.height,
    };

    if (partCatalogData?.id) {
      updatePartCatalog(updateData)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/part-catalog");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      createPartCatalog(data)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/part-catalog");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Add Catalog</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/part-catalog">Part Catalog</a>
                </li>
                <li className="breadcrumb-item active">Add PartCatalog</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                {/* <div className="card-header">
                  <h3 className="card-title">Update Profile</h3>
                </div> */}

                <Form method={`post`} onSubmit={handlePartCatalogForm}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part PT"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartPT}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartPT: e.target.value,
                                  });
                                }}
                                name={`namePartPT`}
                                maxLength={100}
                                placeholder="Part PT"
                              />
                            </div>
                            {errors.namePartPT && (
                              <span className="input-error">
                                {errors.namePartPT}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part ES"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartES}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartES: e.target.value,
                                  });
                                }}
                                name={`namePartES`}
                                maxLength={100}
                                placeholder="Part ES"
                              />
                            </div>
                            {errors.namePartES && (
                              <span className="input-error">
                                {errors.namePartES}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part UK"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartEN}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartEN: e.target.value,
                                  });
                                }}
                                name={`namePartEN`}
                                maxLength={100}
                                placeholder="Part UK"
                              />
                            </div>
                            {errors.namePartEN && (
                              <span className="input-error">
                                {errors.namePartEN}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part FR"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartFR}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartFR: e.target.value,
                                  });
                                }}
                                name={`namePartFR`}
                                maxLength={100}
                                placeholder="Part FR"
                              />
                            </div>
                            {errors.namePartFR && (
                              <span className="input-error">
                                {errors.namePartFR}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part IT"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartIT}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartIT: e.target.value,
                                  });
                                }}
                                name={`namePartIT`}
                                maxLength={100}
                                placeholder="Part IT"
                              />
                            </div>
                            {errors.namePartIT && (
                              <span className="input-error">
                                {errors.namePartIT}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part DE"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartDE}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartDE: e.target.value,
                                  });
                                }}
                                name={`namePartDE`}
                                maxLength={100}
                                placeholder="Part DE"
                              />
                            </div>
                            {errors.namePartDE && (
                              <span className="input-error">
                                {errors.namePartDE}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part Type"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                options={partTypeList}
                                value={partTypeList.find(
                                  (option) =>
                                    option.value ===
                                    Number(partCatalogData.idTypePart)
                                )}
                                onChange={(selectedOption) =>
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    idTypePart: selectedOption.value,
                                  })
                                }
                                placeholder={"Select Part Type"}
                              />
                            </div>
                            {errors.idTypePart && (
                              <span className="input-error">
                                {errors.idTypePart}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part Location"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                options={partLocationList}
                                value={partLocationList.find(
                                  (option) =>
                                    option.value ===
                                    Number(partCatalogData.idLocation)
                                )}
                                onChange={(selectedOption) =>
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    idLocation: selectedOption.value,
                                  })
                                }
                                placeholder={"Select Part Location"}
                              />
                            </div>
                            {errors.idLocation && (
                              <span className="input-error">
                                {errors.idLocation}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part Group"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                options={partGroupList}
                                value={partGroupList.find(
                                  (option) =>
                                    option.value ===
                                    Number(partCatalogData.idGroup)
                                )}
                                onChange={(selectedOption) =>
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    idGroup: selectedOption.value,
                                  })
                                }
                                placeholder={"Select Part Group"}
                              />
                            </div>
                            {errors.idGroup && (
                              <span className="input-error">
                                {errors.idGroup}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part Slide"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                options={partSideList}
                                value={partSideList.find(
                                  (option) =>
                                    option.value ===
                                    Number(partCatalogData.idSide)
                                )}
                                onChange={(selectedOption) =>
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    idSide: selectedOption.value,
                                  })
                                }
                                placeholder={"Select Part Slide"}
                              />
                            </div>
                            {errors.idSide && (
                              <span className="input-error">
                                {errors.idSide}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Depends Body Car"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.dependsBodyCar}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    dependsBodyCar: e.target.value,
                                  });
                                }}
                                name={`dependsBodyCar`}
                                maxLength={100}
                                placeholder="Depends Body Car"
                              />
                            </div>
                            {errors.dependsBodyCar && (
                              <span className="input-error">
                                {errors.dependsBodyCar}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Number Of Car Doors"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="number"
                                value={partCatalogData.numCarDoors}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    numCarDoors: e.target.value,
                                  });
                                }}
                                name={`numCarDoors`}
                                maxLength={100}
                                placeholder="Number Of Car Doors"
                              />
                            </div>
                            {errors.numCarDoors && (
                              <span className="input-error">
                                {errors.numCarDoors}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Height"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="number"
                                value={partCatalogData.height}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    height: e.target.value,
                                  });
                                }}
                                name={`height`}
                                maxLength={100}
                                placeholder="height"
                              />
                            </div>
                            {errors.height && (
                              <span className="input-error">
                                {errors.height}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Weight"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="number"
                                value={partCatalogData.weight}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    weight: e.target.value,
                                  });
                                }}
                                name={`weight`}
                                maxLength={100}
                                placeholder="Weight"
                              />
                            </div>
                            {errors.weight && (
                              <span className="input-error">
                                {errors.weight}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Length"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="number"
                                value={partCatalogData.length}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    length: e.target.value,
                                  });
                                }}
                                name={`length`}
                                maxLength={100}
                                placeholder="Length"
                              />
                            </div>
                            {errors.length && (
                              <span className="input-error">
                                {errors.length}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Width"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control form-control input-search form-control"
                                type="number"
                                value={partCatalogData.width}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    width: e.target.value,
                                  });
                                }}
                                name={`width`}
                                maxLength={100}
                                placeholder="Width"
                              />
                            </div>
                            {errors.width && (
                              <span className="input-error">
                                {errors.width}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            Individual
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={partCatalogData.individual === "1"}
                                  onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label">
                                  Individual
                                </label>
                              </div>
                            </div>
                            {errors.individual && (
                              <span className="input-error">
                                {errors.individual}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      {/* <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2">
                            Select File
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                                name="file"
                                accept=".jpg,.jpeg,.png"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col">
                        <button
                          type="button"
                          className="back-btn"
                          onClick={() => navigate("/part-catalog")}
                        >
                          <i class="bi bi-arrow-left"> </i>Back
                        </button>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-end nxt-btn">
                          <button type="submit" className="btn btn-primary">
                            Submit <i class="bi bi-check2"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer"></div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddCatalog;
