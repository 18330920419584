import { Container, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { Tabs, Tab, Nav } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { getViewTermsConditionList } from "../../services/TermsConditionService";
import { toast } from "react-toastify";
import Utils from "../../utils";

const TermsCondition = () => {
  const [key, setKey] = useState(""); // Initial key state
  const [TermsConditionList, setTermsConditionList] = useState([]);

  useEffect(() => {
    getViewTermsConditionList()
      .then((response) => {
        const terms = response.data.data;
        setTermsConditionList(terms);
        if (terms.length > 0) {
          setKey(terms[0].sequence.toString()); // Set default tab to the first item
        }
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  return (
    <div id="Home">
      <div className="hero-bg d-flex align-items-center heroBg">
        <Container className="termsMain">
          <div className="termstitle">
            <h4>Terms of Services</h4>
          </div>
          <Row className="justify-content-center">
            <Col lg="4" className="px-0 leftContent">
              <div className="TACleft">
                <Nav variant="pills" className="flex-column">
                  {TermsConditionList?.map((item) => (
                    <Nav.Item key={item.sequence}>
                      <Nav.Link
                        className="d-flex"
                        eventKey={item.sequence.toString()}
                        onClick={() => setKey(item.sequence.toString())}
                      >
                        <span>{item.sequence}</span>
                        <p>{item.title}</p>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
            </Col>

            <Col lg="8">
              <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                {TermsConditionList?.map((item) => (
                  <Tab eventKey={item.sequence.toString()} key={item.sequence}>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </Tab>
                ))}
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TermsCondition;
