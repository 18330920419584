import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./view/Home";
import Login from "./view/auth/Login";
import Signup from "./view/auth/Signup";
import "./asset/style/style.css";
import "./asset/style/custome.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { store } from "./store";
import { Provider } from "react-redux";
import DashboardLayout from "./components/Layout/DashboardLayout";
import { PrivateRoute, PublicRoute } from "./utils/function";
import Dashboard from "./view/dashboard";
import FuelType from "./view/fuelType";
import LerCatalog from "./view/lerCatalog";
import PartType from "./view/PartType";
import PartLocation from "./view/partLocation";
import PartGroup from "./view/partGroup";
import PartSides from "./view/partSides";
import Profile from "./view/profile";
import PartCatalog from "./view/partCatalog";
import AddCatalog from "./view/partCatalog/AddCatalog";
import Subscription from "./view/subscription";
import VehicleCategory from "./view/vehicleCategory";
import VehicleType from "./view/vehicleType";
import VehicleModel from "./view/vehicleModel";
import Vehicle from "./view/vehicle";
import PartsInventory from "./view/PartsInventory";

import VehicleProcess from "./view/vehicleProcess";
import AddParts from "./view/PartsInventory/AddParts";
import AddForParts from "./view/PartsInventory/AddForParts";
import RolePermission from "./view/RolePermission/Roles";
import AddRolePermission from "./view/RolePermission/AddRolePermission";

import User from "./view/user";
import TermsCondition from "./view/TermsCondition";
import PrivacyPolicy from "./view/privacyPolicy";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FAQ from "./view/FAQ";
import Settings from "./view/settings";
import FaqAdmin from "./view/FaqAdmin";
import TermsConditionAdmin from "./view/TermsConditionAdmin";
import PrivacyPolicyAdmin from "./view/privacyPolicyAdmin";
import DepollutionOpration from "./view/DepollutionOpration";
import AddForPartVehicleDepollution from "./view/DepollutionOpration/AddForPartVehicleDepollution";
import AddForVehicleDepollution from "./view/DepollutionOpration/AddForVehicleDepollution";
import AddOtherDepollution from "./view/DepollutionOpration/AddOtherDepollution";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/reset-password/:token"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />

            <Route
              path="/terms-condition"
              element={
                <Layout>
                  <TermsCondition />
                </Layout>
              }
            />

            <Route
              path="/privacy-policy"
              element={
                <Layout>
                  <PrivacyPolicy />
                </Layout>
              }
            />
            <Route
              path="/faq"
              element={
                <Layout>
                  <FAQ />
                </Layout>
              }
            />
            <Route path={"/"} element={<PublicRoute />}>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Route>

            <Route path="/" element={<PrivateRoute />}>
              <Route path={"/"} element={<DashboardLayout />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/fuel-type" element={<FuelType />} />
                <Route path="/part-type" element={<PartType />} />
                <Route path="/part-location" element={<PartLocation />} />
                <Route path="/part-group" element={<PartGroup />} />
                <Route path="/part-sides" element={<PartSides />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/ler-catalog" element={<LerCatalog />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/part-catalog" element={<PartCatalog />} />
                <Route path="/add-part-catalog" element={<AddCatalog />} />
                <Route
                  path="/add-part-catalog/:uuid"
                  element={<AddCatalog />}
                />
                <Route path="/vehicle-category" element={<VehicleCategory />} />
                <Route path="/vehicle-type" element={<VehicleType />} />
                <Route path="/vehicle-model" element={<VehicleModel />} />
                <Route path="/vehicle" element={<Vehicle />} />
                <Route path="/vehicle-process" element={<VehicleProcess />} />
                <Route path="/vehicle/:uuid" element={<Vehicle />} />
                <Route path="/parts-inventory" element={<PartsInventory />} />
                <Route path="/parts-inventory" element={<PartsInventory />} />
                <Route
                  path="/parts-inventory/AddParts"
                  element={<AddParts />}
                />
                <Route
                  path="/parts-inventory/AddParts/:uuid"
                  element={<AddParts />}
                />
                <Route
                  path="/parts-inventory-addparts"
                  element={<AddForParts />}
                />

                <Route path="/Roles" element={<RolePermission />} />
                <Route
                  path="/add-role-permssion"
                  element={<AddRolePermission />}
                />
                <Route
                  path="/add-role-permssion/:uuid"
                  element={<AddRolePermission />}
                />
                <Route path="/User" element={<User />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/faqs" element={<FaqAdmin />} />
                <Route
                  path="/terms-condition-admin"
                  element={<TermsConditionAdmin />}
                />
                <Route
                  path="/privacy-policy-admin"
                  element={<PrivacyPolicyAdmin />}
                />
                <Route
                  path="/depollution-operation"
                  element={<DepollutionOpration />}
                />
                <Route
                  path="/add-vehicle-part-depollution-operation"
                  element={<AddForPartVehicleDepollution />}
                />

                <Route
                  path="/add-vehicle-depollution-operation"
                  element={<AddForVehicleDepollution />}
                />


<Route
                  path="/add-vehicle-depollution-operation/:uuid"
                  element={<AddForVehicleDepollution />}
                />

                <Route
                  path="/other-depollution-operation"
                  element={<AddOtherDepollution />}
                />
              </Route>
            </Route>
          </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
