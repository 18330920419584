import React, { useEffect, useRef, useState } from "react";
import {
  getCatalogList,
  actionPartInventoryTempStore,
  getVehicleList,
  getPartInventoryTempList,
  deletePartInventory,
  DestoryPartInventoryTemp,
  createPartsInventory,
  getPartInventoryDetails,
  updatePartsInventory,
  actionPartInventoryImage,
  destoryPartInventoryImage,
} from "../../services/PartsInventory";
import { Button, FormGroup, Label, Form, Modal } from "reactstrap";
import Select from "react-select";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import VehiclePartsList from "./VehiclePartsList";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getVehicleModelAllList } from "../../services/VehicleService";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-js-pagination";
import { use } from "i18next";

const VehiclePartsAdd = ({ vehicleAddDetails, vehicleImageDetails }) => {
  console.log("vehicleAddDetails", vehicleAddDetails);

  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTemp, setSearchQueryTemp] = useState("");
  const [VehicleModelList, setVehicleModelList] = useState([]);
  const [VehicleModelAllList, setVehicleModelAllList] = useState([]);
  const [CatalogList, setCatalogList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tempData, setTempData] = useState({});
  const [partsData, setPartsData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [inputSelctedValue, setInputSelctedValue] = useState("");
  const [images, setImage] = useState([]);
  console.log("images", images);

  const [vehiclePartsData, setVehiclePartsData] = useState({
    vehicle_model_id: "",
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
  });

  const [vehiclePartsDatasTemp, setVehiclePartsDatasTemp] = useState({
    vehicle_model_id: "",
  });
  const [selectedVehicleModel, setSelectedVehicleModel] = useState(null);
  const [detailsForImages, SetDetailsForImages] = useState([]);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [showSkip, setShowSkip] = useState(false);
  const [previewUrl, SetPreviewUrl] = useState([]);
  const [showModalAbout, setShowModalAbout] = useState(false);
  const [ImageDetailsNot, setImageDetailsNot] = useState({});
  const fileInputRef = useRef(null);

  const handleSkip = () => {
    setCurrentPartIndex((prevIndex) => prevIndex + 1);
    setShowSkip(false);
  };

  const handleOpenSkip = () => {
    setShowSkip(true);
  };

  const handleCloseSkip = () => {
    setShowSkip(false);
  };

  const handleFinish = () => {
    // if (images?.length === 0) {
    //   navigate("/parts-inventory");
    // } else {

    if (images?.length === 0) {
      const formData = new FormData();
      formData.append("part_id", ImageDetailsNot?.id);
      images.forEach((file) => {
        formData.append("files", file);
      });

      actionPartInventoryImage(formData)
        .then((response) => {
          toast.success(response.data.message);
          setImage([]);
          SetPreviewUrl([]);
          setCurrentPartIndex((prevIndex) => prevIndex + 1);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      const formData = new FormData();
      formData.append("part_id", ImageDetailsNot?.id);
      images.forEach((file) => {
        formData.append("files", file);
      });
      actionPartInventoryImage(formData)
        .then((response) => {
          toast.success(response.data.message);
          setImage([]);
          SetPreviewUrl([]);
          navigate("/parts-inventory");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const currentPart =
    vehicleImageDetails && vehicleImageDetails?.length > currentPartIndex
      ? vehicleImageDetails[currentPartIndex]
      : detailsForImages && detailsForImages?.length > currentPartIndex
      ? detailsForImages[currentPartIndex]
      : null;

  const hasNextPart = currentPartIndex < detailsForImages.length;

  const handleSubmits = (event) => {
    event.preventDefault();

    if (images?.length === 0) {
      const formData = new FormData();
      formData.append("part_id", ImageDetailsNot?.id);
      images.forEach((file) => {
        formData.append("files", file);
      });

      actionPartInventoryImage(formData)
        .then((response) => {
          toast.success(response.data.message);
          setImage([]);
          SetPreviewUrl([]);
          setCurrentPartIndex((prevIndex) => prevIndex + 1);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      const formData = new FormData();
      formData.append("part_id", currentPart?.id);
      images.forEach((file) => {
        formData.append("files", file);
      });

      actionPartInventoryImage(formData)
        .then((response) => {
          toast.success(response.data.message);
          setImage([]);
          SetPreviewUrl([]);
          setCurrentPartIndex((prevIndex) => prevIndex + 1);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleAboutFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 0) {
      setImage((prevImages) => [...prevImages, ...selectedFiles]);

      const newPreviewUrls = selectedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      SetPreviewUrl((prevUrls) => [...prevUrls, ...newPreviewUrls]);
    }
  };
  const isLastPart =
    (vehicleImageDetails &&
      currentPartIndex === vehicleImageDetails?.length - 1) ||
    (detailsForImages && currentPartIndex === detailsForImages?.length - 1);

  const handleRemoveFile = (indexToRemove, item) => {
    // if (images?.length === 0) {
    //   setImage((prevFiles) =>
    //     prevFiles.filter((_, index) => index !== indexToRemove)
    //   );
    //   SetPreviewUrl((prevUrls) =>
    //     prevUrls.filter((_, index) => index !== indexToRemove)
    //   );

    //   if (images.length === 1) {
    //     fileInputRef.current.value = "";
    //   }
    // } else {
      const data = { id: item };
      destoryPartInventoryImage(data)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/parts-inventory");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    // }

    // if (idToRemove?.id) {
    //   setImage((prevFiles) =>
    //     prevFiles.filter((file) => file.id !== idToRemove.id)
    //   );

    //   const data = { id: idToRemove };
    //   destoryPartInventoryImage(data)
    //     .then((response) => {
    //       toast.success(response.data.message);
    //     })
    //     .catch((err) => {
    //       toast.error(Utils.getErrorMessage(err));
    //     });
    // }
  };

  const handleShowAbout = () => setShowModalAbout(true);
  const handleCloseAbout = () => setShowModalAbout(false);

  let errorsObj = {
    part_code: "",
    part_name: "",
    quantity: "",
    part_price: "",
    part_original_price: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    const isDuplicatePart = (part, newPart) => {
      return (
        part.part_code === newPart.part_code &&
        part.vehicle_make === newPart.vehicle_make &&
        part.vehicle_model === newPart.vehicle_model &&
        part.vehicle_year === newPart.vehicle_year
      );
    };

    if (vehicleAddDetails?.id) {
      const newPart = {
        catalog_id: vehicleAddDetails.catalog_id,
        namePartPT: vehicleAddDetails.namePartPT,
        part_code: vehicleAddDetails?.part_code,
        part_name: vehicleAddDetails?.part_name,
        quantity: vehicleAddDetails?.quantity,
        part_price: vehicleAddDetails?.part_price,
        part_original_price: vehicleAddDetails?.part_original_price,
        sponsor_price: vehicleAddDetails?.sponsor_price,
        part_description: vehicleAddDetails?.part_description,
        inventory_status: vehicleAddDetails?.inventory_status,
        parts_associate: vehicleAddDetails?.parts_associate,
        vehicle_make: vehicleAddDetails.vehicle_make,
        vehicle_model: vehicleAddDetails.vehicle_model,
        vehicle_model_id: vehicleAddDetails.vehicle_parts_id,
        vehicle_year: vehicleAddDetails.vehicle_year,
      };

      setPartsData((prevData) => {
        if (!prevData.some((part) => isDuplicatePart(part, newPart))) {
          return [...prevData, newPart];
        }
        return prevData;
      });
    } else if (selectedItems && selectedItems.length > 0) {
      const selectedParts = selectedItems?.map((item) => ({
        tempId: item.id,
        temp_id: item.temp_id,
        id: item.catalog_id,
        namePartPT: item.namePartPT,
        part_code: "",
        part_name: "",
        quantity: "",
        part_price: "",
        part_original_price: "",
        inventory_status: 0,
        sponsor_price: "",
        part_description: "",
        parts_associate: [],
        vehicle_make: item.vehicle_make,
        vehicle_model: item.vehicle_model,
        vehicle_model_id: item.vehicle_model_id,
        vehicle_year: item.vehicle_year,
      }));

      setPartsData(selectedParts);
    }
  }, [vehicleAddDetails, selectedItems]);

  const handleInputChangePartList = (e, id) => {
    const { name, value, type, checked } = e.target;
    setPartsData((prevData) =>
      prevData.map((part) =>
        part.id === id
          ? { ...part, [name]: type === "checkbox" ? (checked ? 1 : 0) : value }
          : part
      )
    );
  };

  const handleAddClick = (item) => {
    let data = {
      vehicle_make: vehiclePartsData?.vehicle_make
        ? vehiclePartsData?.vehicle_make
        : vehiclePartsDatasTemp?.vehicle_model_id?.vehicle_make,
      vehicle_year: vehiclePartsData?.vehicle_year
        ? vehiclePartsData?.vehicle_year
        : vehiclePartsDatasTemp?.vehicle_model_id?.vehicle_year,
      vehicle_model: vehiclePartsData?.vehicle_model
        ? vehiclePartsData?.vehicle_model
        : vehiclePartsDatasTemp?.vehicle_model_id?.vehicle_model,
      temp_id: tempData?.temp_id ? tempData?.temp_id : "",
      catalog_id: item,
    };

    actionPartInventoryTempStore(data)
      .then((response) => {
        // toast.success(response.data.message);
        setTempData(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleDeleteClick = (item) => {
    let data = {
      id: item.tempId ? item.tempId : item?.id,
    };

    setTempData(item);

    DestoryPartInventoryTemp(data)
      .then((response) => {
        toast.success(response.data.message);

        const fetchData = async () => {
          try {
            const obj = {
              search: searchQueryTemp,
              limit: tableLengthList[0],
              page: 1,
              temp_id: item?.temp_id,
            };
            const response = await getPartInventoryTempList(obj);
            setSelectedItems(response.data.data);
          } catch (err) {
            console.error("Error fetching part inventory:", err);
          }
        };

        fetchData();
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(() => {
    if (selectedItems?.length === 0) {
      setCurrentStep(1);
    }
  }, [selectedItems]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    // company_id: companyId.company_id || 0,
  });

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = "";
    } else if (type === "pagination") {
      paramsObj.page = e;
    }

    setParams(paramsObj);
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getVehicleList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: `${response.data[i].vehicle_make}/ ${response.data[i].vehicle_model} /${response.data[i].vehicle_year}`,
            vehicle_year: response.data[i].vehicle_year,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    var obj = {
      text: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getVehicleModelAllList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].vehicle_model,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_year: response.data[i].vehicle_end_year,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelAllList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);

    if (inputValue.length > 2) {
      const obj = {
        search: inputValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_model,
            });
          }

          setVehicleModelList(list);
        })
        .catch((err) => {
          console.error("Error fetching vehicle model list", err);
        });
    }
  };

  const handleInputSelectedChange = (inputSelctedValue) => {
    setInputSelctedValue(inputSelctedValue);

    if (inputSelctedValue.length > 2) {
      const obj = {
        text: inputSelctedValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleModelAllList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_model,
            });
          }
          setVehicleModelAllList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectChange = (selectedOption) => {
    setVehiclePartsData({
      ...vehiclePartsData,
      vehicle_model_id: selectedOption ? selectedOption.value : "",
      vehicle_make: selectedOption ? selectedOption.vehicle_make : "",
      vehicle_year: selectedOption ? selectedOption.vehicle_year : "",
      vehicle_model: selectedOption ? selectedOption.vehicle_model : "",
    });
  };

  const handleSelectWithoutChanges = (selectedOptions, id) => {
    const optionsArray = Array.isArray(selectedOptions) ? selectedOptions : [];

    setPartsData((prevData) =>
      prevData.map((part) =>
        part.id === id
          ? {
              ...part,
              parts_associate: optionsArray.map((option) => ({
                label: option.vehicle_model,
                vehicle_make: option.vehicle_make,
                vehicle_year: option.vehicle_year,
                vehicle_model: option.vehicle_model,
              })),
            }
          : part
      )
    );
  };

  useEffect(
    function () {
      let data = {
        limit: tableLengthList[0],
        page: 1,
        search: searchQuery,
        is_reload: false,
      };
      getCatalogList(data)
        .then((response) => {
          response = response.data;

          setCatalogList(response);
          setTotalRecords(response.total);
        })
        .catch((err) => {});
    },
    [searchQuery, params]
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchChangeTemp = (e) => {
    setSearchQueryTemp(e.target.value);
  };

  useEffect(() => {
    if (tempData?.temp_id) {
      const fetchData = async () => {
        try {
          const obj = {
            search: searchQueryTemp,
            limit: tableLengthList[0],
            page: 1,
            temp_id: tempData?.temp_id,
          };
          const response = await getPartInventoryTempList(obj);
          setSelectedItems(response.data.data);
        } catch (err) {
          console.error("Error fetching part inventory:", err);
        }
      };

      fetchData();
    }
  }, [searchQueryTemp, tempData]);

  const data = Number(vehiclePartsData.vehicle_model_id);

  const selectedModel = VehicleModelList.find((item) => item.value == data);

  const selectedWithoutModel = VehicleModelList.find(
    (item) => item.value == data
  );

  const createPayload = () => {
    return partsData.map((part) => ({
      vehicle_id: vehiclePartsData?.vehicle_model_id || 0,
      part_catelog: part.id || "",
      part_code: part?.part_code,
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      part_original_price: part?.part_original_price,
      inventory_status: part.inventory_status,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_year,
      vehicle_model: part?.vehicle_model,
      sponsor_price: part?.sponsor_price,
      part_description: part?.part_description,
      parts_associate: [],
    }));
  };

  const createPayloads = () => {
    const part = partsData[0];

    return {
      uuid: vehicleAddDetails?.uuid,
      id: vehicleAddDetails?.id,
      vehicle_id: vehicleAddDetails?.vehicle_id || 0,
      part_catelog: part.id || vehicleAddDetails?.part_catelog,
      part_code: part?.part_code,
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      part_original_price: part?.part_original_price,
      inventory_status: part.inventory_status,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_year,
      vehicle_model: part?.vehicle_model,
      sponsor_price: part?.sponsor_price,
      part_description: part?.part_description,
      parts_associate:
        part?.parts_associate?.map((model) => ({
          uuid: model?.uuid,
          vehicle_make: model.vehicle_make,
          vehicle_year: model.vehicle_year,
          vehicle_model: model.vehicle_model,
        })) || [],
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    partsData.forEach((part) => {
      if (!part.part_code) {
        errorObj.part_code = `Part Code is required.`;
        error = true;
      }

      if (!part.part_name) {
        errorObj.part_name = `Part Name is required.`;
        error = true;
      }

      if (!part.quantity) {
        errorObj.quantity = `Part Quantity is required.`;
        error = true;
      }

      if (!part.part_price) {
        errorObj.part_price = `Part Price is required.`;
        error = true;
      }

      if (!part.part_original_price) {
        errorObj.part_original_price = `Part Original Price is required.`;
        error = true;
      }
    });

    setErrors(errorObj);
    if (error) return;

    const payloads = createPayload();
    const payloadsUpdated = createPayloads();

    if (vehicleAddDetails?.id) {
      updatePartsInventory(payloadsUpdated)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          // navigate("/parts-inventory");
          setCurrentStep(2);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      let data = {
        temp_id: tempData?.temp_id,
        part_list: payloads,
      };
      createPartsInventory(data)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          setCurrentStep(3);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleNextPart = () => {
    setCurrentStep(2);
    setImageDetailsNot(vehicleAddDetails);
  };

  const handleNextClick = () => {
    if (!tempData?.temp_id || selectedItems.length === 0) {
      toast.error("Please select a vehicle part.");
    } else {
      setCurrentStep(2);
    }
  };
  const handleAddPart = (e, index) => {
    e.preventDefault();

    if (selectedVehicleModel) {
      let list = [...partsData];
      let partList = list[index]["parts_associate"];
      const isDuplicate = partList.some(
        (part) => part.value === selectedVehicleModel.value
      );
      console.log("isDuplicate", isDuplicate);
      let idx = partList.findIndex(
        (x) => x.value == selectedVehicleModel.value
      );
      if (idx === -1) {
        partList.push(selectedVehicleModel);
        list[index]["parts_associate"] = partList;
        setPartsData(list);
      } else {
        toast.error(selectedVehicleModel.label + ` is already added.`);
      }
    } else {
      console.warn("No vehicle model selected.");
    }
  };

  const handleSelectWithoutChange = (option) => {
    setSelectedVehicleModel(option);
  };
  const handleBackButtonClick = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    } else {
      navigate("/parts-inventory");
    }
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  {/* <a href="#">Home</a> */}
                </li>
                {/* <li className="breadcrumb-item active">Fuel</li> */}
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>

      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary card-primary-Vehicle">
            {vehicleAddDetails?.id ? (
              <>
                {currentStep === 1 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <Form method={`post`} onSubmit={handleSubmit}>
                          <div className="row">
                            {partsData?.map((part, pIndex) => {
                              return (
                                <React.Fragment key={pIndex}>
                                  <div className="col-lg-6" key={part.id}>
                                    <Card className="text-center">
                                      <Card.Header className="cardHeader">
                                        <div className="d-flex">
                                          <div className="">
                                            <input
                                              className="my-checkbox cstmCheckBox mt-4"
                                              type="checkbox"
                                              name="inventory_status"
                                              checked={
                                                part.inventory_status === 1
                                              }
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="AddPh6">
                                            <h6>
                                              {part.vehicle_make}/
                                              {part.vehicle_model}/
                                              {part.vehicle_year}
                                            </h6>
                                          </div>
                                          {/* <div className="">
                                        <button
                                          type=""
                                          className="btnClose"
                                          onClick={() =>
                                            handleDeleteClick(part)
                                          }
                                        >
                                          <i className="bi bi-x-lg"></i>
                                        </button>
                                      </div> */}
                                        </div>
                                      </Card.Header>
                                      <Card.Body className="cardBody">
                                        <div className="row">
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Code
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              name="part_code"
                                              value={part.part_code}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Code"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Name
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              name="part_name"
                                              value={part.part_name}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Name"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Quantity
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="quantity"
                                              value={part.quantity || "0"}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Quantity"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Price
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="part_price"
                                              value={part.part_price || "0.0"}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Price"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Original Price
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="part_original_price"
                                              value={part.part_original_price}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Sponsor Price
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="sponsor_price"
                                              value={part.sponsor_price}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                          </div>

                                          <div className="col-lg-12 addPartField">
                                            <label className="partsLabel">
                                              Part Description
                                            </label>
                                            <textarea
                                              className="form-control mt-2"
                                              type="text"
                                              name="part_description"
                                              value={part.part_description}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Description"
                                              rows={3}
                                            />
                                          </div>
                                          {vehicleAddDetails.vehicle_id ===
                                            null && (
                                            <>
                                              <div className="col-lg-12 d-flex">
                                                <div className="input-div SelectV selectA">
                                                  <Select
                                                    className="my-Select selectWidth"
                                                    options={
                                                      VehicleModelAllList
                                                    }
                                                    value={VehicleModelAllList.find(
                                                      (item) =>
                                                        item.value ==
                                                        selectedVehicleModel
                                                    )}
                                                    onChange={
                                                      handleSelectWithoutChange
                                                    }
                                                    placeholder={
                                                      "Select Vehicle Model"
                                                    }
                                                    isSearchable={true}
                                                    // onInputChange={handleInputSelectedChange}
                                                    inputSelctedValue={
                                                      inputSelctedValue
                                                    }
                                                  />
                                                </div>
                                                <button
                                                  style={{
                                                    float: "right",
                                                    marginTop: "10px",
                                                    marginRight: "23px",
                                                  }}
                                                  type="button"
                                                  className="addBtn ms-2"
                                                  onClick={(e) =>
                                                    handleAddPart(e, pIndex)
                                                  }
                                                >
                                                  <i className="bi bi-plus-lg"></i>
                                                </button>
                                              </div>
                                              <div className="col-lg-6">
                                                <Table className="rounded-table striped bordered hover mt-3">
                                                  <tbody>
                                                    {vehicleAddDetails
                                                      ?.parts_associate.length >
                                                      0 &&
                                                      part?.parts_associate?.map(
                                                        (row, index) => {
                                                          return (
                                                            <React.Fragment
                                                              key={index}
                                                            >
                                                              <tr>
                                                                <td className="lisatingTable mb-1">
                                                                  {
                                                                    row?.vehicle_model
                                                                  }
                                                                </td>
                                                              </tr>
                                                            </React.Fragment>
                                                          );
                                                        }
                                                      )}
                                                  </tbody>
                                                </Table>
                                              </div>
                                              <div className="col-lg-6"></div>
                                            </>
                                          )}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <button
                                type="button"
                                className="back-btn"
                                onClick={() => navigate("/parts-inventory")}
                              >
                                <i class="bi bi-arrow-left"></i> Back
                              </button>
                            </div>

                            <div className="col-lg-6 text-end">
                              <button
                                type="submit"
                                className="btn btn-primary mr-2"
                              >
                                Save & Next
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleNextPart}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <div className="row">
                          <>
                            <Form onSubmit={handleSubmits} className="row">
                              <React.Fragment>
                                <div className="col-lg-6">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>Part Information</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body
                                      className="cardBody"
                                      style={{ padding: "0" }}
                                    >
                                      <div class="container text-justify">
                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">Part Name</div>
                                          <div class="col">
                                            {currentPart?.part_name}
                                          </div>
                                        </div>
                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">
                                            Part Description
                                          </div>
                                          <div class="col">
                                            {currentPart?.part_description}
                                          </div>
                                        </div>

                                        <div class="row" style={{padding: "8px", borderBottom: "1px solid #eee"}}>
                                          <div class="col">Part Quantity</div>
                                          <div class="col">
                                            {currentPart?.quantity || 0}
                                          </div>
                                        </div>
                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">Part Price</div>
                                          <div class="col">
                                            {currentPart?.part_price || 0}
                                          </div>
                                        </div>

                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">
                                            Part Original Price
                                          </div>
                                          <div class="col">
                                            {currentPart?.part_original_price ||
                                              0}
                                          </div>
                                        </div>

                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">Sponsor Price </div>
                                          <div class="col">
                                            {currentPart?.sponsor_price || 0}
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>

                                <div className="col-lg-6">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>Upload Part Images</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body className="cardBody">
                                      <div className="row">
                                        <div className="col-lg-12 addPartField">
                                          <div className="col-lg-12 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Upload Images
                                              {/* {currentPart.part_name} */}
                                            </label>
                                            <div className="input-div">
                                              <div className="avatar-upload">
                                                <div className="avatar-edit">
                                                  <div className="input-div">
                                                    <input
                                                      ref={fileInputRef}
                                                      className="inputFile"
                                                      type="file"
                                                      id="imageuploadAbout"
                                                      onChange={
                                                        handleAboutFileChange
                                                      }
                                                      name="file"
                                                      accept=".jpg,.jpeg,.png"
                                                      multiple
                                                    />
                                                    {/* <i
                                                        style={{
                                                          position: "absolute",
                                                          top: "46px",
                                                          right: "12px",
                                                          fontSize: "20px",
                                                          color: "#81bb42",
                                                        }}
                                                        className="bi bi-eye"
                                                        onClick={
                                                          handleShowAbout
                                                        }
                                                      ></i> */}
                                                    <>
                                                      {vehicleImageDetails?.map(
                                                        (item, index) => {
                                                          return (
                                                            <div
                                                              key={index}
                                                              className="preview-item"
                                                            >
                                                              <img
                                                                src={
                                                                  item?.image_url
                                                                }
                                                                alt={`Preview ${index}`}
                                                                style={{
                                                                  width:
                                                                    "100px",
                                                                  margin: "5px",
                                                                }}
                                                              />
                                                              <div className="btn-Remove">
                                                                <i
                                                                  className="bi bi-x"
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleRemoveFile(
                                                                      index,
                                                                      item.id
                                                                    )
                                                                  }
                                                                ></i>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </>

                                                    {previewUrl.length > 0 &&
                                                    images.length > 0 ? (
                                                      <>
                                                        {images.map(
                                                          (
                                                            { file, id },
                                                            index
                                                          ) => (
                                                            <div className="partImages mt-2">
                                                              <div
                                                              key={id}
                                                              className="preview-item"
                                                            >
                                                              <div className="btn-Remove" style={{position: "absolute", width: "20px", height: "20px"}}>
                                                                <i
                                                                  className="bi bi-x"
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleRemoveFile(
                                                                      index
                                                                    )
                                                                  }
                                                                ></i>
                                                              </div>
                                                              <img
                                                                src={
                                                                  previewUrl[
                                                                    index
                                                                  ]
                                                                }
                                                                alt={`Preview ${index}`}
                                                                style={{
                                                                  width:
                                                                    "100px",
                                                                  margin: "5px",
                                                                  height: "100px",
                                                                  border: "1px solid",
                                                                  borderRadius: "5px",
                                                                  maxWidth: "100px",
                                                                  maxHeight: "100px"
                                                                }}
                                                              />
                                                            </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </>
                                                    ) : null}
                                                  </div>
                                                  <label htmlFor="imageuploadAbout"></label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </React.Fragment>
                              <div className="d-flex flex-row mt-3 justify-content-end">
                                {/* {!isLastPart && (
                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    onClick={handleOpenSkip}
                                  >
                                    Skip & Next
                                  </Button>
                                )}

                                {!isLastPart && (
                                  <Button className="me-2 mb-2 btn-primary" type="submit">
                                    Save & Next
                                  </Button>
                                )} */}

                                {/* {isLastPart && ( */}
                                <>
                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    onClick={handleFinish}
                                  >
                                    Save
                                  </Button>

                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    onClick={() => navigate("/parts-inventory")}
                                  >
                                    Finish
                                  </Button>
                                </>
                                {/* )} */}
                                {/* {isLastPart && (
                                  <Button
                                    className="me-2 mb-2"
                                    onClick={() => navigate("/parts-inventory")}
                                  >
                                    Close
                                  </Button>
                                )} */}
                              </div>
                            </Form>
                          </>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {currentStep === 1 && (
                  <>
                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="col-form-label ml-2">
                          {"Vehicle"}
                        </Label>
                      </FormGroup>
                    </div>

                    <div className="row">
                      <div className="col-md-8">
                        <div className="card-body pt-0 vehicleparts-table">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3  vehicleparts-input">
                                <input
                                  className="form-control input-search"
                                  type="text"
                                  value={searchQuery}
                                  onChange={handleSearchChange}
                                  maxLength={50}
                                  placeholder="Search"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-div">
                                <Select
                                  className="my-Select"
                                  options={VehicleModelList}
                                  value={selectedModel}
                                  onChange={handleSelectChange}
                                  placeholder={"Select Vehicle"}
                                  isSearchable={true}
                                  onInputChange={handleInputChange}
                                  inputValue={inputValue}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="vehicleparts-table-inner vehicleThBg">
                            <table className="rounded-table table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style={{ width: "10%" }}>Part Catalog</th>
                                  {vehiclePartsData?.vehicle_model_id && (
                                    <th style={{ width: "10%" }}>Vehicle</th>
                                  )}
                                  <th style={{ width: "10%" }}>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {CatalogList?.data?.length > 0 ? (
                                  CatalogList?.data?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.namePartPT}</td>
                                      {vehiclePartsData?.vehicle_model_id && (
                                        <td>{selectedModel?.label}</td>
                                      )}
                                      <td>
                                        <Button
                                          className="addBtn"
                                          onClick={() =>
                                            handleAddClick(item.id)
                                          }
                                          disabled={
                                            !vehiclePartsData?.vehicle_model_id
                                          }
                                        >
                                          <i class="bi bi-plus-lg"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="100%">No results found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card-body vehicleparts-table pt-0">
                          <div className="mb-3  vehicleparts-input">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={searchQueryTemp}
                              onChange={handleSearchChangeTemp}
                              maxLength={50}
                              placeholder="Search"
                            />
                          </div>
                          <div className="vehicleparts-table-inner vehicleThBg">
                            <table className="rounded-table table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style={{ width: "10%" }}>Part Catalog</th>
                                  <th style={{ width: "10%" }}>
                                    Vehicle Model
                                  </th>

                                  <th style={{ width: "10%" }}>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {selectedItems?.length > 0 ? (
                                  selectedItems?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.namePartPT}</td>

                                      <td>{item?.vehicle_model}</td>

                                      <td>
                                        <Button
                                          className="btn-danger-delete"
                                          onClick={() =>
                                            handleDeleteClick(item)
                                          }
                                        >
                                          <i class="bi bi-trash3"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="100%">No results found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col">
                        <button
                          type="button"
                          className="back-btn"
                          onClick={handleBackButtonClick}
                        >
                          <i class="bi bi-arrow-left"></i> Back
                        </button>
                      </div>
                      <div className="d-flex justify-content-end col">
                        <Button
                          className="next-button"
                          type="button"
                          onClick={handleNextClick}
                          // disabled={
                          //   !vehiclePartsData?.vehicle_make &&
                          //   !tempData?.temp_id
                          // }
                        >
                          Next<i class="bi bi-arrow-right"></i>
                        </Button>
                      </div>
                    </div>
                    <div className="fuelCF">
                      <div className="card-footer clearfix card-footer-part">
                        <div className="pagination pagination-sm m-0 float-right">
                          <Pagination
                            activePage={params.page}
                            itemsCountPerPage={parseInt(params.limit)}
                            totalItemsCount={parseInt(totalRecords)}
                            pageRangeDisplayed={5}
                            itemClass={`page-item`}
                            linkClass={`page-link`}
                            onChange={(e) => handleParams(e, "pagination")}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <Form method={`post`} onSubmit={handleSubmit}>
                          <div className="row">
                            {partsData?.map((part, pIndex) => {
                              return (
                                <React.Fragment key={pIndex}>
                                  <div className="col-lg-6" key={part.id}>
                                    <Card className="text-center">
                                      <Card.Header className="cardHeader">
                                        <div className="d-flex">
                                          <div className="">
                                            <input
                                              className="my-checkbox cstmCheckBox mt-4"
                                              type="checkbox"
                                              name="inventory_status"
                                              checked={
                                                part.inventory_status === 1
                                              }
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="AddPh6">
                                            <h6>
                                              {part.vehicle_make}/
                                              {part.vehicle_model}/
                                              {part.vehicle_year}
                                            </h6>
                                          </div>
                                          <div className="">
                                            <button
                                              type=""
                                              className="btnClose"
                                              onClick={() =>
                                                handleDeleteClick(part)
                                              }
                                            >
                                              <i className="bi bi-x-lg"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </Card.Header>
                                      <Card.Body className="cardBody">
                                        <div className="row">
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Part Code
                                            </label>
                                            <input
                                              className="form-control input-search"
                                              type="text"
                                              name="part_code"
                                              value={part.part_code}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Code"
                                            />
                                            {errors.part_code && (
                                              <span className="input-error">
                                                {errors.part_code}
                                              </span>
                                            )}
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Part Name
                                            </label>
                                            <input
                                              className="form-control input-search"
                                              type="text"
                                              name="part_name"
                                              value={part.part_name}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Name"
                                            />
                                            {errors.part_name && (
                                              <span className="input-error">
                                                {errors.part_name}
                                              </span>
                                            )}
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Quantity
                                            </label>
                                            <input
                                              className="form-control input-search"
                                              type="number"
                                              name="quantity"
                                              value={part.quantity}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                            {errors.quantity && (
                                              <span className="input-error">
                                                {errors.quantity}
                                              </span>
                                            )}
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Part Price
                                            </label>
                                            <input
                                              className="form-control input-search"
                                              type="number"
                                              name="part_price"
                                              value={part.part_price}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                            {errors.part_price && (
                                              <span className="input-error">
                                                {errors.part_price}
                                              </span>
                                            )}
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Part Original Price
                                            </label>
                                            <input
                                              className="form-control input-search"
                                              type="number"
                                              name="part_original_price"
                                              value={part.part_original_price}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                            {errors.part_original_price && (
                                              <span className="input-error">
                                                {errors.part_original_price}
                                              </span>
                                            )}
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Sponsor Price
                                            </label>
                                            <input
                                              className="form-control input-search"
                                              type="number"
                                              name="sponsor_price"
                                              value={part.sponsor_price}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                          </div>

                                          <div className="col-lg-12 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Part Descriptions
                                            </label>
                                            <textarea
                                              className="form-control input-search"
                                              type="text"
                                              name="part_description"
                                              value={part.part_description}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Description"
                                              rows={3}
                                            />
                                          </div>

                                          {/* <div className="col-lg-6 d-flex">
                                            <Select
                                              className="my-Select"
                                              options={VehicleModelAllList}
                                              value={VehicleModelAllList.find(
                                                (item) =>
                                                  item.value ==
                                                  selectedVehicleModel
                                              )}
                                              onChange={
                                                handleSelectWithoutChange
                                              }
                                              placeholder={
                                                "Select Vehicle Model"
                                              }
                                              isSearchable={true}
                                              // onInputChange={handleInputSelectedChange}
                                              inputSelctedValue={
                                                inputSelctedValue
                                              }
                                            />

                                            <button
                                              style={{ marginTop: "8px" }}
                                              type="button"
                                              className="addBtn ms-2"
                                              onClick={(e) =>
                                                handleAddPart(e, pIndex)
                                              }
                                            >
                                              <i className="bi bi-plus-lg"></i>
                                            </button>
                                          </div>
                                          <div className="col-lg-6"></div>
                                          <div className="col-lg-6">
                                            <Table
                                              className="rounded-table striped bordered hover mt-2 table mb-2"
                                              striped
                                              bordered
                                              hover
                                            >
                                              <tbody>
                                                {part?.parts_associate.length >
                                                  0 &&
                                                  part.parts_associate.map(
                                                    (row, index) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <tr>
                                                          <td className="lisatingTable mb-1">
                                                            {row.label}
                                                          </td>
                                                        </tr>
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </tbody>
                                            </Table>
                                          </div>
                                          <div className="col-lg-6"></div> */}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="row">
                            <div class="col">
                              <button
                                type="button"
                                class="next-button btn btn-secondary"
                                onClick={handleBackButtonClick}
                              >
                                <i class="bi bi-arrow-left"></i> Back
                              </button>
                            </div>

                            <div className="col text-end">
                              <button type="submit" className="btn btn-primary">
                                Save <i class="bi bi-save"></i>
                              </button>
                            </div>
                          </div>
                        </Form>
                        {/* <div className="card-footer clearfix card-footer-part">
                          <div className="pagination pagination-sm m-0 float-right">
                            <Pagination
                              activePage={params.page}
                              itemsCountPerPage={parseInt(params.limit)}
                              totalItemsCount={parseInt(totalRecords)}
                              pageRangeDisplayed={5}
                              itemClass={`page-item`}
                              linkClass={`page-link`}
                              onChange={(e) => handleParams(e, "pagination")}
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 3 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <div className="row">
                          <>
                            <Form onSubmit={handleSubmits} className="row">
                              <React.Fragment>
                                <div className="col-lg-6">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>Part Information</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body
                                      className="cardBody"
                                      style={{ padding: "0" }}
                                    >
                                      <div class="container text-justify">
                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">Part Name</div>
                                          <div class="col">
                                            {currentPart?.part_name}
                                          </div>
                                        </div>
                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">
                                            Part Description
                                          </div>
                                          <div class="col">
                                            {currentPart?.part_description}
                                          </div>
                                        </div>

                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">Part Quantity</div>
                                          <div class="col">
                                            {currentPart?.quantity}
                                          </div>
                                        </div>
                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">Part Price</div>
                                          <div class="col">
                                            {currentPart?.part_price}
                                          </div>
                                        </div>

                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">
                                            Part Original Price
                                          </div>
                                          <div class="col">
                                            {currentPart?.part_original_price}
                                          </div>
                                        </div>

                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">Sponsor Price </div>
                                          <div class="col">
                                            {currentPart?.sponsor_price}
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>

                                <div className="col-lg-6">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>Upload Part Image</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body className="cardBody">
                                      <div className="row">
                                        <div className="col-lg-12 addPartField">
                                          <div className="col-lg-12 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Upload Images
                                              {/* {currentPart.part_name} */}
                                            </label>
                                            <div className="input-div">
                                              <div className="avatar-upload">
                                                <div className="avatar-edit">
                                                  <div className="input-div">
                                                    <input
                                                      className="inputFile"
                                                      type="file"
                                                      id="imageuploadAbout"
                                                      onChange={
                                                        handleAboutFileChange
                                                      }
                                                      name="file"
                                                      accept=".jpg,.jpeg,.png"
                                                      multiple
                                                    />
                                                    {previewUrl && (
                                                      <i
                                                        style={{
                                                          position: "absolute",
                                                          top: "46px",
                                                          right: "12px",
                                                          fontSize: "20px",
                                                          color: "#81bb42",
                                                        }}
                                                        className="bi bi-eye"
                                                        onClick={
                                                          handleShowAbout
                                                        }
                                                      ></i>
                                                    )}
                                                  </div>
                                                  <label htmlFor="imageuploadAbout"></label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </React.Fragment>
                              <div className="d-flex flex-row mt-3 justify-content-end">
                                {!isLastPart && (
                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    onClick={handleOpenSkip}
                                  >
                                    Skip & Next
                                  </Button>
                                )}

                                {!isLastPart && (
                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    type="submit"
                                  >
                                    Save & Next
                                  </Button>
                                )}

                                {isLastPart && (
                                  <>
                                    <Button
                                      className="mb-2 next-button"
                                      onClick={handleFinish}
                                      style={{ marginRight: "10px" }}
                                    >
                                      Save
                                    </Button>

                                    <Button
                                      className="mb-2 next-button"
                                      onClick={() =>
                                        navigate("/parts-inventory")
                                      }
                                    >
                                      Finish
                                    </Button>
                                  </>
                                )}
                              </div>
                            </Form>
                          </>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Modal show={showModalAbout} onHide={handleCloseAbout}>
          <Modal.Header closeButton>
            <Modal.Title> Parts Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="preview-container">
              {images.map(({ file, id }, index) => (
                <div key={id} className="preview-item">
                  <img
                    src={previewUrl[index]}
                    alt={`Preview ${index}`}
                    style={{ width: "100px", margin: "5px" }}
                  />
                  <div className="btn-Remove">
                    <i
                      className="bi bi-x"
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleRemoveFile(index)}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAbout}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showSkip} onHide={handleCloseSkip}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            Are you sure you want to skip this part? You won't be able to upload
            images for this part.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseSkip}>
              Close
            </Button>
            <Button variant="secondary" onClick={handleSkip}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default VehiclePartsAdd;
