import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import {
  createVehicleImage,
  deleteVehicleImage,
} from "../../services/VehicleService";

const VehicleImage = ({ setActiveTab, vehicleProcessList, activeTab }) => {
  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [files, setFiles] = useState([]);

  const navigate = useNavigate();
  let vehicleId = localStorage.getItem("vehicleId");

  useEffect(() => {
    if (
      Array.isArray(vehicleProcessList?.vehicle_image) &&
      activeTab === "vehicle_images"
    ) {
      const initialFiles = vehicleProcessList?.vehicle_image?.map(
        (document) => ({
          url: document.vehicle_image,
          id: document.id,
        })
      );

      setFiles(initialFiles);
    }
  }, [vehicleProcessList, activeTab]);

  const handleImportModal = () => {
    setModifyImportModal(true);
  };

  const handleImportCloseModal = () => {
    setModifyImportModal(false);
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [
      ...prevFiles,
      ...newFiles.map((file) => ({ url: URL.createObjectURL(file), file })),
    ]);
    setModifyImportModal(false);
  };

  // const handleRemoveFile = () => {
  //   setFiles((prevFiles) => prevFiles.filter((file) => file.id !== idToRemove));

  //   // const data = { id: idToRemove.id };

  //   // deleteVehicleImage(data)
  //   //   .then((response) => {
  //   //     toast.success(response.data.message);
  //   //     setActiveTab("vehicle_documents");
  //   //   })
  //   //   .catch((err) => {
  //   //     toast.error(Utils.getErrorMessage(err));
  //   //   });
  // };

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));

    if (fileToRemove?.id) {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== fileToRemove.id)
      );

      const data = { id: fileToRemove.id };

      deleteVehicleImage(data)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_images");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleVehicleImageForm = (e) => {
    e.preventDefault();
    const hasFiles = files.some((file) => file.file);

    if (hasFiles) {
      const formData = new FormData();
      files.forEach((file, index) => {
        if (file.file) {
          formData.append(`files[${index}]`, file.file);
        }
      });
      formData.append("vehicle_id", vehicleId);

      createVehicleImage(formData)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_documents");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      setActiveTab("vehicle_documents");
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method="post" onSubmit={handleVehicleImageForm}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 ms-3">
                    <button
                      type="button"
                      onClick={() =>
                        document.getElementById("file-input").click()
                      } // Trigger file input click
                      className={`btn btn-primary mb-2 ${files.length === 0 ? "import-button-empty" : ""
                        }`}
                    >
                      <i class="bi bi-cloud-arrow-up"></i> Upload Multiple Images
                    </button>
                    <input
                      id="file-input"
                      className="form-control"
                      type="file"
                      onChange={handleFileChange}
                      name="file"
                      accept=".jpg,.png"
                      multiple
                      style={{ display: "none" }} // Hide the input
                    />
                    <div className="file-preview file-preview-custom">
                      <div className="file-list">
                        {files.length === 0 ? (
                          <div className="file-item large-image">
                            <div
                              className="preview-images-size"
                              style={{ height: "254px" }}
                            >
                              <div className="card" style={{ width: "340px" }}>
                                <div className="card-body">
                                  <div className="">
                                    {/* <i
                                      className="bi bi-x"
                                      style={{
                                        color: "red",
                                        display: "flex",
                                        justifyContent: "end",
                                      }}
                                    ></i> */}
                                    <div className="btn-removeImg">
                                      <div className="btn-Remove">
                                        <i
                                          className="bi bi-x"
                                          style={{
                                            color: "red",
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "48px",
                                      }}
                                    >
                                      No image Selected
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          files?.map((file, index) => (
                            <div
                              key={index}
                              className={`file-item ${index === 0 ? "large-image" : "small-image"
                                }`}
                            >
                              <img
                                src={file.url}
                                alt="preview"
                                className={`preview-images ${index === 0 ? "large-images" : "small-images"
                                  }`}
                              />
                              <div
                                className={`preview-images-size ${index === 0
                                    ? "large-images-size"
                                    : "small-images-size"
                                  }`}
                                style={{ position: "absolute", height: "auto", marginTop: "0", top: "5", zIndex: "9" }}
                              >
                                <div className="btn-Remove">
                                  <i
                                    className="bi bi-x"
                                    style={{ color: "red" }}
                                    onClick={() => handleRemoveFile(file)}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">

                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      className="back-btn"
                      onClick={() => setActiveTab("vehicle")}
                    >
                      <i class="bi bi-arrow-left"></i> Back
                    </button>
                  </div>
                  <div className="col">
                    <div className="d-flex justify-content-end nxt-btn">
                      <button type="submit" className="btn btn-primary">
                        Next <i class="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Modal isOpen={modifyImportModal}>
        <Form method="post">
          <ModalHeader>Upload Multiple Images</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label">Select File</Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="file"
                        onChange={handleFileChange}
                        name="file"
                        accept=".jpg,.png"
                        multiple
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleImportCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default VehicleImage;
