import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  createVehicleCategory,
  deleteVehicleCategory,
  getVehicleCategoryList,
} from "../../services/VehicleCategory";
import { useLocation } from "react-router-dom";
const VehicleCategory = () => {

  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId?.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delCategory, setDelCategory] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [categoryData, setCategoryData] = useState({
    uuid: "",
    name: "",
  });

  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const onEditVehicleCategory = (e, item) => {
    setModifyModal(true);
    setCategoryData({
      uuid: item?.uuid,
      name: item?.category,
    });
  };

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setCategoryData("");
    setErrors("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchCategory = () => {
    let data = { ...params };
    getVehicleCategoryList(data)
      .then((response) => {
        setCategoryList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchCategory(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelCategory(item.uuid);
    setDelModal(true);
  };

  const onDestroyCategory = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delCategory };
    deleteVehicleCategory(data)
      .then((response) => {
        let data = { ...params };

        fetchCategory(data);
        toast.success(response.data.message);
        setDelCategory("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleCatgeoryForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!categoryData.name) {
      errorObj.name = `Category is required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      name: categoryData.name,
    };

    createVehicleCategory(data)
      .then((response) => {
        toast.success(response.data.message);
        let data = { ...params };
        fetchCategory(data);
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      {isLoading ? (
        // <div className="text-center pageLoader">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
          <div className="card">
            <div className="card-header">
              <table className="table rounded-table table-striped table-hover">
                <thead>
                  <tr>
                    <th style={{ width: 10, borderTop: "0", borderBottom: "0" }}><div className="skeleton" ></div></th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}><div className="skeleton"></div></th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                    <th class="d-flex" style={{ borderTop: "0", borderBottom: "0" }}><div class="skeleton me-1"></div>
                      <div class="skeleton me-1"></div>
                      <div class="skeleton"></div>
                    </th>
                    <th style={{ width: 10, borderTop: "0", borderBottom: "0" }}><div className="skeleton"></div></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-body">
              <div className="vehicleThBg table-responsive">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryList?.map((_, index) => (
                      <tr key={index}>
                        <td><div className="skeleton"></div></td>
                        <td><div className="skeleton"></div></td>
                        <td><div className="skeleton"></div></td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="skeleton col-lg-3 float-right"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                {/* <div className="col-sm-6">
                <h1 className="page-title">Vehicle Category</h1>
              </div> */}

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      {/* <a href="/dashboard">Home</a> */}
                    </li>
                    {/* <li className="breadcrumb-item active">Category</li> */}
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary-Vehicle fuelCard">
                    <div className="card-header">
                      <div className="card-title m-0 float-left">
                        <input
                          type="text"
                          className="form-control input-search"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>
                      </div>
                      <div className="card-title m-0 float-right">
                        {RolePermission?.findIndex((e) => e.permission_name === "add_vehicle_category") > -1 && (
                          <button
                            type="button"
                            className="btn mr-2 btn-icon-text"
                            onClick={handleAddModal}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">Add</span>
                          </button>
                        )}

                      </div>
                    </div>
                    <div className="card-body part-card mt-3">
                      <div className="vehicleThBg table-responsive cstm-height">
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: 10 }}
                              >
                                #
                              </th>
                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                Category
                              </th>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: 10 }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {categoryList?.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{item.category}</td>
                                    <td>

                                      <td className="d-flex">
                                        {(item.company_id > 0 || companyId?.role_id == 1) && RolePermission?.findIndex((e) => e.permission_name === "modify_vehicle_category") > -1 && (
                                          <a
                                            className="edit-btn"
                                            href="#"
                                            onClick={(e) =>
                                              onEditVehicleCategory(e, item)
                                            }
                                          >
                                            <i className="bi bi-pencil"></i>
                                          </a>
                                        )}
                                        {(item.company_id > 0 || companyId?.role_id == 1) && RolePermission?.findIndex((e) => e.permission_name === "delete_vehicle_category") > -1 && (
                                          <div className="trash-btn">
                                            <a
                                              className="delete-btn"
                                              href="#"
                                              onClick={(e) =>
                                                onDeleteModalOpen(e, item)
                                              }
                                            >
                                              <i className="bi bi-trash3"></i>
                                            </a>
                                          </div>
                                        )}
                                      </td>

                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          {categoryList && categoryList?.length === 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className={`text-center`}
                              >{`Record Not Found`}</td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}


                  </div>
                </div>
              </div>
            </div>
            <div className="fuelCF">
              <div className="card-footer clearfix card-footer-part">
                <div className="pagination pagination-sm m-0 float-right">
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle Category`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle category?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyCategory}
            >
              Delete
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Vehicle Category</ModalHeader>
        <Form method={`post`} onSubmit={handleCatgeoryForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Category"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={categoryData.name}
                        onChange={(e) => {
                          setCategoryData({
                            ...categoryData,
                            name: e.target.value,
                          });
                        }}
                        name={`name`}
                        maxLength={50}
                        placeholder="Category"
                      />
                    </div>
                    {errors.name && (
                      <span className="input-error">{errors.name}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default VehicleCategory;
