import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import engMessage from "../lang/en.json";
import freMessage from "../lang/fr.json";
import ConfigDB from "../config";
import Utils from "./index";

export const PrivateRoute = () => {
  let auth = useSelector((x) => x.auth.value);
  if (typeof auth != "object") {
    auth = JSON.parse(auth);
  }
  if (!auth?.access_token) {
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
};
export const PublicRoute = () => {
  const location = useLocation();
  const record = Utils.getRecordForCurrentPath(location);
 
  let auth = useSelector((x) => x.auth.value);
  if (typeof auth != "object") {
    auth = JSON.parse(auth);
  }
  if (auth?.access_token && record?.length > 0 && record[0]?.view === 1) {
    return <Navigate to="/dashboard" />;
  } else {
    return <Outlet />;
  }
};
