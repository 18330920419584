import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { getVehicleAllProcessList } from "../../services/VehicleService";
import { useLocation, useNavigate } from "react-router-dom";

const VehicleProcess = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const navigate = useNavigate();
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [vehicleProcessList, setVehicleProcessList] = useState([]);
  const [registration, setRegistration] = useState("");
  const [model, setModel] = useState("");
  const [year, SetYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = "";
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    paramsObj.vehicle_model = model;
    paramsObj.vehicle_year = year;
    paramsObj.registration = registration;
    setParams(paramsObj);
  };

  const fetchLerCatLog = () => {
    let data = { ...params };
    setIsLoading(true);
    getVehicleAllProcessList(data)
      .then((response) => {
        setVehicleProcessList(response.data.data);
        setTotalRecords(response.data.total);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsLoading(false);
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchLerCatLog(data);
    },
    [params]
  );

  const handleVehicle = () => {
    localStorage.removeItem("ownerList");
    localStorage.removeItem("vehicleId");

    navigate("/vehicle");
  };

  return (
    <div>
      {isLoading ? (
        // <div className="text-center pageLoader">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
          <div className="card">
            <div className="card-header">
              <table className="table rounded-table table-striped table-hover">
                <thead>
                  <tr>
                    <th style={{ width: 10, borderTop: "0", borderBottom: "0" }}><div className="skeleton" ></div></th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}><div className="skeleton"></div></th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                    <th class="d-flex" style={{ borderTop: "0", borderBottom: "0" }}><div class="skeleton me-1"></div>
                      <div class="skeleton me-1"></div>
                      <div class="skeleton"></div>
                    </th>
                    <th style={{ width: 10, borderTop: "0", borderBottom: "0" }}><div className="skeleton"></div></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-body">
              <div className="vehicleThBg table-responsive cstm-height">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleProcessList?.map((_, index) => (
                      <tr key={index}>
                        <td><div className="skeleton"></div></td>
                        <td><div className="skeleton"></div></td>
                        <td><div className="skeleton"></div></td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="skeleton col-lg-3 float-right"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1>Vehicle</h1> */}
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="card-header">
                      <div className="card-title m-0 float-left">
                        {/*<input*/}
                        {/*type="text"*/}
                        {/*className="form-control"*/}
                        {/*value={params.search}*/}
                        {/*onChange={(e) => handleParams(e, "search")}*/}
                        {/*placeholder="Search"*/}
                        {/*></input>*/}
                      </div>
                      {/* {RolePermission[0]?.add === 1 && ( */}
                      <div className="card-title m-0 float-right">
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_vehicle"
                        ) > -1 && (
                            <button
                              type="button"
                              className="btn  btn-icon-text mr-2"
                              onClick={handleVehicle}
                            >
                              <i className="bi bi-plus-lg"></i>
                              <span className="ms-2">Add</span>
                            </button>
                          )}
                      </div>
                      {/* )} */}
                    </div>
                    <div className="card-body">
                      <div className="vehicleThBg table-responsive" style={{height: "calc(76vh - 50px)"}}>
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "10" }}>#</th>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Registration No.{" "}
                                <input
                                  className="form-control mt-2 input-search"
                                  type="text"
                                  value={registration}
                                  onChange={(e) => {
                                    setRegistration(e.target.value);
                                  }}
                                  maxLength={50}
                                  placeholder="Registration No."
                                />
                              </th>
                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                Model{" "}
                                <input
                                  className="form-control mt-2 input-search"
                                  type="text"
                                  value={model}
                                  onChange={(e) => {
                                    setModel(e.target.value);
                                  }}
                                  maxLength={50}
                                  placeholder="Model"
                                />
                              </th>
                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                {" "}
                                Year
                                <input
                                  className="form-control mt-2 input-search"
                                  type="text"
                                  value={year}
                                  onChange={(e) => {
                                    SetYear(e.target.value);
                                  }}
                                  maxLength={50}
                                  placeholder="year"
                                />
                              </th>
                              <th
                                style={{ width: "10%" }}
                                className="col-form-label ml-2 Label-my form-label form-label"
                              >
                                Action{" "}
                                <button
                                  type="button"
                                  className="btn  btn-primary mt-2"
                                  onClick={(e) => handleParams(e, "search")}
                                >
                                  <i className="bi bi-search"></i>
                                  <span className="ms-2">Search</span>
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {vehicleProcessList?.map((item, index) => {
                              return (
                                <>
                                  <tr key={item.id}>
                                    <td>{++index}</td>
                                    <td>{item.registration || "-"}</td>
                                    <td>{item.vehicle_model || "-"}</td>
                                    <td>{item.vehicle_year || "-"}</td>
                                    <td>
                                      {RolePermission?.findIndex(
                                        (e) =>
                                          e.permission_name === "modify_vehicle"
                                      ) > -1 && (
                                          <a
                                            className="edit-btn"
                                            href="#"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (item) {
                                                navigate(`/vehicle/${item.uuid}`, {
                                                  state: item,
                                                });
                                              }
                                            }}
                                          >
                                            <i className="bi bi-pencil"></i>
                                          </a>
                                        )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          {vehicleProcessList &&
                            vehicleProcessList?.length === 0 && (
                              <tr>
                                <td
                                  colSpan={7}
                                  className={`text-center`}
                                >{`Record Not Found`}</td>
                              </tr>
                            )}
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer clearfix card-footer-part fuelCF">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default VehicleProcess;
