import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import { createPartCatalog } from "../../services/LerCatalogService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import { createVehicleOwner } from "../../services/VehicleService";
import validator from "validator";

const VehicleOwner = ({ setActiveTab, vehicleProcessList }) => {
  const navigate = useNavigate();
  const [vehicleOwnerData, setVehicleOwnerData] = useState({
    uuid: "",
    owner_name: "",
    certificate_number: "",
    owner_address: "",
    owner_postal_code: "",
    owner_country: "",
    owner_nif: "",
    owner_bicc: "",
    owner_telephone: "",
    owner_email: "",
    owner_code_cent_permanent: "",
    validity: "",
    owner_cellphone: "",
  });

  let errorsObj = {
    owner_name: "",
    certificate_number: "",
    owner_address: "",
    owner_postal_code: "",
    owner_country: "",
    owner_nif: "",
    owner_bicc: "",
    owner_telephone: "",
    owner_email: "",
    owner_code_cent_permanent: "",
    validity: "",
    owner_cellphone: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    if (vehicleProcessList) {
      setVehicleOwnerData({
        owner_name: vehicleProcessList?.owner_name,
        certificate_number: vehicleProcessList?.certificate_number,
        owner_address: vehicleProcessList?.owner_address,
        owner_postal_code: vehicleProcessList?.owner_postal_code,
        owner_country: vehicleProcessList?.owner_country,
        owner_nif: vehicleProcessList?.owner_nif,
        owner_bicc: vehicleProcessList?.owner_bicc,
        owner_telephone: vehicleProcessList?.owner_telephone,
        owner_email: vehicleProcessList?.owner_email,
        owner_code_cent_permanent:
          vehicleProcessList?.owner_code_cent_permanent,
        validity: vehicleProcessList?.validity,
        owner_cellphone: vehicleProcessList?.owner_cellphone,
      });
    }
  }, [vehicleProcessList]);

  const handleVehicleOwnerForm = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    if (!vehicleOwnerData.owner_name) {
      errorObj.owner_name = `Name is required.`;
      error = true;
    }

    if (!vehicleOwnerData.owner_address) {
      errorObj.owner_address = `Address is required.`;
      error = true;
    }
    if (!vehicleOwnerData.owner_country) {
      errorObj.owner_country = `Country is required.`;
      error = true;
    }
    if (!vehicleOwnerData.owner_nif) {
      errorObj.owner_nif = `NIF is required.`;
      error = true;
    }
    if (!vehicleOwnerData.owner_bicc) {
      errorObj.owner_bicc = `BICC is required.`;
      error = true;
    }

    if (!vehicleOwnerData.owner_email) {
      errorObj.owner_email = "Email address is required";
      error = true;
    } else if (!validator.isEmail(vehicleOwnerData.owner_email)) {
      errorObj.owner_email = "Please enter valid email address";
      error = true;
    }

    if (!vehicleOwnerData.validity) {
      errorObj.validity = `Validity is required.`;
      error = true;
    }

    if (
      !vehicleOwnerData.certificate_number ||
      isNaN(vehicleOwnerData.certificate_number)
    ) {
      errorObj.certificate_number =
        "Certificate Number is required and must be a number.";
      error = true;
    }

    if (
      !vehicleOwnerData.owner_postal_code ||
      isNaN(vehicleOwnerData.owner_postal_code)
    ) {
      errorObj.owner_postal_code =
        "Postal Code is required and must be a number.";
      error = true;
    }
    if (
      !vehicleOwnerData.owner_telephone ||
      isNaN(vehicleOwnerData.owner_telephone)
    ) {
      errorObj.owner_telephone = "Telephone is required and must be a number.";
      error = true;
    }

    if (
      !vehicleOwnerData.owner_cellphone ||
      isNaN(vehicleOwnerData.owner_cellphone)
    ) {
      errorObj.owner_cellphone = "Cellphone is required and must be a number.";
      error = true;
    }
    if (
      !vehicleOwnerData.owner_code_cent_permanent ||
      isNaN(vehicleOwnerData.owner_code_cent_permanent)
    ) {
      errorObj.owner_code_cent_permanent =
        "Code cent permanent is required and must be a number.";
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();
    
    if (error) return;
    let data = {
      owner_name: vehicleOwnerData.owner_name,
      certificate_number: vehicleOwnerData.certificate_number,
      owner_address: vehicleOwnerData.owner_address,
      owner_postal_code: vehicleOwnerData.owner_postal_code,
      owner_country: vehicleOwnerData.owner_country,
      owner_nif: vehicleOwnerData.owner_nif,
      owner_bicc: vehicleOwnerData.owner_bicc,
      owner_telephone: vehicleOwnerData.owner_telephone,
      owner_email: vehicleOwnerData.owner_email,
      owner_code_cent_permanent: vehicleOwnerData.owner_code_cent_permanent,
      validity: vehicleOwnerData.validity,
      owner_cellphone: vehicleOwnerData.owner_cellphone,
      ...(vehicleProcessList ? { uuid: vehicleProcessList.uuid } : {}),
    };

    createVehicleOwner(data)
      .then((response) => {
        toast.success(response.data.message);
        setActiveTab("vehicle");
        localStorage.setItem("ownerList", response.data.data.id);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleVehicleOwnerForm}>
              <div className="vehicle-owner-form">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Name"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleOwnerData.owner_name}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  owner_name: e.target.value,
                                });
                              }}
                              name={`owner_name`}
                              maxLength={50}
                              placeholder="Name"
                            />
                          </div>
                          {errors.owner_name && (
                            <span className="input-error">
                              {errors.owner_name}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Certificate Number"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleOwnerData.certificate_number}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  certificate_number: e.target.value,
                                });
                              }}
                              name={`certificate_number`}
                              maxLength={15}
                              placeholder="Certificate Number"
                            />
                          </div>
                          {errors.certificate_number && (
                            <span className="input-error">
                              {errors.certificate_number}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"CellPhone"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleOwnerData.owner_cellphone}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  owner_cellphone: e.target.value,
                                });
                              }}
                              name={`owner_cellphone`}
                              maxLength={9}
                              placeholder="CellPhone"
                            />
                          </div>
                          {errors.owner_cellphone && (
                            <span className="input-error">
                              {errors.owner_cellphone}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Postal Code"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleOwnerData.owner_postal_code}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  owner_postal_code: e.target.value,
                                });
                              }}
                              name={`owner_postal_code`}
                              maxLength={6}
                              placeholder="Postal Code"
                            />
                          </div>
                          {errors.owner_postal_code && (
                            <span className="input-error">
                              {errors.owner_postal_code}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Country"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleOwnerData.owner_country}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  owner_country: e.target.value,
                                });
                              }}
                              name={`owner_country`}
                              maxLength={100}
                              placeholder="Country"
                            />
                          </div>
                          {errors.owner_country && (
                            <span className="input-error">
                              {errors.owner_country}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"NIF"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleOwnerData.owner_nif}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  owner_nif: e.target.value,
                                });
                              }}
                              name={`owner_nif`}
                              maxLength={100}
                              placeholder="NIF"
                            />
                          </div>
                          {errors.owner_nif && (
                            <span className="input-error">
                              {errors.owner_nif}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"BICC"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleOwnerData.owner_bicc}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  owner_bicc: e.target.value,
                                });
                              }}
                              name={`owner_bicc`}
                              maxLength={100}
                              placeholder="BICC"
                            />
                          </div>
                          {errors.owner_bicc && (
                            <span className="input-error">
                              {errors.owner_bicc}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Telephone"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleOwnerData.owner_telephone}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  owner_telephone: e.target.value,
                                });
                              }}
                              name={`owner_telephone`}
                              maxLength={9}
                              placeholder="Telephone"
                            />
                          </div>
                          {errors.owner_telephone && (
                            <span className="input-error">
                              {errors.owner_telephone}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Email"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleOwnerData.owner_email}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  owner_email: e.target.value,
                                });
                              }}
                              name={`owner_email`}
                              maxLength={30}
                              placeholder="Email"
                            />
                          </div>
                          {errors.owner_email && (
                            <span className="input-error">
                              {errors.owner_email}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Code Cent Permanent"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleOwnerData.owner_code_cent_permanent}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  owner_code_cent_permanent: e.target.value,
                                });
                              }}
                              name={`owner_code_cent_permanent`}
                              maxLength={50}
                              placeholder="Code Cent Permanent"
                            />
                          </div>
                          {errors.owner_code_cent_permanent && (
                            <span className="input-error">
                              {errors.owner_code_cent_permanent}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Validity"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleOwnerData.validity}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  validity: e.target.value,
                                });
                              }}
                              name={`validity`}
                              maxLength={100}
                              placeholder="Validity"
                            />
                          </div>
                          {errors.validity && (
                            <span className="input-error">
                              {errors.validity}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3"></div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <FormGroup>
                        <Label className="col-form-label">{"Address"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <textarea
                              className="form-control"
                              type="text"
                              value={vehicleOwnerData.owner_address}
                              onChange={(e) => {
                                setVehicleOwnerData({
                                  ...vehicleOwnerData,
                                  owner_address: e.target.value,
                                });
                              }}
                              name={`owner_address`}
                              maxLength={100}
                              rows={3}
                              placeholder="Address"
                            />
                          </div>
                          {errors.owner_address && (
                            <span className="input-error">
                              {errors.owner_address}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        type="button"
                        className="back-btn"
                        onClick={() => navigate("/vehicle-process")}
                      >
                        <i class="bi bi-arrow-left"></i> Cancel
                      </button>
                    </div>
                    <div className="col">
                      <div className="d-flex justify-content-end nxt-btn">
                        <button type="submit" className="btn btn-primary">
                          Next <i class="bi bi-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleOwner;
