import axiosInstance from "./AxiosInstance";

export function getVehicleModelList(data) {
  return axiosInstance.post(`/vehicle-model/list`, data);
}

export function deleteVehicleModel(data) {
  return axiosInstance.post(`/vehicle-model/delete`, data);
}

export function createVehicleModel(data) {
  return axiosInstance.post(`/vehicle-model/create`, data);
}

export function updateVehicleModel(data) {
  return axiosInstance.post(`/vehicle-model/update`, data);
}

