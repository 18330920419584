import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../view/partials/Header";
import Sidebar from "../../view/partials/Sidebar";
import "../../asset/style/dashboard.css";


const DashboardLayout = () => {
  const [isOpenS, setIsOpens] = useState(true);

  return (
    <>
      <div className="sidebar-mini layout-fixed">
        <Header setIsOpens={setIsOpens} isOpenS={isOpenS}/>
        <div className="app-main">
          <div className="app-sidebar">
            <Sidebar setIsOpens={setIsOpens} isOpenS={isOpenS}/>
          </div>
          <div className="content-wrapper" style={{
          marginLeft: isOpenS ? "250px" : "60px", 
          transition: "margin-left 0.3s ease",
        }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
