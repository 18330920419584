import React, {useState} from 'react';
import { toast, useToast } from "react-toastify";
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useDispatch } from "react-redux";
import validator from "validator";
import {login} from "../../services/AuthServices";
import Utils from "../../utils";
import {authActions, subscriptionActions} from "../../store";
import ConfigDB from "../../config";

const preventRefresh = (e) => {
    e.preventDefault();
};

const Login = () => {
    let errorsObj = {email: "", password: ""};
    const [errors, setErrors] = useState(errorsObj);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const onLogin = (e) => {
        e.preventDefault();
        let errorObj = {...errorsObj};
        let error = false;

        if (!email) {
            errorObj.email = "Email address is required";
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = "Please enter valid email address";
            error = true;
        }

        if (!password) {
            errorObj.password = "Password is required";
            error = true;
        }

        setErrors(errorObj);
    Utils.focusError();

        if (error) return;

        let obj = {
            email: email,
            password: password,
        };
        setIsLoading(true);
        login(obj)
            .then((response) => {
                let userData = response?.data?.data;
                if (userData) {
                    Utils.setDetail(userData);
                    dispatch(authActions.login(userData));
                   
                        navigate("/dashboard");
                    
                }
            })
            .catch((error) => {console.log(Utils.getErrorMessage(error));
                toast.error(Utils.getErrorMessage(error));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="signUp">
            <div className="wrapper">
                <div className="form">
                    <div className="heading">LOGIN</div>
                    <form method={`post`} onSubmit={onLogin}>
                        <div className='padding-bottom--24'>
                            <label htmlFor="email" className='label-my'>E-Mail</label>
                            <input className='form-control input-my' type="text" id="email"
                                   placeholder="Enter your email" value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                            {errors.email && <span className="input-error">{errors.email}</span>}
                        </div>
                        <div className='padding-bottom--24'>
                            <label htmlFor="password" className='label-my'>Password</label>
                            <input className='form-control input-my' type="password" id="password"
                                   placeholder="Enter you password" value={password}
                                   onChange={(e) => setPassword(e.target.value)}/>
                            {errors.password && (
                                <span className="input-error">{errors.password}</span>
                            )}
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button className='login-btn' type="submit" disabled={isLoading}>
                                <span className="text">{isLoading ? "Logging in..." : "Login"}</span>
                                <span className="icon"><i className="bi bi-arrow-right-short"></i></span>
                            </button>
                        </div>
                    </form>
                    <div className='d-flex justify-content-center'>
                        <p>Don't have an account ? <Link to={"/signup"}> Sign Up </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;