import axiosInstance from "./AxiosInstance";

export function createUser(data) {
  return axiosInstance.post(`/add-user`, data);
}
export function updateUser(data) {
    return axiosInstance.post(`/update-user`, data);
}

export function getUserList(data) {
  return axiosInstance.post(`/user-list`, data);
}

export function DestoryUser(data) {
  return axiosInstance.post(`/user-delete`, data);
}

export function getRoleList(data) {
    return axiosInstance.post(`/user/role-list`, data);
}
