import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import {
  getPartGroupAllList,
  getPartLocationAllList,
  getPartSideAllList,
  getPartTypeAllList,
} from "../../services/PartTypeService";
import Utils from "../../utils";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { createPartCatalog } from "../../services/LerCatalogService";
import { Modal, Button } from "react-bootstrap";
import {
  actionSettingAdd,
  getSettingsDetails,
} from "../../services/settingsServices";

const Settings = () => {
  const navigate = useNavigate();
  const [settingData, setSettingData] = useState({
    uuid: "",
    team_size: "",
    about_description: "",
    contact_description: "",
    address: "",
    email: "",
    experience: "",
    mobile: "",
    facebook_url: "",
    instagram_url: "",
    youtube_url: "",
    whatsapp_url: "",
    linkedin_url: "",
    google_play_url: "",
    app_store_url: "",
    footer_description: "",
    our_services_description: "",
    part_image_limit: "",
  });

  const state = useLocation();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [previewUrlAbout, setPreviewUrlAbout] = useState(null);
  const [AboutImage, setAboutImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalAbout, setShowModalAbout] = useState(false);
  const [settingDetails, setSettingDetails] = useState("");

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleShowAbout = () => setShowModalAbout(true);
  const handleCloseAbout = () => setShowModalAbout(false);

  useEffect(() => {
    if (settingDetails) {
      setSettingData({
        team_size: settingDetails?.team_size,
        about_description: settingDetails?.about_description,
        contact_description: settingDetails?.contact_description,
        address: settingDetails?.address,
        email: settingDetails?.email,
        mobile: settingDetails?.mobile,
        facebook_url: settingDetails?.facebook_url,
        instagram_url: settingDetails?.instagram_url,
        youtube_url: settingDetails?.youtube_url,
        whatsapp_url: settingDetails?.whatsapp_url,
        linkedin_url: settingDetails?.linkedin_url,
        google_play_url: settingDetails?.google_play_url,
        app_store_url: settingDetails?.app_store_url,
        footer_description: settingDetails?.footer_description,
        experience: settingDetails?.experience,
        our_services_description: settingDetails?.our_services_description,
        part_image_limit: settingDetails?.part_image_limit,
      });
      setPreviewUrl(settingDetails?.header_image);
      setPreviewUrlAbout(settingDetails?.about_image);
      setImage(settingDetails?.header_image);
      setAboutImage(settingDetails?.about_image);
    }
  }, [settingDetails]);

  useEffect(() => {
    getSettingsDetails()
      .then((response) => {
        setSettingDetails(response.data.data);
      })
      .catch((err) => {
        // toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      setImage(selectedFiles);
      const imageUrl = URL.createObjectURL(selectedFiles);
      setPreviewUrl(imageUrl);
    }
  };

  const handleAboutFileChange = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      setAboutImage(selectedFiles);
      const imageUrl = URL.createObjectURL(selectedFiles);
      setPreviewUrlAbout(imageUrl);
    }
  };

  const handleSettingForm = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("header_image", image);
    formData.append("about_description", settingData?.about_description);
    formData.append("about_image", AboutImage);
    formData.append("experience", settingData?.experience);
    formData.append("team_size", settingData?.team_size);
    formData.append("contact_description", settingData?.contact_description);
    formData.append("address", settingData?.address);
    formData.append("email", settingData?.email);
    formData.append("mobile", settingData?.mobile);
    formData.append("facebook_url", settingData?.facebook_url);
    formData.append("instagram_url", settingData?.instagram_url);
    formData.append("youtube_url", settingData?.youtube_url);
    formData.append("whatsapp_url", settingData?.whatsapp_url);
    formData.append("linkedin_url", settingData?.linkedin_url);
    formData.append("google_play_url", settingData?.google_play_url);
    formData.append("app_store_url", settingData?.app_store_url);
    formData.append("footer_description", settingData?.footer_description);
    formData.append(
      "our_services_description",
      settingData?.our_services_description
    );
    formData.append("part_image_limit", settingData?.part_image_limit);

    actionSettingAdd(formData)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Settings</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                {/* <li className="breadcrumb-item">
                  <a href="/part-catalog">Part Catalog</a>
                </li> */}
                {/* <li className="breadcrumb-item active">Add PartCatalog</li> */}
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                {/* <div className="card-header">
                  <h3 className="card-title">Update Profile</h3>
                </div> */}

                <Form method={`post`} onSubmit={handleSettingForm}>
                  <div className="card-body">
                    <div className="col-form-label ml-2 Label-my form-label form-label">
                      <h3>Header</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Header Image"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <div className="avatar-upload">
                                <div className="avatar-edit">
                                  <div className="input-div">
                                    <input
                                      className="form-control"
                                      type="file"
                                      id="imageUpload"
                                      onChange={handleFileChange}
                                      name="file"
                                      accept=".jpg,.jpeg,.png"
                                    />
                                    {previewUrl && (
                                      <i
                                        style={{
                                          position: "absolute",
                                          top: "3px",
                                          right: "12px",
                                          fontSize: "20px",
                                          color: "#81bb42",
                                        }}
                                        class="bi bi-eye"
                                        onClick={handleShow}
                                      ></i>
                                    )}
                                  </div>
                                  <label htmlFor="imageUpload"></label>
                                </div>
                                {/* <div className="avatar-preview profile-user">
                                  {previewUrl && (
                                    <i
                                      class="bi bi-eye"
                                      onClick={handleShow}
                                    ></i>
                                  )}
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part Image Limit"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="number"
                                value={settingData?.part_image_limit}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                   part_image_limit: e.target.value,
                                  });
                                }}
                                name={`part_image_limit`}
                                placeholder="Part Image Limit"
                              />
                            </div>                          </div>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="col-form-label ml-2 Label-my form-label form-label">
                      <h3>About</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"About Description"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <textarea
                                className="form-control input-search"
                                type="text"
                                value={settingData.about_description}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    about_description: e.target.value,
                                  });
                                }}
                                name={`about_description`}
                                placeholder="About description"
                                rows={4}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"About Image"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <div className="avatar-upload">
                                <div className="avatar-edit">
                                  <div className="input-div">
                                    <input
                                      className="form-control"
                                      type="file"
                                      id="imageuploadAbout"
                                      onChange={handleAboutFileChange}
                                      name="file"
                                      accept=".jpg,.jpeg,.png"
                                    />
                                    {previewUrlAbout && (
                                      <i
                                        style={{
                                          position: "absolute",
                                          top: "3px",
                                          right: "12px",
                                          fontSize: "20px",
                                          color: "#81bb42",
                                        }}
                                        class="bi bi-eye"
                                        onClick={handleShowAbout}
                                      ></i>
                                    )}
                                  </div>

                                  <label htmlFor="imageuploadAbout"></label>
                                </div>
                                {/* <div className="avatar-preview profile-user">
                                  <img
                                    src={previewUrlAbout}
                                    style={{
                                      width: "500px",
                                      height: "225px",
                                      bordeRadius: "75px",
                                      marginLeft: "15px",
                                      marginBottom: "20px",
                                    }}
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Experience"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.experience}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    experience: e.target.value,
                                  });
                                }}
                                name={`experience`}
                                placeholder="Experience"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Team Size"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.team_size}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    team_size: e.target.value,
                                  });
                                }}
                                name={`team_size`}
                                placeholder="Team Size"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="col-form-label ml-2 Label-my form-label form-label">
                      <h3>Contact</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Contact Description"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <textarea
                                className="form-control input-search"
                                type="text"
                                value={settingData.contact_description}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    contact_description: e.target.value,
                                  });
                                }}
                                name={`contact_description`}
                                placeholder="Contact description"
                                rows={4}
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Email"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.email}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    email: e.target.value,
                                  });
                                }}
                                name={`email`}
                                placeholder="Email"
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Facebook URL"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.facebook_url}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    facebook_url: e.target.value,
                                  });
                                }}
                                name={`facebook_url`}
                                placeholder="Facebook URL"
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Youtube URL"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.youtube_url}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    youtube_url: e.target.value,
                                  });
                                }}
                                name={`youtube_url`}
                                placeholder="Youtube URL"
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Linkedin URL"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.linkedin_url}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    linkedin_url: e.target.value,
                                  });
                                }}
                                name={`linkedin_url`}
                                placeholder="Linkedin URL"
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"App Store URL"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.app_store_url}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    app_store_url: e.target.value,
                                  });
                                }}
                                name={`app_store_url`}
                                placeholder="App Store URL"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Address"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <textarea
                                className="form-control input-search"
                                type="text"
                                value={settingData.address}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    address: e.target.value,
                                  });
                                }}
                                name={`address`}
                                placeholder="Address"
                                rows={4}
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Mobile"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.mobile}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    mobile: e.target.value,
                                  });
                                }}
                                name={`mobile`}
                                maxLength={10}
                                placeholder="Mobile"
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Instagram URL"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.instagram_url}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    instagram_url: e.target.value,
                                  });
                                }}
                                name={`instagram_url`}
                                placeholder="Instagram URL"
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Whatsapp URL"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.whatsapp_url}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    whatsapp_url: e.target.value,
                                  });
                                }}
                                name={`whatsapp_url`}
                                placeholder="Whatsapp URL"
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Google play URL"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={settingData.google_play_url}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    google_play_url: e.target.value,
                                  });
                                }}
                                name={`google_play_url`}
                                placeholder="Google play URL"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="col-form-label ml-2 Label-my form-label form-label">
                      <h3>Footer</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Footer Description"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <textarea
                                className="form-control input-search"
                                type="text"
                                value={settingData.footer_description}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    footer_description: e.target.value,
                                  });
                                }}
                                name={`footer_description`}
                                placeholder="Footer description"
                                rows={4}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Our Services Description"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <textarea
                                className="form-control input-search"
                                type="text"
                                value={settingData.our_services_description}
                                onChange={(e) => {
                                  setSettingData({
                                    ...settingData,
                                    our_services_description: e.target.value,
                                  });
                                }}
                                name={`our_services_description`}
                                placeholder="Our Services Description"
                                rows={4}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col"></div>
                    <div className="col">
                      <div
                        className="d-flex justify-content-end"
                        style={{ padding: "10px" }}
                      >
                        <button type="submit" className="btn btn-primary">
                          Submit <i class="bi bi-check2"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer"></div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Header Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={previewUrl}
              alt="Preview"
              style={{ width: "100%", height: "auto" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModalAbout} onHide={handleCloseAbout}>
          <Modal.Header closeButton>
            <Modal.Title>About Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={previewUrlAbout}
              alt="Preview"
              style={{ width: "100%", height: "auto" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAbout}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </div>
  );
};

export default Settings;
