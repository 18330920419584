import axiosInstance from "./AxiosInstance";

export function getVehicleCategoryList(data) {
  return axiosInstance.post(`/vehicle-category/list`, data);
}

export function deleteVehicleCategory(data) {
  return axiosInstance.post(`/vehicle-category/delete`, data);
}

export function createVehicleCategory(data) {
  return axiosInstance.post(`/vehicle-category/create`, data);
}
