import axiosInstance from "./AxiosInstance";

export function actionTermsConditionAddUpdate(data) {
  return axiosInstance.post(`/tc/add-update`, data);
}

export function getTermsConditionDetails(data) {
  return axiosInstance.post(`/tc/details`, data);
}

export function destroyTermsConditionList(data) {
  return axiosInstance.post(`tc/delete`, data);
}

export function getTermsConditionList(data) {
  return axiosInstance.post(`/tc/list`, data);
}

export function getViewTermsConditionList(data) {
  return axiosInstance.post(`/tc/view-list`, data);
}

export function actionPrivacyPolicyAddUpdate(data) {
  return axiosInstance.post(`/pp/add-update`, data);
}

export function getPrivacyPolicyDetails(data) {
  return axiosInstance.post(`/pp/details`, data);
}

export function destroyPrivacyPolicyList(data) {
  return axiosInstance.post(`/pp/delete`, data);
}

export function getPrivacyPolicyList(data) {
  return axiosInstance.post(`/pp/list`, data);
}

export function getViewPrivacyPolicyList(data) {
  return axiosInstance.post(`/pp/view-list`, data);
}
