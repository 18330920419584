import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  createLerCatalog,
  createPartCatalog,
  deleteLerCatalog,
  deletePartCatalog,
  getLerCatalogList,
  getPartCatalogList,
  importLerCatalog,
  importPartCatalog,
  getExportPartCatalogList,
} from "../../services/LerCatalogService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const PartCatalog = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const navigate = useNavigate();
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const LangData = localStorage.getItem("lng");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [partList, setPartList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [partLocationData, setPartLocationData] = useState({
    uuid: "",
    ler_code: "",
    item_weight: "",
    item_weight_unit: "",
  });

  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let errorsObj = {
    ler_code: "",
    item_weight: "",
    item_weight_unit: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  let errorsObjImport = {
    file: "",
  };
  const [errorsImport, setErrorsImport] = useState(errorsObjImport);

  const handleImportModal = () => {
    setModifyImportModal(true);
  };

  const handleImportCloseModal = () => {
    setModifyImportModal(false);
  };

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setPartLocationData("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchPartCatLog = () => {
    let data = { ...params };
    setIsLoading(true);
    getPartCatalogList(data)
      .then((response) => {
        setPartList(response.data.data);
        setTotalRecords(response.data.total);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsLoading(false);
      });
  };

  const fetchExportPartCatLog = () => {
    let data = { ...params };

    getExportPartCatalogList(data)
      .then((response) => {
        const worksheet = XLSX.utils.json_to_sheet(response.data.data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "PartCatalog.csv");
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartCatLog(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditLerCatalog = (e, item) => {
    setPartLocationData({
      uuid: item?.uuid,
      ler_code: item?.ler_code,
      item_weight: item?.item_weight,
      item_weight_unit: item?.item_weight_unit,
    });
    setModifyModal(true);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    deletePartCatalog(data)
      .then((response) => {
        let datas = { ...params };
        fetchPartCatLog(datas);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handlePartLocationForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!partLocationData.ler_code) {
      errorObj.ler_code = `LER Code required.`;
      error = true;
    }

    if (!partLocationData.item_weight) {
      errorObj.item_weight = `Item Weight required.`;
      error = true;
    }

    if (!partLocationData.item_weight_unit) {
      errorObj.item_weight_unit = `Item Weight Unit required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();
    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      ler_code: partLocationData.ler_code,
      item_weight: partLocationData.item_weight,
      item_weight_unit: partLocationData.item_weight_unit,
    };

    let Updateddata = {
      uuid: partLocationData?.uuid,
      ler_code: partLocationData.ler_code,
      item_weight: partLocationData.item_weight,
      item_weight_unit: partLocationData.item_weight_unit,
    };

    if (partLocationData?.uuid) {
      createPartCatalog(Updateddata)
        .then((response) => {
          toast.success(response.data.message);
          let datas = { ...params };
          fetchPartCatLog(datas);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createPartCatalog(data)
        .then((response) => {
          toast.success(response.data.message);
          let datas = { ...params };
          fetchPartCatLog(datas);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  const handleImportLocation = (e) => {
    e.preventDefault();

    let error = false;
    let errorObjImport = { ...errorsObjImport };
    if (!file) {
      errorObjImport.file = `File is required.`;
      error = true;
    }

    setErrorsImport(errorObjImport);
    if (error) return;
    setIsModifyButtonLoad(true);
    const formData = new FormData();
    formData.append("files", file);

    importPartCatalog(formData)
      .then((response) => {
        toast.success(response.data.message);
        let datas = { ...params };
        fetchPartCatLog(datas);
        setModifyImportModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };
  const handleExport = () => {
    let data = { ...params };
    fetchExportPartCatLog(data);
  };

  return (
    <div>
      <>
        {/* {isLoading ? (
          <div className="text-center">
            {/* <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div> */}
        {/* Skeleton Loader */}
        {/* <div className="skeleton-loader">
              <table className="table table-bordered rounded-table table table-striped table-hover">
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>
                      <div className="skeleton"></div>
                    </th>
                    <th>
                      <div className="skeleton"></div>
                    </th>
                    <th>
                      <div className="skeleton"></div>
                    </th>
                    <th>
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ width: 10 }}>
                      <div className="skeleton"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {partList?.map((_, index) => (
                    <tr key={index}>
                      <td>
                        <div className="skeleton"></div>
                      </td>
                      <td>
                        <div className="skeleton"></div>
                      </td>
                      <td>
                        <div className="skeleton"></div>
                      </td>
                      <td>
                        <div className="skeleton"></div>
                      </td>
                      <td>
                        <div className="skeleton"></div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div> */}

        <div>
          {/* Your existing content goes here */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">{/* <h1>Part Catalog</h1> */}</div>
                <div className="col-sm-6"></div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="card-header">
                      <div className="card-title m-0 float-left">
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        />
                      </div>
                      <div className="card-title m-0 float-right d-flex">
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "import_part_catalog"
                        ) > -1 && (
                            <button
                              type="button"
                              className="btn btn-icon-text mr-2"
                              onClick={handleImportModal}
                            >
                              <i className="bi bi-file-earmark-excel-fill"></i>
                              <span className="ms-2">Import</span>
                            </button>
                          )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "export_part_catalog"
                        ) > -1 && (
                            <button
                              type="button"
                              className="btn btn-icon-text mr-2"
                              onClick={handleExport}
                            >
                              <i className="bi bi-file-earmark-excel-fill"></i>
                              <span className="ms-2">Export</span>
                            </button>
                          )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_part_catalog"
                        ) > -1 && (
                            <button
                              type="button"
                              className="btn btn-icon-text mr-2"
                              onClick={() => navigate("/add-part-catalog")}
                            >
                              <i className="bi bi-plus-lg"></i>
                              <span className="ms-2">Add</span>
                            </button>
                          )}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="vehicleThBg table-responsive cstm-height">
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: 10 }}>#</th>
                              <th>
                                {LangData === "pt"
                                  ? "PT"
                                  : LangData === "fr"
                                    ? "FR"
                                    : LangData === "es"
                                      ? "ES"
                                      : LangData === "it"
                                        ? "IT"
                                        : LangData === "de"
                                          ? "DE"
                                          : "UK"}
                              </th>

                              <th>BodyCar</th>
                              <th>Car Doors</th>
                              <th style={{ width: 10 }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {partList?.map((item, index) => (
                              <tr key={item.id}>
                                <td>{++index}</td>
                                <td>
                                  {LangData === "pt"
                                    ? item.namePartPT
                                    : LangData === "fr"
                                      ? item.namePartFR
                                      : LangData === "es"
                                        ? item.namePartES
                                        : LangData === "it"
                                          ? item.namePartIT
                                          : LangData === "de"
                                            ? item.namePartDE
                                            : item.namePartEN}
                                </td>
                                <td>{item.dependsBodyCar}</td>
                                <td>{item.numCarDoors}</td>
                                <td>
                                  <div className="d-flex">
                                    {(item.company_id > 0 ||
                                      companyId.role_id === "1") &&
                                      RolePermission?.findIndex(
                                        (e) =>
                                          e.permission_name ===
                                          "modify_part_catalog"
                                      ) > -1 && (
                                        <a
                                          className="edit-btn"
                                          href="#"
                                          onClick={() =>
                                            navigate(
                                              `/add-part-catalog/${item.id}`,
                                              { state: item }
                                            )
                                          }
                                        >
                                          <i className="bi bi-pencil"></i>
                                        </a>
                                      )}
                                    {(item.company_id > 0 ||
                                      companyId.role_id === "1") &&
                                      RolePermission?.findIndex(
                                        (e) =>
                                          e.permission_name ===
                                          "delete_part_catalog"
                                      ) > -1 && (
                                        <div className="trash-btn">
                                          <a
                                            className="delete-btn"
                                            href="#"
                                            onClick={(e) =>
                                              onDeleteModalOpen(e, item)
                                            }
                                          >
                                            <i className="bi bi-trash3"></i>
                                          </a>
                                        </div>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {partList && partList.length === 0 && (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  Record Not Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer clearfix">
              <div className="pagination pagination-sm m-0 float-right card-footer-part">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </section>
        </div>
        {/* )} */}

        <Modal isOpen={delModal}>
          <ModalHeader>{`Delete Part Catalog`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this Part Catalog?`}</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={onDeleteModalClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>

            {!isDelButtonLoad && (
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartType}
              >
                Delete
              </button>
            )}
            {isDelButtonLoad && (
              <button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </button>
            )}
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyModal}>
          <ModalHeader>Part Location</ModalHeader>
          <Form method={`post`} onSubmit={handlePartLocationForm}>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">{"LER Code"}</Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={partLocationData.ler_code}
                          onChange={(e) => {
                            setPartLocationData({
                              ...partLocationData,
                              ler_code: e.target.value,
                            });
                          }}
                          name={`ler_code`}
                          maxLength={50}
                          placeholder="LER Code"
                        />
                      </div>
                      {errors.ler_code && (
                        <span className="input-error">{errors.ler_code}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">{"Item Weight"}</Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={partLocationData.item_weight}
                          onChange={(e) => {
                            setPartLocationData({
                              ...partLocationData,
                              item_weight: e.target.value,
                            });
                          }}
                          name={`item_weight`}
                          maxLength={50}
                          placeholder="Item Weight"
                        />
                      </div>
                      {errors.item_weight && (
                        <span className="input-error">
                          {errors.item_weight}
                        </span>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">
                      {"Item Weight Unit"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={partLocationData.item_weight_unit}
                          onChange={(e) => {
                            setPartLocationData({
                              ...partLocationData,
                              item_weight_unit: e.target.value,
                            });
                          }}
                          name={`item_weight_unit`}
                          maxLength={50}
                          placeholder="Item Weight Unit"
                        />
                      </div>
                      {errors.item_weight_unit && (
                        <span className="input-error">
                          {errors.item_weight_unit}
                        </span>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                class="btn btn-block btn-secondary"
                disabled={isModifyButtonLoad}
                onClick={handleCloseModal}
                style={{ width: "100px", marginTop: "10px" }}
              >
                Close
              </button>
              {!isModifyButtonLoad && (
                <button
                  type="submit"
                  class="btn btn-block btn-primary"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  Submit
                </button>
              )}
              {isModifyButtonLoad && (
                <Button
                  color="success"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modifyImportModal}>
          <Form method={`post`} onSubmit={handleImportLocation}>
            <ModalHeader>{`Import Part Catalog`}</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">Select File</Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="file"
                          onChange={(e) => setFile(e.target.files[0])}
                          name="file"
                          accept=".csv,.xlsx"
                        />
                      </div>
                      {errorsImport.file && (
                        <span className="input-error">{errorsImport.file}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                class="btn btn-block btn-secondary"
                disabled={isModifyButtonLoad}
                onClick={handleImportCloseModal}
                style={{ width: "100px", marginTop: "10px" }}
              >
                Close
              </button>
              {!isModifyButtonLoad && (
                <button
                  type="submit"
                  class="btn btn-block btn-primary"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  Import
                </button>
              )}
              {isModifyButtonLoad && (
                <Button
                  color="success"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </>
    </div>
  );
};

export default PartCatalog;
