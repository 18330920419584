import axiosInstance from "./AxiosInstance";

export function actionCountryList(data) {
  return axiosInstance.post(`/company/country`, data);
}

export function actionStateList(data) {
  return axiosInstance.post(`/company/state`, data);
}

export function actionCityList(data) {
  return axiosInstance.post(`/company/city`, data);
}
