import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  actionAddSubscription,
  actionUpdateSubscription,
  getSubscriptionList,
  actionDelSubscription,
  actionUpdateStatusSubscription,
} from "../../services/SubscriptionServices";
import { toast } from "react-toastify";
import Utils from "../../utils";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from "react-bootstrap";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const Subscription = () => {
  const navigate = useNavigate();
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const data = localStorage.getItem(ConfigDB?.data?.login_data);
  const companyId = JSON.parse(data);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [uuid, setuuid] = useState("");
  const [name, setName] = useState("");
  const [monthlyPrice, setMonthlyPrice] = useState("");
  const [yearlyPrice, setYearlyPrice] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [status, setStatus] = useState(1);
  const [freeDays, setfreeDays] = useState(0);
  const [description, setDescription] = useState("");
  const [checkbox, setCheckbox] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = {
    name: "",
    monthlyPrice: "",
    yearlyPrice: "",
    free_Days: "",
    description: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const isNumber = (number) => Number.isFinite(number);
  const handleAddModal = () => {
    ClereForm();
    setModifyModal(true);
  };

  const handleDeleteModal = (e, uuid) => {
    setuuid(uuid);
    setDeleteModal(true);
  };
  const handleCloseModal = () => {
    setModifyModal(false);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };
  const handleCheckboxChange = (e, value, id) => {
    const isChecked = e.target.checked;
    setCheckbox((prev) => ({
      ...prev,
      [id]: isChecked,
    }));

    const obj = {
      status: isChecked ? 1 : 0,
      uuid: id,
    };
    setIsLoading(true);
    actionUpdateStatusSubscription(obj)
      .then((response) => {
        toast.success(response.data.message);
        LoadList();
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const valueChangedHandler = function (e) {
    setIsFree(!isFree);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let errorObj = { ...errorsObj };
    let error = false;
    if (!name) {
      errorObj.name = "Name is required";
      error = true;
    }
    const parsedValue = parseFloat(monthlyPrice);

    if (!monthlyPrice || isNaN(parseFloat(monthlyPrice))) {
      errorObj.monthlyPrice = "Monthly price is required";
      error = true;
    }
    if (!yearlyPrice || isNaN(parseFloat(yearlyPrice))) {
      errorObj.yearlyPrice = "Yearly price is required";
      error = true;
    }

    if (isFree) {
      if (!freeDays || freeDays <= 0) {
        errorObj.free_Days = "Free days grater then zero";
        error = true;
      }
    }

    if (!description) {
      errorObj.yearlyPrice = "Description is required";
      error = true;
    }
    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    let obj = {
      name: name,
      monthly_price: monthlyPrice,
      yearly_price: yearlyPrice,
      free_days: isFree == true ? freeDays : 0,
      is_free: isFree == true ? 1 : 0,
      status: status == true ? 1 : 0,
      description: description,
    };
    setIsLoading(true);

    actionAddSubscription(obj)
      .then((response) => {
        toast.success(response.data.message);
        ClereForm();
        setModifyModal(false);
        LoadList();
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    let obj = {
      uuid: uuid,
    };
    actionDelSubscription(obj)
      .then((response) => {
        toast.success(response.data.message);
        setDeleteModal(false);
        LoadList();
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  useEffect(function () {
    if (companyId.company_id > 0) {
      return navigate("/dashboard");
    }
    LoadList();
  }, []);

  function LoadList() {
    let data = { ...params };
    setIsLoading(true);

    getSubscriptionList(data)
      .then((response) => {
        setSubscriptionList(response.data.data);
        setTotalRecords(response.data.total);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsLoading(false);
      });
  }
  function ClereForm() {
    setName("");
    setMonthlyPrice("");
    setYearlyPrice("");
    setIsFree(false);
    setfreeDays(0);
    setStatus(1);
    setDescription("");
  }
  return (
    <div>
      {isLoading ? (
        // <div className="text-center">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
            <div className="card">
              <div className="card-header">
                  <table className="table rounded-table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 10, borderTop: "0", borderBottom:"0" }}><div className="skeleton" ></div></th>
                        <th style={{borderTop: "0", borderBottom:"0"}}><div className="skeleton"></div></th>
                        <th style={{borderTop: "0", borderBottom:"0"}}></th>
                        <th class="d-flex" style={{borderTop: "0", borderBottom:"0"}}><div class="skeleton me-1"></div>
                          <div class="skeleton me-1"></div>
                          <div class="skeleton"></div>
                        </th>
                        <th style={{ width: 10,borderTop: "0", borderBottom:"0" }}><div className="skeleton"></div></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              <div className="card-body">
                <div className="vehicleThBg table-responsive">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{backgroundColor: "#c0c0c0"}}><div className="skeleton"></div></th>
                      <th style={{backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                      <th style={{backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {SubscriptionList?.map((_, index) => (
                      <tr key={index}>
                        <td><div className="skeleton"></div></td>
                        <td><div className="skeleton"></div></td>
                        <td><div className="skeleton"></div></td>
                      
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              </div>
              <div className="card-footer">
                <div className="skeleton col-lg-3 float-right"></div>
              </div>
            </div>
          </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Subscription</h1>
                </div>

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Subscription</li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title m-0 float-right">
                        <button
                          type="button"
                          className="btn btn-block btn-icon-text"
                          onClick={handleAddModal}
                        >
                          <i className="bi bi-plus-lg"></i>
                          <span className="ms-2">Add</span>
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="vehicleThBg">
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: 10 }}>#</th>
                              <th>Name</th>
                              <th>Monthly Price</th>
                              <th>Yearly Price</th>
                              <th>Is Free</th>
                              <th>Free Days</th>
                              <th>Status</th>
                              <th>Description</th>
                              <th style={{ width: 10 }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {SubscriptionList?.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{item.name}</td>
                                    <td>{item.monthly_price}</td>
                                    <td>{item.yearly_price}</td>
                                    <td>
                                      {item.is_free == true ? "Yes" : "No"}
                                    </td>
                                    <td>{item.free_days}</td>
                                    <td>
                                      <div className="form-group">
                                        <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            checked={
                                              checkbox[item.uuid] ||
                                              item.status === 1
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(
                                                e,
                                                item.status,
                                                item.uuid
                                              )
                                            }
                                            id={`customSwitch${index}`}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`customSwitch${index}`}
                                          ></label>
                                        </div>
                                      </div>
                                    </td>
                                    <td>{item.subscription_desc}</td>
                                    <td>
                                      {" "}
                                      <div className="trash-btn">
                                        <a
                                          href="#"
                                          className="text-danger"
                                          onClick={(e) =>
                                            handleDeleteModal(e, item.uuid)
                                          }
                                        >
                                          <i class="bi bi-trash"></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          {SubscriptionList &&
                            SubscriptionList?.length === 0 && (
                              <tr>
                                <td
                                  colSpan={9}
                                  className={`text-center`}
                                >{`Record Not Found`}</td>
                              </tr>
                            )}
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                    <div className="card-footer clearfix card-footer-part">
                      <div className="pagination pagination-sm m-0 float-right">
                        <Pagination
                          activePage={params.page}
                          itemsCountPerPage={parseInt(params.limit)}
                          totalItemsCount={parseInt(totalRecords)}
                          pageRangeDisplayed={5}
                          itemClass={`page-item`}
                          linkClass={`page-link`}
                          onChange={(e) => handleParams(e, "pagination")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              show={modifyModal}
              onHide={handleCloseModal}
              backdrop="static"
              keyboard={false}
            >
              <Form onSubmit={handleSubmit}>
                <ModalHeader closeButton>
                  <Modal.Title>Create Subscription</Modal.Title>
                </ModalHeader>
                <ModalBody>
                  <Form.Group controlId="name" class="form-group">
                    <Form.Label className="col-form-label ml-2 Label-my form-label form-label">
                      Subscription
                    </Form.Label>
                    <Form.Control
                      className="form-control 
                    input-search"
                      type="text"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && (
                      <span className="input-error">{errors.name}</span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="monthlyPrice" class="form-group">
                    <Form.Label className="col-form-label ml-2 Label-my form-label form-label">
                      Monthly Price
                    </Form.Label>
                    <Form.Control
                      className="form-control 
                    input-search"
                      type="number"
                      placeholder="Enter Monthly Price"
                      value={monthlyPrice}
                      onChange={(e) => setMonthlyPrice(e.target.value)}
                    />
                    {errors.monthlyPrice && (
                      <span className="input-error">{errors.monthlyPrice}</span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="yearlyPrice" class="form-group">
                    <Form.Label className="col-form-label ml-2 Label-my form-label form-label">
                      Yearly Price
                    </Form.Label>
                    <Form.Control
                      className="form-control 
                    input-search"
                      type="number"
                      placeholder="Enter Yearly Price"
                      value={yearlyPrice}
                      onChange={(e) => setYearlyPrice(e.target.value)}
                    />
                    {errors.yearlyPrice && (
                      <span className="input-error">{errors.yearlyPrice}</span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="isFree" className="form-check">
                    <Form.Check
                      type="checkbox"
                      value={isFree}
                      checked={isFree}
                      onChange={(e) => valueChangedHandler(e.target.value)}
                    />
                    <Form.Label className="Label-my">Is Free</Form.Label>
                  </Form.Group>
                  {isFree == true && (
                    <Form.Group controlId="freeDays" class="form-group">
                      <Form.Label className="col-form-label ml-2 Label-my form-label form-label">
                        Free Days
                      </Form.Label>
                      <Form.Control
                        className="form-control 
                      input-search"
                        type="number"
                        placeholder="Enter free days"
                        value={freeDays}
                        min={0}
                        onChange={(e) => setfreeDays(e.target.value)}
                      />
                      {errors.free_Days && (
                        <span className="input-error">{errors.free_Days}</span>
                      )}
                    </Form.Group>
                  )}
                  <Form.Group controlId="status" class="form-group">
                    <Form.Label className="col-form-label ml-2 Label-my form-label form-label">
                      Status
                    </Form.Label>
                    <Form.Select
                      className="form-control input-search"
                      type="select"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="0">De-Active</option>
                      <option value="1">Active</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="description" class="form-group">
                    <Form.Label className="col-form-label ml-2 Label-my form-label form-label">
                      Description
                    </Form.Label>
                    <Form.Control
                      className="form-control 
                    input-search"
                      type="area"
                      placeholder="Enter Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    {errors.description && (
                      <span className="input-error">{errors.description}</span>
                    )}
                  </Form.Group>
                </ModalBody>
                <ModalFooter>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>
                </ModalFooter>
              </Form>
            </Modal>

            <Modal
              show={deleteModal}
              onHide={handleCloseDeleteModal}
              backdrop="static"
              keyboard={false}
            >
              <Form onSubmit={handleDeleteSubmit}>
                <ModalHeader closeButton>
                  <Modal.Title>Delete Fuel</Modal.Title>
                </ModalHeader>
                <ModalBody>
                  <Form.Group controlId="id">
                    <Form.Label>
                      Are you sure you want to delete this record?
                    </Form.Label>
                  </Form.Group>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={handleCloseDeleteModal}
                    style={{ width: "100px", marginTop: "10px" }}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-block bg-red"
                    style={{ width: "100px" }}
                  >
                    Delete
                  </button>
                </ModalFooter>
              </Form>
            </Modal>
          </section>
        </div>
      )}
    </div>
  );
};

export default Subscription;
