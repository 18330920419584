import axiosInstance from "./AxiosInstance";

export function login(data) {
  return axiosInstance.post(`/login`, data);
}

export function signUp(data) {
  return axiosInstance.post(`/company-registration`, data);
}

export function forgotPassword(data) {
  return axiosInstance.post(`/forgot-password`, data);
}
export function validateOTP(data) {
    return axiosInstance.post(`/forgot-password/otp`, data);
}

export function resetPasswordToken(data) {
  return axiosInstance.post(`/reset-password/check-token`, data);
}

export function resetPassword(data) {
  return axiosInstance.post(`/reset-password`, data);
}

export function profileDetail(data) {
  return axiosInstance.post(`/profile/change-detail`, data);
}

export function profileDetailForWeb(data) {
  return axiosInstance.post(`profile/detail`, data);
}

export function getSubscriptionList(data) {
    return axiosInstance.get(`/get-subscription`, data);
}
export function SubscribeCompany(data) {
    return axiosInstance.post(`/company/subscribe`, data);
}
