import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UserImg from "../../asset/images/user-img.png";
import LanguageSwitcher from "./LanguageSwitcher";
import ConfigDB from "../../config";

const Header = ({isOpenS, setIsOpens}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLan, setIsOpenLan] = useState(false);
  const navigate = useNavigate();
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const roleData = JSON.parse(data);

  const location = useLocation();

  let title;
  if (location.pathname === "/dashboard") {
    title = "Dashboard";
  } else if (location.pathname === "/vehicle-process") {
    title = "Vehicle";
  } else if (location.pathname === "/vehicle-process") {
    title = "Vehicle";
  } else if (location.pathname === "/parts-inventory") {
    title = "Parts Inventory";
  } else if (location.pathname === "/ler-catalog") {
    title = "LER Catalog";
  } else if (location.pathname === "/depollution-operation") {
    title = "Depollution Opration";
  } else if (location.pathname === "/User") {
    title = "User";
  } else if (location.pathname === "/Roles") {
    title = "Roles";
  } else if (location.pathname === "/fuel-type") {
    title = "Fuel";
  } else if (location.pathname === "/part-type") {
    title = "Part Type";
  } else if (location.pathname === "/part-location") {
    title = "Part Location";
  } else if (location.pathname === "/part-group") {
    title = "Part Group";
  } else if (location.pathname === "/part-sides") {
    title = "Part Sides";
  } else if (location.pathname === "/vehicle-category") {
    title = "Vehicle Category";
  } else if (location.pathname === "/vehicle-type") {
    title = "Vehicle Type";
  } else if (location.pathname === "/vehicle-model") {
    title = "Vehicle Model";
  } else if (location.pathname === "/faqs") {
    title = "FAQ";
  } else if (location.pathname === "/terms-condition-admin") {
    title = "Terms & Condition";
  } else if (location.pathname === "/privacy-policy-admin") {
    title = "Privacy & Policy";
  } else if (location.pathname === "/part-catalog") {
    title = "Part Catalog";
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdownLan = () => {
    setIsOpenLan(!isOpenLan);
  };

  const handleLogout = () => {
    localStorage.removeItem("@secure.@data");
    navigate("/");
  };

  return (
    <div className="wrapper-dashboard">
      <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{marginLeft: isOpenS ? "250px" : "60px"}}>
        <h5>{title}</h5>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown" style={{ marginRight: "35px" }}>
            <LanguageSwitcher />
          </li>
          <li className="nav-item dropdown">
            <div className="profile">
              <div className="dropdown">
                <img
                  src={UserImg}
                  alt="user"
                  className="img-thumbnail"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={toggleDropdown}
                />
                <ul
                  className={`dropdown-menu dropdown-menu-md dropdown-menu-right ${
                    isOpen ? "show" : ""
                  }`}
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  <li>
                    <a className="dropdown-item" href="/profile">
                      Profile
                    </a>
                  </li>
                  {roleData?.role_id === "1" && (
                    <li>
                      <a className="dropdown-item" href="/settings">
                        Settings
                      </a>
                    </li>
                  )}

                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
