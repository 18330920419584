import axiosInstance from "./AxiosInstance";

export function getPartTypeList(data) {
  return axiosInstance.post(`/parts-type/list`, data);
}

export function getPartlocationList(data) {
  return axiosInstance.post(`/parts-locations/list`, data);
}
export function getExportPartlocationList(data) {
  return axiosInstance.post(`/parts-locations/export-list`, data);
}
export function getPartgroupList(data) {
  return axiosInstance.post(`/parts-group/list`, data);
}
export function getExportPartgroupList(data) {
  return axiosInstance.post(`/parts-group/export-list`, data);
}
export function getPartsidesList(data) {
  return axiosInstance.post(`/parts-slides/list`, data);
}

export function getExportPartsidesList(data) {
  return axiosInstance.post(`/parts-slides/export-list`, data);
}

export function deletePartType(data) {
  return axiosInstance.post(`/parts-type/delete`, data);
}

export function deletePartgroup(data) {
  return axiosInstance.post(`/parts-group/delete`, data);
}

export function deletePartlocation(data) {
  return axiosInstance.post(`/parts-locations/delete`, data);
}

export function deletePartsides(data) {
  return axiosInstance.post(`/parts-slides/delete`, data);
}

export function createPartType(data) {
  return axiosInstance.post(`/parts-type/create`, data);
}

export function createPartGroup(data) {
  return axiosInstance.post(`/parts-group/create`, data);
}

export function createPartLocation(data) {
  return axiosInstance.post(`/parts-locations/create`, data);
}

export function createPartSlides(data) {
  return axiosInstance.post(`/parts-slides/create`, data);
}

export function updatePartType(data) {
  return axiosInstance.post(`/parts-type/update`, data);
}

export function updatePartGroup(data) {
  return axiosInstance.post(`/parts-group/update`, data);
}

export function updatePartLocation(data) {
  return axiosInstance.post(`/parts-locations/update`, data);
}

export function updatePartSlides(data) {
  return axiosInstance.post(`/parts-slides/update`, data);
}

export function importPartType(data) {
  return axiosInstance.post(`/parts-type/import`, data);
}

export function importPartLocation(data) {
  return axiosInstance.post(`/parts-locations/import`, data);
}

export function importPartGroup(data) {
  return axiosInstance.post(`/parts-group/import`, data);
}

export function importPartSlide(data) {
  return axiosInstance.post(`/parts-slides/import`, data);
}

export function actionAddPartType(data) {
  return axiosInstance.post(`/parts-type/create`, data);
}

export function actionUpdatePartType(data) {
  return axiosInstance.post(`/parts-type/update`, data);
}

export function getPartType(data) {
  return axiosInstance.post(`/parts-type/list`, data);
}

export function getExportPartType(data) {
  return axiosInstance.post(`/parts-type/export-list`, data);
}

export function actionDelPartType(data) {
  return axiosInstance.post(`/parts-type/delete`, data);
}

export function getPartTypeAllList(data) {
  return axiosInstance.get(`/get-parts-type`, data);
}

export function getPartLocationAllList(data) {
  return axiosInstance.get(`/get-parts-location`, data);
}
export function getPartGroupAllList(data) {
  return axiosInstance.get(`/get-parts-group`, data);
}
export function getPartSideAllList(data) {
  return axiosInstance.get(`/get-parts-sides`, data);
}

export function GetPartCatalogDetails(data) {
  return axiosInstance.post("/parts-catalog/get-details", data);
}
