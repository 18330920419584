import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import VehicleOwner from "./VehicleOwner";
import Vehicles from "./Vehicles";
import VehicleImage from "./VehicleImage";
import VehicleDocument from "./VehicleDocument";
import VehicleInfoExtra from "./VehicleInfoExtra";
import {
  getCountryAllist,
  getVehicleProcessDetails,
} from "../../services/VehicleService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import VehicleAmortization from "./VehicleAmortization";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
const Vehicle = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const [activeTab, setActiveTab] = useState("vehicle_owner");
  const [vehicleProcessList, setVehicleProcessList] = useState({});
  const [AllvehicleSerachData, setAllVehicleSerachData] = useState({
    vin: "",
    plate: "",
    country: "",
    serach_vehicle_id: 1,
  });
  const [vehicleVinData, setVehicleVinData] = useState({});
  const [vehicleVinTecData, setVehicleVinTecData] = useState({});

  const [vehiclePlateData, setVehiclePlateData] = useState({});
  const [countryAllData, setCountryAllData] = useState([]);

  let ownerId = localStorage.getItem("ownerList");

  let vehicleId = localStorage.getItem("vehicleId");

  const { uuid } = useParams();

  const state = useLocation();
  const navigate = useNavigate();

  const toggleTab = (tab) => {
    let isvalid = false;

    if (ownerId || state?.state?.vehicle_owner_id) {
      if (tab === "vehicle_owner") {
        isvalid = true;
      } else if (tab === "vehicle") {
        isvalid = true;
      } else if (
        vehicleId ||
        (state?.state?.id && vehicleId != null) ||
        state?.state?.id != null
      ) {
        isvalid = true;
      } else {
        isvalid = false;
      }
    } else {
      if (tab === "vehicle_owner") {
        isvalid = true;
      }
    }

    if (isvalid) {
      if (activeTab !== tab) setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (ownerId || vehicleId) {
      let data = {
        vehicle_id: vehicleId ? vehicleId : "",
        data_type: activeTab,
        owner_id: ownerId,
        uuid: uuid,
      };

      getVehicleProcessDetails(data)
        .then((response) => {
          setVehicleProcessList(response.data.data);
        })
        .catch((err) => {
          // toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [activeTab]);

  useEffect(() => {
    if (state?.state) {
      let data = {
        vehicle_id: state?.state?.id,
        data_type: activeTab,
        owner_id: state?.state?.vehicle_owner_id,
        uuid: uuid,
      };

      getVehicleProcessDetails(data)
        .then((response) => {
          setVehicleProcessList(response.data.data);
        })
        .catch((err) => {
          // toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [activeTab, state]);

  useEffect(function () {
    getCountryAllist()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].code,
            label: response.data[i].country,
          });
        }
        setCountryAllData(list);
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      <section className="content-header content-header-vehcial">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"></div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="vehicle-taps-content">
            <Nav tabs className="navPills steps-horizontal-tabs">
              <NavItem className="vehicle-m-l vehicle-b">
                <NavLink
                  className={activeTab === "vehicle_owner" ? "active" : ""}
                  onClick={() => toggleTab("vehicle_owner")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-person me-1"></i> Owner
                </NavLink>
              </NavItem>

              <NavItem
                className={`vehicle-m-l ${activeTab ? "vehicle-b" : ""}`}
              >
                <NavLink
                  className={activeTab === "vehicle" ? "active" : ""}
                  onClick={() => toggleTab("vehicle")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-car-front-fill me-1"></i> Vehicle
                </NavLink>
              </NavItem>

              <NavItem className="vehicle-m-l vehicle-b">
                <NavLink
                  className={activeTab === "vehicle_images" ? "active" : ""}
                  onClick={() => toggleTab("vehicle_images")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-images me-1"></i> Images
                </NavLink>
              </NavItem>
              <NavItem className="vehicle-m-l vehicle-b">
                <NavLink
                  className={activeTab === "vehicle_documents" ? "active" : ""}
                  onClick={() => toggleTab("vehicle_documents")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-file-earmark-medical me-1"></i> Document
                </NavLink>
              </NavItem>
              <NavItem className="vehicle-m-l vehicle-b">
                <NavLink
                  className={activeTab === "vehicle_extra_info" ? "active" : ""}
                  onClick={() => toggleTab("vehicle_extra_info")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-info-lg">Extra Info</i>
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="vehicle_owner">
                <VehicleOwner
                  setActiveTab={setActiveTab}
                  vehicleProcessList={vehicleProcessList}
                />
              </TabPane>
              <TabPane tabId="vehicle">
                <Vehicles
                  setActiveTab={setActiveTab}
                  vehicleProcessList={vehicleProcessList}
                  setVehicleVinData={setVehicleVinData}
                  vehicleVinData={vehicleVinData}
                  vehicleVinTecDat={vehicleVinTecData}
                  setVehicleVinTecData={setVehicleVinTecData}
                  vehiclePlateData={vehiclePlateData}
                  setVehiclePlateData={setVehiclePlateData}
                  countryAllData={countryAllData}
                  AllvehicleSerachData={AllvehicleSerachData}
                  setAllVehicleSerachData={setAllVehicleSerachData}
                />
              </TabPane>

              <TabPane tabId="vehicle_images">
                <VehicleImage
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  vehicleProcessList={vehicleProcessList}
                />
              </TabPane>

              <TabPane tabId="vehicle_documents">
                <VehicleDocument
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  vehicleProcessList={vehicleProcessList}
                />
              </TabPane>

              <TabPane tabId="vehicle_extra_info">
                <VehicleInfoExtra
                  setActiveTab={setActiveTab}
                  vehicleProcessList={vehicleProcessList}
                  activeTab={activeTab}
                  vehicleVinData={vehicleVinData}
                  vehiclePlateData={vehiclePlateData}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Vehicle;
