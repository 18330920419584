import React from "react";
import ConfigDB from "../../config";
import { useLocation } from "react-router-dom";
import Utils from "../../utils";
import { useState } from "react";

const Dashboard = () => {
  const location = useLocation();
  const record = Utils.getRecordForCurrentPath(location);
  const [isLoading, setIsLoading] = useState(false);
  const lerList = Array(5).fill(null);
  return (
    <>
      {record && record[0]?.display_name === "View" && (
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
            {isLoading ? (
          // <div className="text-center pageLoader">
          //   <div className="spinner-border" role="status">
          //     <span className="visually-hidden">Loading...</span>
          //   </div>
          // </div>
          <div className="skeleton-loader p-3">
            <div class="skeleton"></div>
          </div>
        ) : (
              <div className="col-sm-6">
                <h1 className="m-0 text-dark"></h1>
              </div>
        )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
