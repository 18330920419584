import axiosInstance from "./AxiosInstance";

export function actionAddRole(data) {
    return axiosInstance.post(`/roles/create`, data);
}

export function actionUpdateRole(data) {
    return axiosInstance.post(`/roles/update`, data);
}

export function getRoleList(data) {
    return axiosInstance.post(`/roles/list`, data);
}

export function actionDelRole(data) {
    return axiosInstance.post(`/roles/delete`, data);
}

export function getDetailsRole(data) {
    return axiosInstance.post(`/roles/get-details`, data);
}

